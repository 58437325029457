/**
 * @description Módulo para el testado y hasheo de información condifencial
 */
import { EFirmaDelimitador } from 'cofepris-typesafe/Modules/Firma'
import { IAI_Ensayo, IEC_Ensayo, ITipoDatoCampoActivoInformacion } from '../Types/ActivoDeInformacion/types'
import { IDatoFormulario } from '../Types/Homoclave'
import { NIVEL_ACCESO, NivelAccesoIsPermitidoEnContexto } from './NivelAcceso'
/**
 * @description Testa Información que se encuentre a un nivel de acceso superior al máximo requerido
 */

export enum EConfidencialidadDelimitador {
	DELIMITADOR_REEMPLAZO_INICIO = '«', //Delimitador de inicio de valor reemplazado
	DELIMITADOR_REEMPLAZO_FIN = '»' //Delimitador de fin de valor reemplazado
}

export const ConfidencialidadTestarByNivelAccesoContexto = (dato: string, nivelAcceso: NIVEL_ACCESO, contexto: NIVEL_ACCESO): string => {
	let response: string = ''
	try {
		if (NivelAccesoIsPermitidoEnContexto(nivelAcceso, contexto) || EFirmaDelimitador.DELIMITADOR_VALOR_VACIO === (dato as EFirmaDelimitador)) {
			response = dato || EFirmaDelimitador.DELIMITADOR_VALOR_VACIO
		} else {
			if (contexto === NIVEL_ACCESO.NO_CLASIFICADO) {
				response = `${EConfidencialidadDelimitador.DELIMITADOR_REEMPLAZO_INICIO}${'CONTEXTO_' + contexto}${EConfidencialidadDelimitador.DELIMITADOR_REEMPLAZO_FIN}`
			} else if (nivelAcceso === NIVEL_ACCESO.NO_CLASIFICADO) {
				response = `${EConfidencialidadDelimitador.DELIMITADOR_REEMPLAZO_INICIO}${'NIVEL_ACCESO_' + nivelAcceso}${EConfidencialidadDelimitador.DELIMITADOR_REEMPLAZO_FIN}`
			} else {
				response = `${EConfidencialidadDelimitador.DELIMITADOR_REEMPLAZO_INICIO}${nivelAcceso}${EConfidencialidadDelimitador.DELIMITADOR_REEMPLAZO_FIN}`
			}
		}
	} catch (e) {
		console.error('Error al evaluar el nivel de acceso', e)
	}
	return response
}

export const ConfidencialidadTest = () => {
	const niveles = Object.values(NIVEL_ACCESO) as string[]
	const contextos = Object.values(NIVEL_ACCESO) as string[]
	niveles.forEach((nivel: string) => {
		contextos.forEach((contexto: string) => {
			console.log(
				`Nivel: ${nivel} - Contexto: ${contexto} - Resultado: ${ConfidencialidadTestarByNivelAccesoContexto('Dato', NIVEL_ACCESO[nivel as keyof typeof NIVEL_ACCESO], NIVEL_ACCESO[contexto as keyof typeof NIVEL_ACCESO])}`
			)
		})
	})
}

/**
 * @function TestarDatosIai
 */

export const TestarDatosIai = (iai: IAI_Ensayo): IAI_Ensayo => {
	console.log(`iai`, Object.keys(iai))
	let count = 0
	const testarDatos = (nodo: ITipoDatoCampoActivoInformacion): ITipoDatoCampoActivoInformacion => {
		Object.keys(nodo).forEach((key: string) => {
			const nodoHijo = nodo[key as keyof ITipoDatoCampoActivoInformacion] as IDatoFormulario
			if (nodoHijo !== null && typeof nodoHijo === 'object' && 'valor' in nodoHijo) {
				const accessLevel = nodoHijo.accessLevel as NIVEL_ACCESO
				if (nodoHijo.valor && 'valueString' in nodoHijo.valor) {
					count++
					nodoHijo.valor.valueString = ConfidencialidadTestarByNivelAccesoContexto(nodoHijo?.valor?.valueString || '', accessLevel, NIVEL_ACCESO.PUBLICO)
				} else if (nodoHijo.valor && 'valueDate' in nodoHijo.valor) {
					count++
					nodoHijo.valor.valueDate = ConfidencialidadTestarByNivelAccesoContexto(nodoHijo.valor.valueDate?.toString() || '', accessLevel, NIVEL_ACCESO.PUBLICO) as unknown as Date
				} else {
					console.warn('nodoHijo', Object.keys(nodoHijo))
					console.warn('nodoHijo.valor', Object.keys(nodoHijo?.valor ?? {}))
				}
			} else if (typeof nodoHijo === 'object' && !('valor' in nodoHijo)) {
				testarDatos(nodoHijo as ITipoDatoCampoActivoInformacion)
			}
		})

		return nodo
	}
	if (!iai) {
		throw new Error('El IAI no puede ser nulo')
	}
	if (!iai.versiones) {
		throw new Error('El IAI no tiene versiones')
	}
	if (iai.versiones.length <= 0) {
		throw new Error('El IAI no tiene versiones')
	}
	const versiones = iai.versiones

	versiones.forEach((version, index) => {
		console.log(`version`, Object.keys(version))
		versiones[index] = testarDatos(version as ITipoDatoCampoActivoInformacion) as IEC_Ensayo
	})
	console.log(`iai`, Object.keys(iai))
	console.log(`Se han testado ${count} datos`)
	return iai
}
