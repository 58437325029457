import { NIVEL_ACCESO } from 'cofepris-typesafe/Modules/NivelAcceso'
import { HomoclaveContextRespuestaPrevencion, IDatosFormato, IHomoclaveContext, IHomoclaveContextEvaluacion, IHomoclaveContextResolucion } from 'cofepris-typesafe/Types/Homoclave'

export function getUltimaResolucion(context: Partial<IHomoclaveContext>): IHomoclaveContextResolucion | undefined {
	if (context.metadatos != undefined) {
		if (context.metadatos.contadorResoluciones != undefined) {
			if (context.metadatos.contadorResoluciones >= 0) {
				if (context.resolucion != undefined) {
					let indexResolucion = context.metadatos.contadorResoluciones - 1
					if (indexResolucion < 0) {
						indexResolucion = 0
					}
					return context.resolucion[indexResolucion]
				}
			}
		}
	}
	return undefined
}
export function getUltimaEvaluacion(context: Partial<IHomoclaveContext>): IHomoclaveContextEvaluacion | undefined {
	if (context.metadatos != undefined) {
		if (context.metadatos.contadorEvaluaciones != undefined) {
			if (context.metadatos.contadorEvaluaciones >= 0) {
				if (context.evaluacion != undefined) {
					let indexEvaluacion = context.metadatos.contadorEvaluaciones - 1
					if (indexEvaluacion < 0) {
						indexEvaluacion = 0
					}
					return context.evaluacion[indexEvaluacion]
				}
			}
		}
	}
	return undefined
}

export function getUltimaRespuestaPrevencion(context: Partial<IHomoclaveContext>): HomoclaveContextRespuestaPrevencion | undefined {
	if (context.metadatos != undefined) {
		if (context.metadatos.contadorRespuestasPrevenciones != undefined) {
			if (context.metadatos.contadorRespuestasPrevenciones >= 0) {
				if (context.respuesta_prevencion != undefined) {
					let indexRespuestaPrevencion = context.metadatos.contadorRespuestasPrevenciones - 1
					if (indexRespuestaPrevencion < 0) {
						indexRespuestaPrevencion = 0
					}
					return context.respuesta_prevencion[indexRespuestaPrevencion]
				}
			}
		}
	}
	return undefined
}
export function getNombreHomoclave(context: IHomoclaveContext): string {
	let response = ''
	if (context.homoclave) {
		if (context.homoclave.titulo) {
			response = context.homoclave.titulo
		}
	}
	return response
}

export function getIdSolicitud(context: IHomoclaveContext): string {
	let response = ''
	if (context.metadatos) {
		if (context.metadatos.idSolicitud) {
			response = context.metadatos.idSolicitud
		}
	}
	return response
}

export function getIdTramite(context: IHomoclaveContext): string {
	let response = ''
	if (context.metadatos) {
		if (context.metadatos.idTramite) {
			response = context.metadatos.idTramite
		}
	}
	return response
}

/**
 * @function fileSize
 * @description Devuelve el nivel de acceso más alto de un arreglo de niveles de acceso
 * @param {NIVEL_ACCESO} accessLeves - Niveles de acceso a evaluar
 * @return {NIVEL_ACCESO} Nivel de acceso más alto
 */
export function getHighestAccessLevel(accessLevels: (NIVEL_ACCESO | undefined)[]): NIVEL_ACCESO {
	const filteredAccessLevels = accessLevels.filter(level => level !== undefined) as NIVEL_ACCESO[]
	let mayor: NIVEL_ACCESO = NIVEL_ACCESO.NO_CLASIFICADO as NIVEL_ACCESO
	for (const level of filteredAccessLevels) {
		if (level == NIVEL_ACCESO.CONFIDENCIAL) {
			return NIVEL_ACCESO.CONFIDENCIAL
		} else if (level == NIVEL_ACCESO.RESTRINGIDO && (mayor == NIVEL_ACCESO.INTERNO || mayor == NIVEL_ACCESO.PUBLICO || mayor == NIVEL_ACCESO.NO_CLASIFICADO)) {
			mayor = NIVEL_ACCESO.RESTRINGIDO
			continue
		} else if (level == NIVEL_ACCESO.INTERNO && (mayor == NIVEL_ACCESO.PUBLICO || mayor == NIVEL_ACCESO.NO_CLASIFICADO)) {
			mayor = NIVEL_ACCESO.INTERNO
			continue
		} else if (level == NIVEL_ACCESO.PUBLICO && mayor == NIVEL_ACCESO.NO_CLASIFICADO) {
			mayor = NIVEL_ACCESO.PUBLICO
			continue
		}
	}
	return mayor
}

/**
 * @function getDatoByTag
 * @description Obtiene un dato de un contexto por su tag
 * @param {string} tag - Tag del dato a obtener
 */

export function getDatoByTagFromFormatos(formatos: IDatosFormato[], tag: string): string | undefined {
	let dato = ''
	formatos?.forEach((formato: { secciones: any }) => {
		const secciones = formato.secciones
		secciones.forEach((seccion: { formularios: any }) => {
			const formularios = seccion.formularios
			formularios.forEach((formulario: { campos: any }) => {
				const campos = formulario.campos
				dato = getDatoFromCampos(campos, tag, dato)
			})
		})
	})
	return dato
}

function getDatoFromCampos(campos: any[], tag: string, dato: string): string {
	campos.forEach((campo: { valor: any; outDataPath: string }) => {
		const currentTag = campo.valor.outDataPath || campo.outDataPath
		if (currentTag == tag) {
			dato = campo.valor.valueString
		}
	})
	return dato
}
