<script lang="ts" setup>
import { computed, ComputedRef } from 'vue'
import { VaModal } from 'vuestic-ui'
import { ErrorModalArgs } from './'
import TypeContainer from 'cofepris-typesafe/Types/Container'
import { IErrorModalService } from 'cofepris-typesafe/Types/ErrorModal'
const props = defineProps<{
	model: Partial<ErrorModalArgs>
}>()

const model: ComputedRef<Partial<ErrorModalArgs>> = computed(() => {
	const m: Partial<ErrorModalArgs> = props.model ?? new ErrorModalArgs()
	return m
})
const mostrar = computed(() => model.value.mostrar)
const bold = computed(() => model.value.bold)
const mensaje = computed(() => model.value.mensaje)
const aceptarTexto = computed(() => model.value.aceptarTexto ?? 'Aceptar')

const reintentarTexto = computed(() => model.value.reintentarTexto ?? 'Reintentar')

const cancelarTexto = computed(() => model.value.cancelarTexto ?? 'Cancelar')

const regresarTexto = computed(() => model.value.regresarTexto ?? 'Regresar')

const cerrarTexto = computed(() => model.value.cerrarTexto ?? 'Cerrar')
const titulo = computed(() => model.value.titulo ?? 'Ocurrió un error')

const datos = computed(() => model.value.datos?.value)
const onAceptar = computed(() => model.value.onAceptar)
const onReintentar = computed(() => model.value.onReintentar)
const onCerrar = computed(() => model.value.onCerrar)
const onRegresar = computed(() => model.value.onRegresar)
const onCancelar = computed(() => model.value.onCancelar)

const aceptar: ComputedRef<boolean> = computed(() => model.value.onAceptar != undefined)
const reintentar: ComputedRef<boolean> = computed(() => model.value.onReintentar != undefined)
const cancelar: ComputedRef<boolean> = computed(() => model.value.onCancelar != undefined)
const regresar: ComputedRef<boolean> = computed(() => model.value.onRegresar != undefined)
const cerrar: ComputedRef<boolean> = computed(() => model.value.onCerrar != undefined)

async function _onReintentar() {
	TypeContainer.get<IErrorModalService>('IErrorModalService').set({
		mostrar: false
	})
	await invokeAction(onReintentar.value)
}
async function _onCerrar() {
	TypeContainer.get<IErrorModalService>('IErrorModalService').set({
		mostrar: false
	})
	await invokeAction(onCerrar.value)
}
async function _onRegresar() {
	TypeContainer.get<IErrorModalService>('IErrorModalService').set({
		mostrar: false
	})
	await invokeAction(onRegresar.value)
}
async function _onCancelar() {
	TypeContainer.get<IErrorModalService>('IErrorModalService').set({
		mostrar: false
	})
	await invokeAction(onCancelar.value)
}
async function _onAceptar() {
	TypeContainer.get<IErrorModalService>('IErrorModalService').set({
		mostrar: false
	})
	await invokeAction(onAceptar.value)
}

async function invokeAction(action?: Promise<unknown> | (() => void | any)) {
	if (action != undefined) {
		if (typeof action == 'function') {
			await action()
		} else {
			await action
		}
	}
}
</script>

<template>
	<div>
		<!-- {{ mostrar }} -->
		<VaModal v-model="mostrar" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12 text-center">
					<div class="text-center fs-lg" :class="bold ? 'bold' : ''">{{ titulo }}</div>
					<debug-panel v-if="datos != undefined" title="Mostrar" :content="datos" :collapsed="false" />
					<hr />
					<div class="text-justify mt15 wb-bax">
						<p style="text-align: justify; word-break: break-all">
							{{ mensaje }}
						</p>
					</div>
					<hr v-if="mensaje" />

					<!--PRIMARIOS-->
					<button v-if="aceptar == true" class="btn btn-primary ml10" type="button" @click="_onAceptar">{{ aceptarTexto }}</button>
					<button v-if="reintentar == true" class="btn btn-primary ml10" type="button" @click="_onReintentar">{{ reintentarTexto }}</button>

					<!--SECUNDARIOS-->
					<button v-if="cancelar == true" class="btn btn-default ml10" type="button" @click="_onCancelar">{{ cancelarTexto }}</button>
					<button v-if="regresar == true" class="btn btn-default ml10" type="button" @click="_onRegresar">{{ regresarTexto }}</button>
					<button v-if="cerrar == true" class="btn btn-default ml10" type="button" @click="_onCerrar">{{ cerrarTexto }}</button>
				</div>
			</div>
		</VaModal>
	</div>
</template>
