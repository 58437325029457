import { AnyEventObject } from 'xstate'
//local imports
import { WebAuthnContext } from './types'
import { isPublicKeyCredentialSupported } from 'webauthn'

const isTrue = function (context: WebAuthnContext, event: AnyEventObject): boolean {
    return true
}

const isFalse = function (context: WebAuthnContext, event: AnyEventObject): boolean {
    return false
}

const isLoginMode = function (context: WebAuthnContext, event: AnyEventObject): boolean {
    context.debug ? console.log('on isLoginMode') : null
    return context.mode == 'LOGIN' ? true : false
}

const isRegisterMode = function (context: WebAuthnContext, event: AnyEventObject): boolean {
    context.debug ? console.log('on isRegisterMode') : null
    return context.mode == 'REGISTER' ? true : false
}

const isSignMode = function (context: WebAuthnContext, event: AnyEventObject): boolean {
    context.debug ? console.log('on isSignMode') : null
    return context.mode == 'SIGN' ? true : false
}

const isPublicKeyCredentialNotSupported = function (context: WebAuthnContext, event: AnyEventObject): boolean {
    return !isPublicKeyCredentialSupported()
}

const isChallengeEmpty = function (context: WebAuthnContext, event: AnyEventObject): boolean {
    return context.challenge ? false : true
}

const isCredential = function (context: WebAuthnContext, event: AnyEventObject): boolean {
    return context.credential ? true : false
}

export default {
    isTrue,
    isFalse,
    isPublicKeyCredentialSupported,
    isPublicKeyCredentialNotSupported,
    isChallengeEmpty,
    isCredential,
    isLoginMode,
    isRegisterMode,
    isSignMode
}
