/**
 * @module Datos
 * @author @tirsomartinezreyes
 * @version 1.0.1
 * @description Módulo para la extracción de datos persistidos en un contexto de homoclave
 * Un contexto de Homoclave (IHomoclaveContext) es un almacen de datos que utiliza una máquina de estados para almacenar y recuperar datos de un proceso de homoclave
 * un Contexto tiene:
 *  - Una homoclave
 *  - Una modalidad de Homoclave
 *  - Un conjunto de metadatos
 *  - Una fase de Solicitud
 *  - Un arreglo de fases de Evaluación
 *  - Un arreglo de fases de Resolución
 *  - Un arreglo de fases de Respuesta a Prevención
 *  - Un conjunto de permisos otorgados
 *  - Un conjunto de registros de acciones
 * Este módulo esta enfocado en los datos de los formatos, secciones, formularios y campos que se encuentran en las fases de la homoclave, por lo tanto es necesario entender que:
 * Una Homoclave tiene M modalidades
 * Una modalidad tiene N formatos para cada fase esecífica (Solicitud, Evaluación, Resolución, Respuesta a Prevención), los cuales se pueden acceder cutilizando el Módulo de Formatos
 * Un formato tiene S secciones
 * Una sección tiene F formularios
 * Un formulario tiene C campos
 * Un campo puede ser de un tipo STRING, NUMBER, FILE, BOOLEAN, DATE, ENUM, ARRAY, TABLE (CUSTOM_TYPE)
 * Las tablas son un tipo especial de campo, que a su vez tiene celdas de tipo STRING, NUMBER, FILE, BOOLEAN, DATE, ENUM, ARRAY (CUSTOM_TYPE)
 * Cada CUSTOM_TYPE tiene una forma particular de almacenar su información
 * Para cada tipo de dato, existe la manera de generar un valor Normalizado, el cual es un valor seguro de procesar como cadena de texto para fines de firmado y comparación
 *
 * En la fase evaluación, se generan datos de evaluación del dictaminador principal y de los dictaminadores secundarios, los cuales se almacenan en un nodo de evaluaciones (IHomoclaveContextComentarios) y que llevan la misma secuencia de los datos: Formatos (IComentariosFormato), secciones (IComentariosSeccion), formularios (IComentariosFormulario), campos (IComentarioCampo)
 * Los comentarios registran un valor de evaluación (OK, WARNING o ERROR ) y observaciones realizadas por el dictaminador, las cuales deben ser mostradas al usuario cuando un trámite se resuelve como PREVENIDO o  DESECHADO, de tal manera que el usuario pueda realizar las correcciones necesarias para que el trámite sea aprobado ,o en su defecto, entender la razón del rechazo.
 *
 * Comentarios de versión:
 * - 1.0.1 Se agrega validación para evitar el error en generación de fechas normalizasas cuando el dato de enrada ya está normalizado
 */
import {
	IHomoclaveContext,
	IDatosFormato,
	IDatosSeccion,
	IDatosFormulario,
	IDatoFormularioInput,
	IHomoclaveContextSolicitud,
	IHomoclaveContextEvaluacion,
	IHomoclaveContextRespuestaPrevencion,
	IHomoclaveContextResolucion,
	IComentariosFormato,
	IHomoclaveContextFaseFirmable,
	IHomoclaveContextFaseFirmableDatos
} from 'cofepris-typesafe/Types/Homoclave'
import {
	CUSTOM_TYPE,
	ICustomTypeUnion,
	IFileValue,
	IEnumValue,
	ICustomTypeValueString,
	ICustomTypeValueNumber,
	ICustomTypeValueFile,
	ICustomTypeValueBoolean,
	ICustomTypeValueTable,
	ICustomTypeValueDate,
	ICustomTypeValueEnum
} from 'cofepris-typesafe/src/primitives/IPrimitive'
import { wrapTabla, wrapFila, wrapCabecera, wrapCelda, wrapValor, isVacio, EFirmaDelimitador } from 'cofepris-typesafe/Modules/Firma'

export enum EDatosFase {
	SOLICITUD = 'solicitud',
	EVALUACION = 'evaluacion',
	RESOLUCION = 'resolucion',
	RESPUESTA_PREVENCION = 'respuesta_prevencion'
}

export enum EDatosNodo {
	DATOS = 'datos',
	DATOS_EVALUADOS = 'datosEvaluados',
	COMENTARIOS = 'comentarios'
}

export interface IDato {
	fase: EDatosFase
	faseIndice: number
	faseNodo: EDatosNodo
	idFormato: string
	idSeccion: string
	idDuplicado?: string
	indiceDuplicado?: number
	idFormulario: string
	idCampo: string
	idDato: string
	campo: IDatoFormularioInput
}

export interface IMappingDatos {
	[key: string]: IDato
}

export interface IDatoValorNormalizado {
	valorNormalizado: string
	error?: string
}

/****FASES*****/

/**
 * @description Extrae el objeto solicitud de un contexto de homoclave
 */
export const getFaseSolicitudFromContexto = (context: IHomoclaveContext): IHomoclaveContextSolicitud => {
	let response: unknown = undefined
	try {
		response = context.solicitud
	} catch (e) {
		console.error('Error al obtener datos de solicitud desde contexto ', e)
	}
	return response as IHomoclaveContextSolicitud
}

/**
 * @description Extrae el objeto evaluación de un contexto de homoclave
 */
export const getFaseEvaluacionFromContexto = (context: IHomoclaveContext, index: number = 0): IHomoclaveContextEvaluacion => {
	let response: unknown = undefined
	try {
		response = context.evaluacion?.[index]
	} catch (e) {
		console.error('Error al obtener datos de evaluación desde contexto ', e)
	}
	return response as IHomoclaveContextEvaluacion
}

/**
 * @description Extrae el objeto de respuesta a prevención un contexto de homoclave
 */
export const getFaseRespuestaPrevencionFromContexto = (context: IHomoclaveContext, index: number = 0): IHomoclaveContextRespuestaPrevencion => {
	let response: unknown = undefined
	try {
		response = context.respuesta_prevencion?.[index]
	} catch (e) {
		console.error('Error al obtener datos de respuesta_prevención desde contexto ', e)
	}
	return response as IHomoclaveContextRespuestaPrevencion
}

/**
 * @description Extrae el objeto resolución de un contexto de homoclave
 */
export const getFaseResolucionFromContexto = (context: IHomoclaveContext, index: number = 0): IHomoclaveContextResolucion => {
	let response: unknown = undefined
	try {
		response = context.resolucion?.[index]
	} catch (e) {
		console.error('Error al obtener datos de resolución desde contexto ', e)
	}
	return response as IHomoclaveContextResolucion
}

/****DATOS*****/

/**
 * @description Extrae los datos persistido en el contexto de la homoclave de una fase específica
 */
export const getDatosFromContextoByFase = (context: IHomoclaveContext, fase: EDatosFase, nodoDatos: EDatosNodo = EDatosNodo.DATOS, indice?: number | undefined): IDato[] => {
	const response: IDato[] = []
	try {
		const formatos = getDatosFormatosFromContextoByFase(context, fase, nodoDatos, indice)
		formatos.forEach(formato => {
			const secciones = getDatosSeccionesFromFormato(formato)
			secciones.forEach((seccion: IDatosSeccion) => {
				const formularios = getDatosFormulariosFromSeccion(seccion)
				formularios.forEach(formulario => {
					const campos = getDatosCamposFromFormulario(formulario)
					campos.forEach(campo => {
						const dato: IDato = {
							fase,
							faseIndice: indice ?? 0,
							faseNodo: nodoDatos,
							idFormato: typeof formato.idFormato === 'string' ? formato.idFormato : formato.idFormato._id,
							idSeccion: seccion.idSeccion,
							idDuplicado: seccion.idDuplicado,
							indiceDuplicado: seccion.indiceDuplicado,
							idFormulario: formulario.idFormulario,
							idCampo: campo.idCampo,
							idDato: campo.idDato!,
							campo
						}
						response.push(dato)
					})
				})
			})
		})
	} catch (e) {
		console.error('Error al obtener datos desde contexto ', e)
	}
	return response
}

/**
 * @description Obtiene un objeto map de IDatos con su idCampo como llave
 */
export const getDatosMapping = (datos: IDato[]): IMappingDatos => {
	const response: IMappingDatos = {}
	try {
		datos.forEach(dato => {
			const idDato = dato.idDato || dato.idCampo
			response[idDato] = dato
		})
	} catch (e) {
		console.error('Error al obtener mapping de datos ', datos, e)
	}
	return response
}

/**
 * @description Extrae los formatos persistido en el contexto de la homoclave de una fase específica
 */
export const getDatosFormatosFromContextoByFase = (context: IHomoclaveContext, fase: EDatosFase, nodoDatos: EDatosNodo = EDatosNodo.DATOS, indice?: number | undefined): IDatosFormato[] => {
	const response: IDatosFormato[] = []
	try {
		let faseFirmable: IHomoclaveContextFaseFirmable | IHomoclaveContextFaseFirmable[] | undefined = context[fase] as IHomoclaveContextFaseFirmable | IHomoclaveContextFaseFirmable[] | undefined
		if (faseFirmable == undefined) {
			return response
		}

		if (faseFirmable instanceof Array) {
			//extraemos el ultimo elemento del array o el indicado por el argumento indice
			const indiceFaseFirmable: number = indice ?? (faseFirmable.length > 0 ? faseFirmable.length - 1 : 0)
			faseFirmable = faseFirmable[indiceFaseFirmable]
		}

		if (faseFirmable != undefined) {
			if (nodoDatos in faseFirmable) {
				const datos = faseFirmable[nodoDatos as unknown as keyof IHomoclaveContextFaseFirmable] as IHomoclaveContextFaseFirmableDatos
				if (datos != undefined) {
					if ('formatos' in datos) {
						const formatos: IDatosFormato[] = ((datos as any)?.formatos ?? []) as IDatosFormato[]
						response.push(...formatos)
					}
				}
			}
		}
	} catch (e) {
		console.error('Error al obtener datos de formatos desde contexto ', e)
	}
	return response
}

export const getComentariosFormatosFromContextoByFase = (
	context: IHomoclaveContext,
	fase: EDatosFase,
	nodoDatos: EDatosNodo = EDatosNodo.DATOS,
	indice?: number | undefined
): IComentariosFormato[] => {
	const response: IComentariosFormato[] = []
	try {
		let faseFirmable: IHomoclaveContextFaseFirmable | IHomoclaveContextFaseFirmable[] | undefined = context[fase] as IHomoclaveContextFaseFirmable | IHomoclaveContextFaseFirmable[] | undefined
		if (faseFirmable == undefined) {
			return response
		}

		if (faseFirmable instanceof Array) {
			//extraemos el ultimo elemento del array o el indicado por el argumento indice
			const indiceFaseFirmable: number = indice ?? (faseFirmable.length > 0 ? faseFirmable.length - 1 : 0)
			faseFirmable = faseFirmable[indiceFaseFirmable]
		}

		if (nodoDatos in faseFirmable) {
			const datos = faseFirmable[nodoDatos as unknown as keyof IHomoclaveContextFaseFirmable] as IComentariosFormato[]
			if (datos != undefined) {
				if ('formatos' in datos) {
					const formatos: IComentariosFormato[] = ((datos as any)?.formatos ?? []) as IComentariosFormato[]
					response.push(...formatos)
				}
			}
		}
	} catch (e) {
		console.error('Error al obtener datos de formatos desde contexto ', e)
	}
	return response
}

/**
 * @description Extrae las secciones de un formato
 */
export const getDatosSeccionesFromFormato = (formato: IDatosFormato): IDatosSeccion[] => {
	const response: IDatosSeccion[] = []
	try {
		if (formato.secciones) {
			response.push(...formato.secciones)
		}
	} catch (e) {
		console.error('Error al obtener datos de secciones desde formato ', e)
	}
	return response
}

/**
 * @description Extrae los formularios de una sección
 */
export const getDatosFormulariosFromSeccion = (seccion: IDatosSeccion): IDatosFormulario[] => {
	const response: IDatosFormulario[] = []
	try {
		if (seccion.formularios) {
			response.push(...seccion.formularios)
		}
	} catch (e) {
		console.error('Error al obtener datos de formularios desde seccion ' + seccion.idSeccion, e)
	}
	return response
}

/**
 * @description Extrae los campos de un formulario
 */
export const getDatosCamposFromFormulario = (formulario: IDatosFormulario): IDatoFormularioInput[] => {
	const response: IDatoFormularioInput[] = []
	try {
		if (formulario.campos) {
			const campos: IDatoFormularioInput[] = formulario.campos as IDatoFormularioInput[]
			response.push(...campos)
		}
	} catch (e) {
		console.error('Error al obtener datos de campos desde formuario ' + formulario.idFormulario, e)
	}
	return response
}
/**
 * @description Extrae el nombre de un campo
 */
export const getDatosNombreDatoPersonalizado = (campo: IDatoFormularioInput): string | undefined => {
	let response: string | undefined = ''
	try {
		if (typeof campo.idCustomType === 'string') {
			response = campo.idCustomType
		} else if (typeof campo.typeName === 'string') {
			response = campo.typeName
		} else {
			response = undefined
		}
	} catch (e) {
		console.error('Error al obtener nombre de campo desde ', campo.idCampo, e)
	}
	return response
}

/*******VALOR*******/

/**
 * @description Extrae el valor de un campo(IDatoFormularioInput) o de una celda de tabla (ICustomTypeUnion)
 */
export const getDatosValorFromCampo = (campo: IDatoFormularioInput | ICustomTypeUnion): string | number | IFileValue[] | boolean | Date | IEnumValue[] | ICustomTypeUnion[][] | undefined => {
	let valorPersistencia: unknown = undefined
	let valorFormulario: unknown = undefined
	let response: unknown = undefined
	try {
		if ('valor' in campo && 'valuePath' in campo) {
			const index = campo.valuePath as keyof IDatoFormularioInput['valor']
			if (campo.valor) {
				if (index in campo.valor) {
					valorPersistencia = campo.valor[index] as unknown
				}
			} else {
				valorPersistencia = campo[index] as unknown
			}
		} else if ('valuePath' in campo) {
			valorFormulario = campo[campo.valuePath as keyof typeof campo] as unknown
		} else {
			//celda de tabla
			valorPersistencia = getDatosValorFromCelda(campo as unknown as ICustomTypeUnion)
		}
		if (valorPersistencia !== undefined) {
			response = valorPersistencia == null || valorPersistencia == '' ? EFirmaDelimitador.DELIMITADOR_VALOR_VACIO : valorPersistencia
		} else if (valorFormulario !== undefined) {
			response = valorFormulario
		} else {
			response = undefined
		}
	} catch (e) {
		console.error('Error al obtener valor de campo ' + getDatosIdCampoFromCampo(campo), e)
	}

	switch (campo.type) {
		case CUSTOM_TYPE.STRING:
			return response as string
		case CUSTOM_TYPE.NUMBER:
			return response as number
		case CUSTOM_TYPE.FILE:
			return response as IFileValue[]
		case CUSTOM_TYPE.BOOLEAN:
			return response as boolean
		case CUSTOM_TYPE.DATE:
			return response as Date
		case CUSTOM_TYPE.ENUM:
			return response as IEnumValue[]
		case CUSTOM_TYPE.TABLE:
			return response as ICustomTypeUnion[][]
		default:
			return response as undefined
	}
}

/**
 * @description Extrae el valor de una celda de tabla
 */
export const getDatosValorFromCelda = (celda: ICustomTypeUnion): string | number | IFileValue[] | boolean | Date | IEnumValue[] | ICustomTypeUnion[][] | undefined => {
	try {
		switch (celda.type) {
			case CUSTOM_TYPE.STRING:
				return (celda as ICustomTypeValueString).valueString as string
			case CUSTOM_TYPE.NUMBER:
				return (celda as ICustomTypeValueNumber).valueNumber as number
			case CUSTOM_TYPE.FILE:
				return (celda as ICustomTypeValueFile).valueFile as IFileValue[]
			case CUSTOM_TYPE.BOOLEAN:
				return (celda as ICustomTypeValueBoolean).valueBoolean as boolean
			case CUSTOM_TYPE.DATE:
				return (celda as ICustomTypeValueDate).valueDate as Date
			case CUSTOM_TYPE.ENUM:
				return (celda as ICustomTypeValueEnum).valueEnum as IEnumValue[]
			case CUSTOM_TYPE.TABLE:
				return (celda as ICustomTypeValueTable).valueTable as ICustomTypeUnion[][]
			default:
				return undefined
		}
	} catch (e) {
		console.error('Error al obtener valor de la celda ' + getDatosIdCampoFromCampo(celda), e)
	}
	return undefined
}

/*****VALOR NORMALIZADO ***/

/**
 * @description Extrae el valor normalizado de un campo IDatoFormularioInput (o de una celda de tabla ICustomTypeUnion)
 */
export const getDatosValorNormalizadoFromCampo = (campo?: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	let response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	if (campo == undefined) {
		return response
	}
	const type: CUSTOM_TYPE | undefined = getDatosTypeFromCampo(campo)

	try {
		if (type === CUSTOM_TYPE.STRING) {
			response = getDatosValorNormalizadoFromCustomTypeSTRING(campo)
		} else if (type === CUSTOM_TYPE.NUMBER) {
			response = getDatosValorNormalizadoFromCustomTypeNUMBER(campo)
		} else if (type === CUSTOM_TYPE.FILE) {
			response = getDatosValorNormalizadoFromCustomTypeFILE(campo)
		} else if (type === CUSTOM_TYPE.BOOLEAN) {
			response = getDatosValorNormalizadoFromCustomTypeBOOLEAN(campo)
		} else if (type === CUSTOM_TYPE.DATE) {
			response = getDatosValorNormalizadoFromCustomTypeDATE(campo)
		} else if (type === CUSTOM_TYPE.ENUM) {
			response = getDatosValorNormalizadoFromCustomTypeENUM(campo)
		} else if (type === CUSTOM_TYPE.ARRAY) {
			response = getDatosValorNormalizadoFromCustomTypeARRAY(campo)
		} else if (type === CUSTOM_TYPE.TABLE) {
			response = getDatosValorNormalizadoFromCustomTypeTABLE(campo)
		} else {
			response.error = 'No se ha implementado la normalización para el tipo de campo ' + (type as unknown as string)
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
	}

	return response
}

/**
 * @description Extrae el valor normalizado de un campo o celda de tabla de tipo CUSTOM_TYPES.STRING
 */
const getDatosValorNormalizadoFromCustomTypeSTRING = (campo: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	try {
		const valor: string = getDatosValorFromCampo(campo) as string
		const type = getDatosTypeFromCampo(campo)

		if (type == CUSTOM_TYPE.STRING) {
			response.valorNormalizado = wrapValor(valor)
		} else {
			response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo ' + CUSTOM_TYPE.STRING
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
		response.error = 'Ocurrió un error al obtener el valor normalizado del campo ' + getDatosIdCampoFromCampo(campo)
	}
	return response
}

/**
 * @description Extrae el valor normalizado de un campo o celda de tabla de tipo CUSTOM_TYPES.NUMBER
 */
const getDatosValorNormalizadoFromCustomTypeNUMBER = (campo: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	try {
		const valor: number | undefined = getDatosValorFromCampo(campo) as number | undefined
		const type = getDatosTypeFromCampo(campo)

		if (type == CUSTOM_TYPE.NUMBER) {
			response.valorNormalizado = wrapValor(valor?.toString())
		} else {
			response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo ' + CUSTOM_TYPE.NUMBER
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
		response.error = 'Ocurrió un error al obtener el valor normalizado del campo ' + getDatosIdCampoFromCampo(campo)
	}
	return response
}

/**
 * @description Extrae el valor normalizado de un campo o celda de tabla de tipo CUSTOM_TYPES.BOOLEAN
 */
const getDatosValorNormalizadoFromCustomTypeBOOLEAN = (campo: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	try {
		const valor: boolean = getDatosValorFromCampo(campo) as boolean
		const type = getDatosTypeFromCampo(campo)

		if (type == CUSTOM_TYPE.BOOLEAN) {
			response.valorNormalizado = wrapValor(valor == undefined ? '' : valor === true ? 'SI' : 'NO')
		} else {
			response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo ' + CUSTOM_TYPE.BOOLEAN
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
		response.error = 'Ocurrió un error al obtener el valor normalizado del campo ' + getDatosIdCampoFromCampo(campo)
	}
	return response
}

/**
 * @description Extrae el valor normalizado de un campo o celda de tabla de tipo CUSTOM_TYPES.ENUM
 */
const getDatosValorNormalizadoFromCustomTypeENUM = (campo: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	try {
		const valor: IEnumValue[] = getDatosValorFromCampo(campo) as IEnumValue[]
		const type = getDatosTypeFromCampo(campo)
		if (type == CUSTOM_TYPE.ENUM) {
			valor.forEach(enumValue => {
				if (enumValue?.selected != undefined) {
					response.valorNormalizado = wrapValor(enumValue.selected)
				}
			})
		} else {
			response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo ' + CUSTOM_TYPE.ENUM
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
		response.error = 'Ocurrió un error al obtener el valor normalizado del campo ' + getDatosIdCampoFromCampo(campo)
	}
	return response
}

/**
 * @description Extrae el valor normalizado de un campo o celda de tabla de tipo CUSTOM_TYPES.ARRAY
 */
const getDatosValorNormalizadoFromCustomTypeARRAY = (campo: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		//error: undefined
		error: 'No se ha implementado la normalización para el tipo de campo ' + CUSTOM_TYPE.ARRAY
	}
	try {
		//ToDo: identificar precisament el tipo de dato regresado por este tipo de campo
		const valor: unknown = getDatosValorFromCampo(campo) as unknown
		const type = getDatosTypeFromCampo(campo)

		if (type == CUSTOM_TYPE.ARRAY) {
			response.valorNormalizado = wrapValor(JSON.stringify(valor))
		} else {
			response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo ' + CUSTOM_TYPE.ARRAY
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
		response.error = 'Ocurrió un error al obtener el valor normalizado del campo ' + getDatosIdCampoFromCampo(campo)
	}
	return response
}

/**
 * @description Extrae el valor normalizado de un campo o celda de tabla de tipo CUSTOM_TYPES.FILE
 */
const getDatosValorNormalizadoFromCustomTypeFILE = (campo: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	try {
		const valor: IFileValue[] = getDatosValorFromCampo(campo) as IFileValue[]
		const type = getDatosTypeFromCampo(campo)
		const filas: string[] = []

		if (typeof valor === 'object' && type == CUSTOM_TYPE.FILE) {
			valor.forEach(file => {
				if (file.sha256 && file.fileName && file.size) {
					const celdas: string[] = []
					celdas.push(wrapCelda(file.fileName))
					celdas.push(wrapCelda(file.size.toString()))
					celdas.push(wrapCelda(file.sha256))
					filas.push(wrapFila(celdas))
				} else {
					response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no tiene los atributos requeridos para normalizarlo'
				}
			})
		} else {
			response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo ' + CUSTOM_TYPE.FILE
		}
		if (filas.length > 0) {
			const cabeceras: string = wrapFila([wrapCabecera('ARCHIVO'), wrapCabecera('TAMAÑO'), wrapCabecera('SHA256')])
			filas.unshift(cabeceras)
			response.valorNormalizado = wrapTabla(filas)
		} else if ((response.valorNormalizado as unknown as string) != (EFirmaDelimitador.DELIMITADOR_VALOR_VACIO as unknown as string)) {
			response.valorNormalizado = wrapValor(response.valorNormalizado)
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
		response.error = 'Ocurrió un error al obtener el valor normalizado del campo ' + getDatosIdCampoFromCampo(campo)
	}
	return response
}

/**
 * @description Extrae el valor normalizado de un campo o dcelda de tabla de tipo CUSTOM_TYPES.DATE
 */
const getDatosValorNormalizadoFromCustomTypeDATE = (campo: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	try {
		const valor: Date = getDatosValorFromCampo(campo) as Date
		const type = getDatosTypeFromCampo(campo)

		if (type == CUSTOM_TYPE.DATE) {
			if ((valor as unknown as string) == (EFirmaDelimitador.DELIMITADOR_VALOR_VACIO as unknown as string)) {
				return response
			}

			if (valor) {
				response.valorNormalizado = wrapValor(new Date(valor).toISOString())
			}
		} else {
			response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo DATE'
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
		response.error = 'Ocurrió un error al obtener el valor normalizado del campo ' + getDatosIdCampoFromCampo(campo)
	}
	return response
}

/**
 * @description Extrae el valor normalizado de un campo o celda de tabla de tipo CUSTOM_TYPES.TABLE
 */
const getDatosValorNormalizadoFromCustomTypeTABLE = (campo: IDatoFormularioInput | ICustomTypeUnion): IDatoValorNormalizado => {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	try {
		const valor: ICustomTypeUnion[][] = getDatosValorFromCampo(campo) as ICustomTypeUnion[][]
		const type = getDatosTypeFromCampo(campo)
		const filas: string[] = []

		if (Array.isArray(valor) && type == CUSTOM_TYPE.TABLE) {
			valor.forEach(fila => {
				if (Array.isArray(fila)) {
					const celdas: string[] = []
					let contadorVacios: number = 0

					fila.forEach(celda => {
						const valorNormalizadoCampo = getDatosValorNormalizadoFromCampo(celda).valorNormalizado
						const valorCelda = wrapCelda(valorNormalizadoCampo, celda.type)
						if (isVacio(valorNormalizadoCampo)) {
							contadorVacios++
						}
						celdas.push(valorCelda)
					})
					if (celdas.length != contadorVacios) {
						filas.push(wrapFila(celdas))
					}
				} else {
					response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo Table en su estructura de filas'
				}
			})
		} else {
			response.error = 'El valor de campo ' + getDatosIdCampoFromCampo(campo) + ' no es de tipo Table'
		}
		if (filas.length > 0) {
			//TODO: Agregar fila de cabeceras de campos
			response.valorNormalizado = wrapTabla(filas)
			// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
		} else if (response.valorNormalizado != EFirmaDelimitador.DELIMITADOR_VALOR_VACIO) {
			response.valorNormalizado = wrapValor(response.valorNormalizado)
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado desde campo ' + getDatosIdCampoFromCampo(campo), e)
		response.error = 'Ocurrió un error al obtener el valor normalizado del campo ' + getDatosIdCampoFromCampo(campo)
	}
	return response
}

/*****PROPIEDADES****/
/**
 * @description Extrae el type (CUSTOM_TYPE) de un campo o de una celda de tabla
 */
export const getDatosTypeFromCampo = (campo: IDatoFormularioInput | ICustomTypeUnion): CUSTOM_TYPE | undefined => {
	let response: CUSTOM_TYPE | undefined = undefined
	try {
		if (campo.type) {
			response = campo.type
		}
		if ('valor' in campo) {
			if ('type' in campo.valor!) {
				response = campo.valor.type
			}
		}
	} catch (e) {
		console.error('Error al obtener type de campo ' + getDatosIdCampoFromCampo(campo), e)
	}
	return response
}

/**
 * @description Extrae el id de un campo o el JSON de una celda de tabla
 */
export const getDatosIdCampoFromCampo = (campo: IDatoFormularioInput | ICustomTypeUnion): string => {
	let response: string = ''
	try {
		if ('idCampo' in campo) {
			response = campo.idCampo!
		} else {
			response = JSON.stringify(campo)
		}
	} catch (e) {
		console.error('Error al obtener id de campo ', campo, e)
	}
	return response
}
