/**
 * @name CTDMedicamentosModulo2
 * @author @tirsomartinezreyes
 * @version 0.0.4
 *
 * @description Define la estructura del Módulo 2 (Summaries)
 * del CTD (Common Technical Document) para Registro de Medicamentos.
 *
 * #changelog 0.0.0.4 - Corrección de typo en 2.7.6
 */
import {
	//ECTDCaracteresIdentificadoresReservados, //Enumeración que define los caracteres identificadores reservados en identificadores de nodos del CTD
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

/**
 * @name CTDMedicamentosModulo2
 */
export const CTDMedicamentosModulo2: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm2',
	nombre: 'm2',
	descripcion: 'Módulo 2: Resúmenes',
	hijos: [
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '2.1',
			nombre: '21-toc',
			descripcion: 'Tabla de Contenido del Módulo 2',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '2.2',
			nombre: '22-intro',
			descripcion: 'Introducción',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '2.3',
			nombre: '23-qos',
			descripcion: 'Resumen general de calidad',
			hijos: [
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.3.1',
					nombre: '231-mx-quality-overall-sum',
					descripcion: 'Resumen general de calidad del medicamento',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.3.s',
					nombre: '23s-mx-drug-sub',
					descripcion: 'Fármaco / Biofármaco / antígeno',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.3.p',
					nombre: '23p-mx-drug-prod',
					descripcion: 'Producto Terminado',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.3.a',
					nombre: '23a-mx-app',
					descripcion: 'Apéndices',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.a.1',
							nombre: '23a1-mx-fac-equip',
							descripcion: 'Instalaciones y Equipos',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.a.2',
							nombre: '23a2-mx-safety-evaluation-advent-agent',
							descripcion: 'Evaluación de seguridad de agentes adventicios',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.a.3',
							nombre: '23a3-mx-excip',
							descripcion: 'Aditivos',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.3.r',
					nombre: '23r-mx-reg-info',
					descripcion: 'Información Regional',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.r.1',
							nombre: '23r1-mx-documentacion-produccion',
							descripcion: 'Documentación de producción',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.r.2',
							nombre: '23r2-mx-documentacion-control-calidad',
							descripcion: 'Otros documentos del control de calidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.r.3',
							nombre: '23r3-mx-dispositivos-medicos',
							descripcion: 'Dispositivo(s) Médico(s)',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.r.4',
							nombre: '23r4-mx-comparabilidad',
							descripcion: 'Comparabilidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.r.5',
							nombre: '23r5-mx-biocomparabilidad',
							descripcion: 'Biocomparabilidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.r.6',
							nombre: '23r6-mx-extrapolacion',
							descripcion: 'Extrapolación',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.r.7',
							nombre: '23r7-mx-certificado-identidad-taxonomica-planta',
							descripcion: 'Certificado de identidad taxonómica de la planta',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '2.3.r.8',
							nombre: '23r8-mx-evaluacion-riesgo-medio-ambiente',
							descripcion: 'Evaluación del riesgo para el medio ambiente',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '2.4',
			nombre: '24-nonclin-over',
			descripcion: 'Resumen global preclínico',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '2.5',
			nombre: '25-clin-over',
			descripcion: 'Resumen global clínico',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '2.6',
			nombre: '26-nonclin-sum',
			descripcion: 'Resumen escrito y tabulado preclínico',
			hijos: [
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.6.1',
					nombre: '261-mx-intro',
					descripcion: 'Introducción',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.6.2',
					nombre: '262-mx-pharmacol-written-sum',
					descripcion: 'Resumen escrito de farmacología',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.6.3',
					nombre: '263-mx-pharmacol-tabulated-sum',
					descripcion: 'Resumen tabulado de farmacología',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.6.4',
					nombre: '264-mx-pk-written-sum',
					descripcion: 'Resumen escrito de farmacocinética',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.6.5',
					nombre: '265-mx-pk-tabulated-sum',
					descripcion: 'Resumen tabulado de farmacocinética',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.6.6',
					nombre: '266-mx-tox-written-sum',
					descripcion: 'Resumen escrito de toxicología',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.6.7',
					nombre: '267-mx-tox-tabulated-sum',
					descripcion: 'Resumen tabulado de toxicología',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '2.7',
			nombre: '27-clin-sum',
			descripcion: 'Resumen Clínico',
			hijos: [
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.7.1',
					nombre: '271-mx-biopharm-stud-methods-sum',
					descripcion: 'Resumen de estudios biofarmacéuticos y métodos analíticos asociados ',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.7.2',
					nombre: '272-mx-clin-pharmacol-stud-sum',
					descripcion: 'Resumen de los estudios de farmacología clínica',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.7.3',
					nombre: '273-mx-clin-effic-sum',
					descripcion: 'Resumen de eficacia clínica',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.7.4',
					nombre: '274-mx-clin-safety-sum',
					descripcion: 'Resumen de seguridad clínica',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.7.5',
					nombre: '275-mx-lit-ref',
					descripcion: 'Literatura de referencia',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '2.7.6',
					nombre: '276-mx-synopses-individual-stud',
					descripcion: 'Sinopsis de estudios individuales',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				}
			]
		}
	]
}
