/**
 * @name CTDMedicamentosModulo1
 * @author @tirsomartinezreyes
 * @version 0.0.9
 *
 * @description Define la estructura del Módulo 1 (Administrative Information and Prescribing Information )
 * del CTD (Common Technical Document) para Registro de Medicamentos.
 *
 * @changelog 0.0.9 - 02/sep/2024 - Se corrige typo en 1.13.3,
 * @changelog 0.0.8 - 28/agosto/24 - Se corrige typo en 1.3.5, 1.20.2.1
 * @changelog 0.0.7 - Corrección de typos en 1.3.3, 1.5.3.2, 1.10.2 y 1.14.6
 */

import {
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

/**
 * @name CTDMedicamentosModulo1
 * @description Define la estructura del Módulo 1 del CTD (Common Technical Document) para Registro de Medicamentos.
 */
export const CTDMedicamentosModulo1: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm1',
	nombre: 'm1',
	descripcion: 'Módulo 1: Documentación e Información Administrativa - Legal',
	hijos: [
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: 'mx',
			nombre: 'mx',
			descripcion: 'Región: México',
			hijos: [
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.toc',
					nombre: '1-toc',
					descripcion: 'Tabla de Contenido del expediente',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.1',
					nombre: '11-solicitud-documentos-relacionados',
					descripcion: 'Solicitud y Documentos Relacionados',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.1.1',
							nombre: '111-formatos',
							descripcion: 'Formatos',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.1.1.1',
									nombre: '1111-formato-solicitud-autorizacion',
									descripcion: 'Formato de Autorizaciones, Certificados y Visitas FF-COFEPRIS-01',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.1.1.2',
									nombre: '1112-formato-solicitud-modificacion',
									descripcion: 'Formato de Modificación a las Condiciones del Registro Sanitario FF-COFEPRIS-15',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.1.1.3',
									nombre: '1113-formato-solicitud-prorroga',
									descripcion: 'Formato de Prórroga de Registro Sanitario FF-COFEPRIS-14',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.1.2',
							nombre: '112-cartas-anexas-solicitud',
							descripcion: 'Cartas Anexas a la Solicitud',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.1.2.1',
									nombre: '1121-carta-presentacion-registro-sanitario-solicitado',
									descripcion: 'Carta de Presentación del Registro Sanitario Solicitado',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.WORD]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.1.2.2',
									nombre: '1122-carta-presentacion-justificacion-cambio',
									descripcion: 'Carta de presentación de modificaciones a las condiciones de registro sanitario (Dice/Debe decir) y justificación del cambio',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.WORD]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.1.2.3',
									nombre: '1123-carta-respuesta-prevencion',
									descripcion: 'Carta de Respuesta al Oficio de Prevención',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.WORD]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.1.2.4',
									nombre: '1124-carta-solicitud-prorroga',
									descripcion: 'Carta de Solicitud de Prórroga',
									instrucciones: 'Puede ser la carta de presentación de solicitud de prórroga (ordinaria) o carta de apego al ACUERDO (Quinto Transitorio. Para prórrogas retroactivas))',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.WORD]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.1.3',
							nombre: '113-documentacion-relacionada-solicitud-modificaciones',
							descripcion: 'Documentación relacionada a las solicitudes de modificaciones a las condiciones del registro sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.1.4',
							nombre: '114-estatus-regulatorio-internacional',
							descripcion: 'Estatus Regulatorio Internacional',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.1.5',
							nombre: '115-documento-acredite-personalidad-juridica',
							descripcion: 'Documento que Acredita la Personalidad Jurídica o RUPA',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.2',
					nombre: '12-pago-derechos',
					descripcion: 'Comprobante del pago de derechos',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.2.1',
							nombre: '121-comprobantes-pago',
							descripcion: 'Comprobantes de pago',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.3',
					nombre: '13-oficios-constancias-relacionadas',
					descripcion: 'Oficios de Registro Sanitario y Constancias Relacionadas al Medicamento',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.3.1',
							nombre: '131-ultimo-oficio-registro-sanitario',
							descripcion: 'Oficio de Registro Sanitario del medicamento (última actualización)',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.3.2',
							nombre: '132-oficios-diluyentes-dispositivos-constancias',
							descripcion: 'Oficio de Registro Sanitario del diluyente, dispositivo médico o del medicamento y constancias relacionadas',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.3.3',
							nombre: '133-oficio-cancelacion',
							descripcion: 'Oficio de cancelación',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.3.4',
							nombre: '134-constancia-prorroga-registro-sanitario',
							descripcion: 'Constancia de prórroga de Registro Sanitario de Medicamentos',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.3.5',
							nombre: '135-constancias-modificaciones-menores-moderadas',
							descripcion: 'Constancias de modificaciones menores y moderadas a las condiciones de Registro Sanitario de Medicamentos',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.4',
					nombre: '14-anexos',
					descripcion: 'Anexos',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.4.1',
							nombre: '141-anexos-para-autorizacion',
							descripcion: 'Anexos para Autorización',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.1.1',
									nombre: '1411-proyectos-etiqueta',
									descripcion: 'Proyectos de Etiqueta',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.WORD]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.1.2',
									nombre: '1412-proyectos-instructivo',
									descripcion: 'Proyectos de Instructivo (Cuando aplique)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.WORD]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.1.3',
									nombre: '1413-proyectos-ippa',
									descripcion: 'Proyectos de la información para prescribir en su versión amplia',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.WORD]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.1.4',
									nombre: '1414-proyectos-ippr',
									descripcion: 'Proyectos de la información para prescribir en su versión reducida',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.WORD]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.4.2',
							nombre: '142-anexos-previamente-autorizados',
							descripcion: 'Anexos Previamente Autorizados',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.2.1',
									nombre: '1421-etiquetas-previamente-autorizadas',
									descripcion: 'Etiquetas previamente autorizadas',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.2.2',
									nombre: '1422-instructivo-previamente-autorizado',
									descripcion: 'Instructivo previamente autorizado (si aplica)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.2.3',
									nombre: '1423-ippa-previamente-autorizada',
									descripcion: 'Información para prescribir en su versión amplia, previamente autorizada',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.2.4',
									nombre: '1424-ippr-previamente-autorizada',
									descripcion: 'Información para prescribir en su versión reducida, previamente autorizada',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.4.3',
							nombre: '143-control-cambios',
							descripcion: 'Control de Cambios',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.3.1',
									nombre: '1431-proyectos-etiqueta',
									descripcion: 'Proyectos de etiqueta',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.3.2',
									nombre: '1432-proyectos-instructivos',
									descripcion: 'Proyectos de Instructivos (cuando aplique)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.3.3',
									nombre: '1433-proyectos-ippa',
									descripcion: 'Proyectos de la Información para Prescribir en su versión amplia',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.4.3.4',
									nombre: '1434-proyectos-ippr',
									descripcion: 'Proyectos de la Información para Prescribir en su versión reducida',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.4.4',
							nombre: '144-proyectos-prescribir-referencias',
							descripcion: 'Proyectos de Información para Prescribir Referenciando las Secciones del Expediente que Sustenten cada uno de los Rubros',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.WORD]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.4.5',
							nombre: '145-resumen-caracteristicas-producto',
							descripcion: 'Resumen de las características del producto (SmPC), información para prescribir o documento equivalente autorizado por la ARN',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.5',
					nombre: '15-titular',
					descripcion: 'Titular del Registro Sanitario',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.5.1',
							nombre: '151-titular-territorio-nacional',
							descripcion: 'Titular en Territorio Nacional',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.5.1.1',
									nombre: '1511-licencia-sanitaria-aviso-responsable-sanitario',
									descripcion: 'Licencia sanitaria de fábrica o laboratorio de medicamentos o productos biológicos para uso humano y Aviso de Responsable Sanitario',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.5.2',
							nombre: '152-titular-extranjero',
							descripcion: 'Titular en el extranjero',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.5.2.1',
									nombre: '1521-acreditacion-permiso',
									descripcion: 'Licencia, certificado o documento que acredite que la empresa titular del Registro Sanitario cuenta con el permiso para fabricar medicamentos',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.5.3',
							nombre: '153-cesion-derechos',
							descripcion: 'Cesión de derechos (solicitud de modificación)',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.5.3.1',
									nombre: '1531-escritura-instrumento-cesion-derechos',
									descripcion: 'Escritura o instrumento público donde conste la cesión de derechos',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.5.3.2',
									nombre: '1532-escritura-instrumento-poderes-cesion-derechos',
									descripcion: 'Escritura o instrumento público donde consten los poderes de las personas que llevan a cabo la cesión de derechos.',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.6',
					nombre: '16-fabricante-farmacos-biofarmacos-antigenos',
					descripcion: 'Fabricante de fármaco/biofármaco/antígeno',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.6.1',
							nombre: '161-certificado-buenas-practicas',
							descripcion: 'Certificado de Buenas Prácticas de Fabricación o documento equivalente',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.6.2',
							nombre: '162-licencia-sanitaria-aviso-responsable-sanitario',
							descripcion: 'Licencia sanitaria y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.7',
					nombre: '17-fabricante-productos-intermedios',
					descripcion: 'Fabricante de Productos Intermedios',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.7.1',
							nombre: '171-certificado-buenas-practicas',
							descripcion: 'Certificado de Buenas Prácticas de Fabricación o documento equivalente',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.7.2',
							nombre: '172-licencia-sanitaria-aviso-responsable-sanitario',
							descripcion: 'Licencia sanitaria y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.8',
					nombre: '18-fabricante-medicamento-biologico',
					descripcion: 'Fabricante de Medicamento o Producto Biológico',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.8.1',
							nombre: '181-certificado-buenas-practicas',
							descripcion: 'Certificado de Buenas Prácticas de Fabricación o documento equivalente',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.8.2',
							nombre: '182-licencia-sanitaria-aviso-responsable-sanitario',
							descripcion: 'Licencia sanitaria y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.9',
					nombre: '19-fabricante-diluyente-adyuvante',
					descripcion: 'Fabricante del diluyente y/o adyuvante',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.9.1',
							nombre: '191-certificado-buenas-practicas',
							descripcion: 'Certificado de Buenas Prácticas de Fabricación o documento equivalente',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},

						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.9.2',
							nombre: '192-licencia-sanitaria-aviso-responsable-sanitario',
							descripcion: 'Licencia sanitaria y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.10',
					nombre: '110-acondicionadores',
					descripcion: 'Acondicionador(es)',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.10.1',
							nombre: '1101-acondicionador-primario',
							descripcion: 'Acondicionador primario',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.10.1.1',
									nombre: '11011-certificado-buenas-practicas',
									descripcion: 'Certificado de Buenas Prácticas de Fabricación o Documento Equivalente',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.10.1.2',
									nombre: '11012-licencia-sanitaria-aviso-responsable-sanitario',
									descripcion: 'Licencia sanitaria y Aviso de Responsable Sanitario',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.10.2',
							nombre: '1102-acondicionador-secundario',
							descripcion: 'Acondicionador secundario',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.10.2.1',
									nombre: '11021-licencia-sanitaria-certificado-buenas-practicas',
									descripcion: 'Licencia sanitaria o Certificado de Buenas Prácticas de Fabricación o documento equivalente',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.10.2.2',
									nombre: '11022-aviso-responsable-sanitario',
									descripcion: 'Aviso de Responsable Sanitario',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.11',
					nombre: '111-almacen',
					descripcion: 'Almacén(es)',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.11.1',
							nombre: '1111-licencia-sanitaria-aviso-responsable-sanitario',
							descripcion: 'Licencia sanitaria y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.11.2',
							nombre: '1112-aviso-funcionamiento-aviso-responsable-sanitario',
							descripcion: 'Aviso de Funcionamiento y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.12',
					nombre: '112-distribuidor',
					descripcion: 'Distribuidor(es)',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.12.1',
							nombre: '1121-licencia-sanitaria-aviso-responsable-sanitario',
							descripcion: 'Licencia sanitaria y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.12.2',
							nombre: '1122-aviso-funcionamiento-aviso-responsable-sanitario',
							descripcion: 'Aviso de Funcionamiento y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.13',
					nombre: '113-requisitos-titulares-extranjeros',
					descripcion: 'Requisitos para Titulares Extranjeros',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.13.1',
							nombre: '1131-documento-acreditacion-representante-legal',
							descripcion: 'Documento que acredite a un Representante Legal con domicilio en los Estados Unidos Mexicanos.',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.13.2',
							nombre: '1132-oficio-reconocimiento-farmacovigilancia',
							descripcion: 'Oficio de reconocimiento de la Unidad de Famacovigilancia del Representante Legal en México o última actualización',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.13.3',
							nombre: '1133-licencia-sanitaria-aviso-funcionamiento-importador',
							descripcion: 'Licencia sanitaria o Aviso de Funcionamiento del importador del medicamento o producto biológico (cuando aplique) y Aviso de Responsable Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.13.4',
							nombre: '1134-control-calidad',
							descripcion: 'Control de Calidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.13.4.1',
									nombre: '11341-contrato-representate-legal-tercero-autorizado',
									descripcion: 'Contrato celebrado entre el representante legal y un laboratorio Tercero Autorizado',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.13.4.2',
									nombre: '11342-licencia-sanitaria',
									descripcion: 'Licencia Sanitaria',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.14',
					nombre: '114-convenios-acuerdos',
					descripcion: 'Convenios o Acuerdos',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.14.1',
							nombre: '1141-convenio-maquila',
							descripcion: 'Convenio o Acuerdo de Maquila',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},

						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.14.2',
							nombre: '1142-convenio-acondicionado',
							descripcion: 'Convenio o acuerdo de acondicionado',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.14.3',
							nombre: '1143-convenio-almacenamiento-distribucion',
							descripcion: 'Convenio o Acuerdo de Almacenamiento y/o Distribución',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.14.4',
							nombre: '1144-convenio-licenciatario',
							descripcion: 'Convenio, acuerdo o documento que acredite al licenciatario del medicamento',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.14.5',
							nombre: '1145-convenio-comercializacion-calidad-combo',
							descripcion: 'Contrato de comercialización y/o convenio de calidad, así como consentimiento entre los titulares de los registros que integran el combo (cuando aplique)',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.14.6',
							nombre: '1146-convenio-calidad',
							descripcion: 'Convenios o acuerdos de calidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.15',
					nombre: '115-comite-moleculas-nuevas',
					descripcion: 'Comité de Moléculas Nuevas',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.15.1',
							nombre: '1151-oficio-opinion-cmn',
							descripcion: 'Oficio con la opinión técnica del Comité de Moléculas Nuevas',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.15.2',
							nombre: '1152-respuesta-oficio-opinion-cmn',
							descripcion: 'Respuesta a las observaciones del oficio de opinión técnica del Comité de Moléculas Nuevas',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.15.3',
							nombre: '1153-documentacion-pmr',
							descripcion: 'Documentación que acredite el sometimiento del Plan de Manejo de Riesgos',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.16',
					nombre: '116-patentes',
					descripcion: 'Información de Patentes',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.16.1',
							nombre: '1161-documentacion-patentes',
							descripcion: 'Documentación que demuestre que el solicitante del Registro Sanitario es el titular de la patente o que cuenta con la licencia correspondiente, ambas inscritas en el IMPI',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.16.2',
							nombre: '1162-escrito-bajo-protesta-patentes',
							descripcion: 'Escrito bajo protesta de decir verdad en materia de patentes',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.17',
					nombre: '117-denominacion-distintiva',
					descripcion: 'Denominación Distintiva',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.17.1',
							nombre: '1171-propuesta-denominacion-distintiva',
							descripcion: 'Propuestas de denominación distintiva',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.17.2',
							nombre: '1172-declaratoria-no-denominacion-distintiva',
							descripcion: 'Declaratoria en la que manifieste que no requiere denominación distintiva para el medicamento genérico',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.18',
					nombre: '118-certificado-libre-venta',
					descripcion: 'Certificado de libre venta o documento equivalente ',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.18.1',
							nombre: '1181-certificado-libre-venta',
							descripcion: 'Certificado de libre venta (CLV) o equivalente expedido por la autoridad correspondiente del país de origen',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.18.2',
							nombre: '1182-nuevas-entidades-moleculares',
							descripcion: 'Documentación para nuevas entidades moleculares que no cuenten con CLV o equivalente.',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.18.2.1',
									nombre: '11821-listado-informes-estudios-clinicos',
									descripcion: 'Listado de los informes de estudios clínicos que cuenten con la participación de población mexicana',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.18.2.2',
									nombre: '11822-documento-intervenciones',
									descripcion:
										'Documento que describa las actividades e intervenciones designadas para caracterizar y prevenir los potenciales riesgos previamente identificados, relacionados con los medicamentos, incluyendo la medición de la efectividad de dichas intervenciones',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.19',
					nombre: '119-carta-representacion',
					descripcion: 'Carta de representación entre el titular y el fabricante del medicamento',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.20',
					nombre: '120-requerimientos-especificos',
					descripcion: 'Requerimientos específicos para diferentes tipos de solicitudes',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.20.1',
							nombre: '1201-hemoderivados',
							descripcion: 'Hemoderivados',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.20.1.1',
									nombre: '12011-certificado-expediente-maestro-plasma',
									descripcion: 'Certificado del Expediente Maestro del Plasma/Plasma Master File (PMF)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.20.2',
							nombre: '1202-genericos',
							descripcion: 'Medicamento genérico',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.20.2.1',
									nombre: '12021-pagina-acuerdo-intercambiabilidad',
									descripcion: 'Página del “ACUERDO que determina el tipo de prueba para demostrar intercambiabilidad de medicamentos genéricos"',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.20.2.2',
									nombre: '12022-documento-medicamento-referencia',
									descripcion: 'Listado u oficio emitido por la Secretaría donde se indique el medicamento de referencia',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.20.2.3',
									nombre: '12023-estudios-intercambiabilidad',
									descripcion: 'Estudios de intercambiabilidad realizados en el extranjero',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.20.3',
							nombre: '1203-prorroga',
							descripcion: 'Prórroga del Registro Sanitario',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.20.3.1',
									nombre: '12031-informe-farmacovigilancia-medicamento',
									descripcion: 'Informe de farmacovigilancia del medicamento.',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.21',
					nombre: '121-acuerdos-equivalencia',
					descripcion: 'Acuerdos de Equivalencia',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.21.1',
							nombre: '1211-canada',
							descripcion: 'Ministerio de Salud de Canadá (Health Canada)',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.1.1',
									nombre: '12111-aviso-cumplimiento',
									descripcion: 'Aviso de cumplimiento (Notice of compliance)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.1.2',
									nombre: '12112-certificado-producto-farmaceutico',
									descripcion: 'Certificado de Producto Farmacéutico (Certificate of Pharmaceutical Product)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.1.3',
									nombre: '12113-licencia-establecimiento',
									descripcion: 'Licencia del establecimiento (Establishment Licence Number)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.21.2',
							nombre: '1212-australia',
							descripcion: 'Administración de Productos Terapéuticos de Australia (TGA)',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.2.1',
									nombre: '12121-carta-aprobacion',
									descripcion: 'Carta de Aprobación para el Registro',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.21.3',
							nombre: '1213-comision-europea',
							descripcion: 'Comisión Europea',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.3.1',
									nombre: '12131-autorizacion-comercializacion',
									descripcion: 'Autorización de Comercialización',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.21.4',
							nombre: '1214-estados-unidos',
							descripcion: 'Administración de Alimentos y Medicamentos de los Estados Unidos de América (FDA)',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.4.1',
									nombre: '12141-carta-aprobacion',
									descripcion: 'Carta de Aprobación (Approval Letter)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.4.2',
									nombre: '12142-certificado-gobierno-extranjero',
									descripcion: 'Certificado para Gobierno Extranjero, CPP o EIR.',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.4.3',
									nombre: '12143-documento-registro-establecimiento',
									descripcion: 'Documento del Registro del Establecimiento de medicamentos (Registration of Drug Establishment) o equivalente',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.21.5',
							nombre: '1215-suiza',
							descripcion: 'Agencia Suiza para Productos Terapéuticos (Swissmedic)',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.5.1',
									nombre: '12151-autorizacion-comercializacion',
									descripcion: 'Autorización de Comercialización de productos medicinales',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.21.6',
							nombre: '1216-oms',
							descripcion: 'Precalificación de la OMS',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.6.1',
									nombre: '12161-referencia-oms',
									descripcion: 'El número de referencia otorgado por la OMS al medicamento precalificado y la fecha de precalificación',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.EXCEL]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.6.2',
									nombre: '12162-detalles-producto',
									descripcion: 'La impresión de los detalles del producto y de acondicionamiento, condiciones de almacenamiento y fabricantes, publicados en el sitio web de la OMS',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '1.21.6.3',
									nombre: '12163-enlace-oms',
									descripcion:
										'El enlace electrónico a la lista de medicamentos precalificados albergada en el sitio web de la OMS que permita constatar que el medicamento objeto de la solicitud no ha sido suspendido o removido de la lista, así como sus características de calidad',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO,
											documentoFormato: [ECTDFormatoDocumento.EXCEL]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.21.7',
							nombre: '1217-otros-acuerdos',
							descripcion: 'Otros Acuerdos',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '1.22',
					nombre: '122-producto-huerfano',
					descripcion: 'Estatus Internacional de Producto Huérfano',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.22.1',
							nombre: '1221-documento-autorizacion',
							descripcion: 'Documento que avale la autorización de designación y comercialización de Producto Huérfano',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '1.22.2',
							nombre: '1222-literatura-referencia',
							descripcion: 'Literatura de referencia',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				}
			]
		}
	]
}
