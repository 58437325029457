export enum ComponentViews {
	Alert = 'Alert',
	Avatar = 'Avatar',
	BreadCrumb = 'BreadCrumb',
	Button = 'Button',
	ButtonGroup = 'ButtonGroup',
	Card = 'Card',
	Carousel = 'Carousel',
	CheckBox = 'CheckBox',
	Chip = 'Chip',
	ContentControl = 'ContentControl',
	Counter = 'Counter',
	DataTable = 'DataTable',
	DateInput = 'DateInput',
	FileInput = 'FileInput',
	Image = 'Image',
	Label = 'Label',
	ListView = 'ListView',
	ModalField = 'ModalField',
	OptionList = 'OptionList',
	ProgressBar = 'ProgressBar',
	ProgressCircle = 'ProgressCircle',
	RadioGroup = 'RadioGroup',
	Row = 'Row',
	ScrollContainer = 'ScrollContainer',
	Select = 'Select',
	Divider = 'Divider',
	Switch = 'Switch',
	Tab = 'Tab',
	TextArea = 'TextArea',
	TextInput = 'TextInput',
	TimeInput = 'TimeInput',
	TimePicker = 'TimePicker',
	PopOver = 'PopOver',
	// Containers
	DropContainer = 'DropContainer',
	DraggableContainer = 'DraggableContainer',
	SelectableContainer = 'SelectableContainer',
	ItemsContainer = 'ItemsContainer',
	AccessLevelContainer = 'AccessLevelContainer',
	ComentariosContainer = 'ComentariosContainer',
	EliminableContainer = 'EliminableContainer',
	SuggestionsContainer = 'SuggestionsContainer',
	Unknown = 'Unknown'
}
