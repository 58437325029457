<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'
import { redirectToPathByRoleAndGroup } from 'src/router/redirect'
import { RolType } from 'cofepris-typesafe/Types/Roles'

const props = defineProps<{
	rol: RolType
	grupo: string
	path?: string
}>()

let timeout: ReturnType<typeof setTimeout | undefined> = undefined

//TODO: REvisar a conciencia la redirección en página con acceso anónimo, por ekjemplo, cuando se carga la página inicial con la sesión abierta
// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
onMounted(() => {
	timeout = setTimeout(() => {
		redirectToPathByRoleAndGroup(props.rol, props.grupo)
	}, 3000)
})

onUnmounted(() => {
	if (timeout) {
		clearTimeout(timeout)
	}
})
</script>

<template>
	<div></div>
	<!-- <div>{{ props }}</div> -->
</template>
