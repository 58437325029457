export enum NIVEL_ACCESO {
	NO_CLASIFICADO = 'NO_CLASIFICADO',
	PUBLICO = 'PUBLICO',
	INTERNO = 'INTERNO',
	RESTRINGIDO = 'RESTRINGIDO',
	CONFIDENCIAL = 'CONFIDENCIAL'
}

export enum NIVEL_ACCESO_VALOR {
	NO_CLASIFICADO = -1,
	PUBLICO = 0,
	INTERNO = 1,
	RESTRINGIDO = 2,
	CONFIDENCIAL = 3
}

export enum NIVEL_ACCESO_ETIQUETA {
	NO_CLASIFICADO = 'No Clasificado',
	PUBLICO = 'Público',
	INTERNO = 'Interno',
	RESTRINGIDO = 'Restringido',
	CONFIDENCIAL = 'Confidencial'
}

export enum NIVEL_ACCESO_COLOR {
	NO_CLASIFICADO = '#AAAAAA',
	PUBLICO = '#3498db',
	INTERNO = '#27ae60',
	RESTRINGIDO = '#f1c40f',
	CONFIDENCIAL = '#e74c3c'
}

export enum NIVEL_ACCESO_USO {
	NO_CLASIFICADO = 'No se ha determinado una clasificación para este dato.',
	PUBLICO = 'El dato puede ser accedido en cualquier ámbito, incluída su divulgación pública.',
	INTERNO = 'El dato puede ser accedido en el ámbito de la organización.',
	RESTRINGIDO = 'El dato puede ser accedido en el ámbito de la organización por los usuarios que lo necesiten para efectuar una actividad específica, siempre y cuando cuenten con una autorización explícita vigente.',
	CONFIDENCIAL = 'El dato puede ser accedido en el ámbito de la organización por los usuarios que lo necesiten para efectuar una actividad específica y estrictamente necesaria. Requiere una autorización explícita vigente. El acceso a información con esta clasificación requiere medidas de seguridad adicionales como cifrado, autenticación de múltiples factores y cumplimiento de políticas de resguardo en reposo.'
}

export const MATRIZ_NIVEL_ACCESO_VS_CONTEXTO = {
	NO_CLASIFICADO: {
		NO_CLASIFICADO: false,
		PUBLICO: false,
		INTERNO: false,
		RESTRINGIDO: false,
		CONFIDENCIAL: false
	},
	PUBLICO: {
		NO_CLASIFICADO: false,
		PUBLICO: true,
		INTERNO: true,
		RESTRINGIDO: true,
		CONFIDENCIAL: true
	},
	INTERNO: {
		NO_CLASIFICADO: false,
		PUBLICO: false,
		INTERNO: true,
		RESTRINGIDO: true,
		CONFIDENCIAL: true
	},
	RESTRINGIDO: {
		NO_CLASIFICADO: false,
		PUBLICO: false,
		INTERNO: false,
		RESTRINGIDO: true,
		CONFIDENCIAL: true
	},
	CONFIDENCIAL: {
		NO_CLASIFICADO: false,
		PUBLICO: false,
		INTERNO: false,
		RESTRINGIDO: false,
		CONFIDENCIAL: true
	}
}

/**
 *
 * @param nivelAcceso
 * @param contexto
 * @description Responde de manera booleana a la pregunta ¿Es permitido el nivel de acceso en el contexto?
 */
export const NivelAccesoIsPermitidoEnContexto = (nivelAcceso: NIVEL_ACCESO, contexto: NIVEL_ACCESO): boolean => {
	let response = false
	try {
		response = MATRIZ_NIVEL_ACCESO_VS_CONTEXTO[nivelAcceso][contexto]
	} catch (e) {
		console.error('Error al evaluar el nivel de acceso ' + nivelAcceso + 'en el contexto ' + contexto, e)
	}
	return response
}
