<script lang="ts" setup>
import { onMounted } from 'vue'
import ERROR_ROUTES_ROUTE_NOT_FOUND from 'src/errors/ERROR_ROUTES_ROUTE_NOT_FOUND'
import { useRoute } from 'vue-router'
import { useConfigStore } from 'src/store/config'
import errorSVG from 'src/assets/img/error.svg'

const route = useRoute()

onMounted(() => {
	throw new ERROR_ROUTES_ROUTE_NOT_FOUND('No se encontró la ruta solicitada', route.fullPath)
})
</script>

<template>
	<div class="container100p">
		<div class="section section-error vertical-buffer">
			<div class="row">
				<div class="col-sm-7">
					<h2>La página solicitada no se encuentra en este servidor</h2>
					<h3>Error 404</h3>
					<hr class="red" />
					<p>
						La página solicitada puede no estar disponible, haber cambiado de dirección (URL) o no existir. Con frecuencia es debido a algún error al escribir la dirección en la página (URL).
						Compruebe de nuevo si es correcta.
					</p>
					<hr />

					<div class="text-center m20">
						<router-link :to="{ path: '/' }">
							<button type="button" class="btn btn-primary active">IR A LA PÁGINA INICIAL DE {{ useConfigStore().getConfig().GENERAL.SYSTEM_NAME }}</button>
						</router-link>
					</div>
				</div>
				<div class="col-sm-4 col-sm-offset-1">
					<figure>
						<img :src="errorSVG" alt="Migrando a gob.mx" />
					</figure>
					<h3>Otras opciones:</h3>
					<ul>
						<li>
							<a href="http://www.gob.mx/">Regresar al inicio</a>
						</li>
						<li>
							<a href="http://www.gob.mx/busqueda">Buscar en gob.mx</a>
						</li>
						<li>
							<a href="javascript:history.back();">Volver a la página anterior</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
