/**
 * @name ICTD
 * @author @tirsomartinezreyes
 * @version 0.0.9
 *
 * @description Define la estructura de un CTD (Common Technical Document) y sus nodos.
 * @references https://www.ich.org/page/ct
 *
 * @changelog 0.0.9 - 09/sep/24 - Se agrega ICTDValidacionDossier.nombreEsperadoFolderRaiz
 * @changelog 0.0.8 - 28/ago/24 - Se ajusta ECTDFormatoDocumento y se agrega CCTDFormatoDocumento
 * @changelog 0.0.7 - Se agrega ICTDNodo.archivos para manejar archivos persistidos en los nodos del CTD
 * @changelog 0.0.6 - Se ajusta ICTDRutaCTDValidacionDossier para agregar actualizacion como opcional, necesario para la reactividad en Validador de Dossier de FrontEnd
 */

import { IFileType } from 'cofepris-typesafe/Types/FilesType'
import { CTDNodo } from './CTDNodo.class'
/**
 * @name CCTDVersion
 * @description Versión de la interface ICTD utilizando semver
 */
export const CICTDVersion = '0.0.9'

/**
 * @name ICTD
 * @description Interfaz wrap de la estructura de un CTD para persistencia (con versiones de Interface y Dossier).
 */
export interface ICTD {
	versionICTD: string //Versión de la interface ICTD utilizando semver
	tipoCTD: ECTDTipoCTD //Tipo de CTD (MEDICAMENTOS,DISPOSITIVOS_MEDICOS)
	versionCTD: string //Versión de la estructura del CTD (Dossier) utilizando semver (Puede ser de Medicamentos o Dispositivos Médicos)
	raiz: ICTDNodo //Nodo raíz del CTD
}

/**
 * @name ICTDNodo
 * @description Interfaz que define la estructura de un nodo del CTD.
 */
export interface ICTDNodo {
	tipo: ECTDTipoNodo //Tipo de nodo
	id?: string //Identificador único del nodo en convención del CTD
	nombre?: string //Nombre del Folder o Documento
	descripcion?: string //Descripción del Folder o Documento
	instrucciones?: string //Instrucciones sobre la carga del Folder o Documento
	documentoFormato?: ECTDFormatoDocumento[] //Formato de documento permitido para el Folder o Documento
	hijos?: ICTDNodo[] //Nodos hijos del Folder
	archivos?: IFileType[] //Archivos asociados
}

/**
 * @name ICTDParametrosProcesadorFoldersMultiples
 * @description Interfaz que define los parámetros a reemplazar en Folders Múltiples de un ICTDNodo
 * para la generación del ICTDNodo de un Medicamento o Dispositivo en particular a partir de su CTD base.
 */
export interface ICTDParametrosProcesadorFoldersMultiples {
	base: string | string[] //Identificador del nodo base a partir del cual se buscarán el nodo con id a reemplazar
	tipo: ECTDTipoNodo //Tipo de nodo a reemplazar
	valores: { id: string; nombre: string }[] //Valores a reemplazar por cada ocurriencia del caracter reservado en nodos id y nombre
}

/**
 * @name ECTDTipoCTD
 * @description Enumeración que define los tipos de CTD definidos para el negocio.
 */
export enum ECTDTipoCTD {
	MEDICAMENTOS = 'MEDICAMENTOS',
	DISPOSITIVOS_MEDICOS = 'DISPOSITIVOS_MEDICOS'
}

/**
 * @name ECTDCaracteresIdentificadoresReservados
 * @description Enumeración que define los caracteres identificadores reservados utilizados en la definición de tipos de nodos del CTD.
 */
export enum ECTDCaracteresIdentificadoresReservados {
	SUBPROCESO = 'A', //Subproceso (Activity)
	PRODUCTO_TERMINADO = 'P', //Producto terminado (Finished Product)
	INDICACION_TERAPEUTICA = 'I', //Indicación terapéutica (Indication)
	SISTEMA_CIERRE_CONTENEDOR = 'C', //Sistema de cierre de contenedor (Container Closure System)
	DOCUMENTO = 'D', //Documento
	SITIO = 'F', //Lugar donde se fabrica un fármaco (Facility), se acondiciona, se almacena o se distribuye
	SUJETO = 'M', //Fabricante de un Fármaco (Manufacturer), Acondicionador, Almacén, Distribuidor
	NUMERO = 'N', //Número de documento (Number)
	RAIZ = '{R}', //Nodo Raíz (Root)
	SUSTANCIA = 'S', //Fármaco/Biofármaco/Antígeno/Adyuvante/Diluyente/Aditivo (Substance)
	FOLDER = 'X', //Folder
	MULTIPLE_REPORTE = 'R', //Reporte
	SUSTANCIA_SUJETO = '{SM}', //Sustancia y fabricante
	SUJETO_SITIO = '{MF}', //Fabricante y sitio
	SUBPROCESO_SUJETO_SITIO = '{AMF}', //Subproceso, fabricante y sitio
	SUSTANCIA_SUJETO_SITIO = '{SMF}', //Sustancia, fabricante y sitio
	SUSTANCIA_SUBPROCESO_SUJETO_SITIO = '{SAMF}', //Sustancia, subproceso, fabricante y sitio
	SUJETO_SITIO_UNION_SUBPROCESO_SUJETO_SITIO = '{MF|U|AMF}' //Fabricante y sitio, además de subproceso, fabricante y sitio
}

/**
 * @name ECTDTipoNodo
 * @description Enumeración que define los tipos de nodos del CTD (Common Technical Document).
 */
export enum ECTDTipoNodo {
	FOLDER = 'FOLDER',
	FOLDER_MULTIPLE_SUBPROCESO = 'FOLDER_MULTIPLE_SUBPROCESO', //Folder con múltiples subprocesos (A = 'Activity' en términos de CTD)
	FOLDER_MULTIPLE_SUSTANCIA = 'FOLDER_MULTIPLE_SUSTANCIA', //Folder con múltiples sustancias (S = 'Substance' en términos de CTD)
	FOLDER_MULTIPLE_SUJETO = 'FOLDER_MULTIPLE_SUJETO', //Folder con múltiples fabricantes, acondicionadores, almacenes, Distribuidores  (M = 'Manufacturer' en términos de CTD)
	FOLDER_MULTIPLE_SITIO = 'FOLDER_MULTIPLE_SITIO', //Folder con múltiples sitios de fabricación, acondicionamiento, almacen, distribución (F = 'Facility' en términos de CTD)
	FOLDER_MULTIPLE_PRODUCTO_TERMINADO = 'FOLDER_MULTIPLE_PRODUCTO_TERMINADO', //Folder con múltiples productos terminados (P = 'Finished Product' en términos de CTD)
	FOLDER_MULTIPLE_INDICACION_TERAPEUTICA = 'FOLDER_MULTIPLE_INDICACION_TERAPEUTICA', //Folder con múltiples indicaciones terapéuticas (I = 'Indication' en términos de CTD)
	FOLDER_MULTIPLE_SISTEMA_CIERRE_CONTENEDOR = 'FOLDER_MULTIPLE_SISTEMA_CIERRE_CONTENEDOR', //Folder con múltiples sistemas de cierre de contenedor (C = 'Container Closure System' en términos de CTD)
	FOLDER_MULTIPLE_SUSTANCIA_SUJETO = 'FOLDER_MULTIPLE_SUSTANCIA_SUJETO', //Folder con múltiples sustancias y fabricantes (SM = 'substance-S-manufacturer-M' en términos de CTD)
	FOLDER_MULTIPLE_SUJETO_SITIO = 'FOLDER_MULTIPLE_SUJETO_SITIO', //Folder con múltiples fabricantes y sitios (MF = 'manufacturer-M-facility-F' en términos de CTD)
	FOLDER_MULTIPLE_SUBPROCESO_SUJETO_SITIO = 'FOLDER_MULTIPLE_SUBPROCESO_SUJETO_SITIO', //Folder con múltiples subprocesos, fabricantes y sitios (AMF = 'Activity-A-Manufacturer-M-Facility-F' en términos de CTD)
	FOLDER_MULTIPLE_SUJETO_SITIO_UNION_SUBPROCESO_SUJETO_SITIO = 'FOLDER_MULTIPLE_SUJETO_SITIO_UNION_SUBPROCESO_SUJETO_SITIO', //Folder con múltiples fabricantes y sitios , además de múltiples subprocesos, fabricantes y Sitios (MFAMF = 'Manufacturer-M-Facility-F  y Activity-A-Manufacturer-M-Facility-F' en términos de CTD)
	FOLDER_MULTIPLE_SUSTANCIA_SUJETO_SITIO = 'FOLDER_MULTIPLE_SUSTANCIA_SUJETO_SITIO', //Folder con múltiples sustancias, fabricantes y sitios (SMF = 'substance-S-manufacturer-M-facility-F' en términos de CTD)
	FOLDER_MULTIPLE_SUSTANCIA_SUBPROCESO_SUJETO_SITIO = 'FOLDER_MULTIPLE_SUSTANCIA_SUBPROCESO_SUJETO_SITIO', //Folder con múltiples sustancias, subprocesos, fabricantes y sitios (SMAMF = 'substance-S-Activity-A-Manufacturer-M-Facility-F' en términos de CTD)
	DOCUMENTO = 'DOCUMENTO',
	DOCUMENTO_MULTIPLE = 'DOCUMENTO_MULTIPLE',
	FOLDER_MULTIPLE_REPORTE = 'FOLDER_MULTIPLE_REPORTE', //Folder con múltiples reportes (R = 'Report' en términos de CTD)
	RAIZ = 'RAIZ'
}

/**
 * @name ECTDFormatoDocumento
 * @description Enumeración que define los formatos de documentos válidos para el CTD (Common Technical Document).
 */
export enum ECTDFormatoDocumento {
	FOLDER = 'FOLDER',
	PDF = 'PDF',
	WORD = 'WORD',
	EXCEL = 'EXCEL',
	POWERPOINT = 'POWERPOINT',
	PNG = 'PNG',
	JPG = 'JPG',
	JPEG = 'JPEG',
	ZIP = 'ZIP',
	CUALQUIERA = 'CUALQUIERA'
}

export const CCTDFormatoDocumento: { [key in ECTDFormatoDocumento]: string } = Object.freeze({
	FOLDER: 'carpeta',
	PDF: 'pdf',
	WORD: 'docx',
	EXCEL: 'xlsx',
	POWERPOINT: 'pptx',
	PNG: 'png',
	JPG: 'jpg',
	JPEG: 'jpeg',
	ZIP: 'zip',
	CUALQUIERA: '*'
})

export interface ICTDRutaCTDValidacionDossier {
	actualizacion?: number
	ruta: string
	id: string
	nodo: CTDNodo
	nombre: string
	archivosValidos: IFileType[]
	archivosErroneos: IFileType[]
	archivosIgnorados: IFileType[]
}

export interface ICTDValidacionDossier {
	nombreCorrectoFolderRaiz: boolean
	nombreEsperadoFolderRaiz: string
	nombreFolderRaiz: string
	numeroCarpetas: number
	numeroArchivos: number
	numeroArchivosValidos: number
	numeroArchivosErroneos: number
	numeroArchivosIgnorados: number
	rutasValidas: string[]
	rutasErroneas: string[]
	archivosIgnorados: string[]
	rutasValidadas: ICTDRutaCTDValidacionDossier[]
}

export const obtenerExtensionPorFormatoDocumento = (formato: ECTDFormatoDocumento): string => CCTDFormatoDocumento[formato] ?? ''

export const obtenerFormatoDocumentoPorExtension = (extension: string): ECTDFormatoDocumento | undefined => {
	const formato = Object.keys(CCTDFormatoDocumento).find(key => CCTDFormatoDocumento[key as ECTDFormatoDocumento] === extension)
	return (formato as ECTDFormatoDocumento) ?? undefined
}
