<!-- eslint-disable vue/no-mutating-props -->
<script lang="ts" setup>
const props = defineProps({
	mostrar: {
		type: Boolean,
		default: false
	},
	bold: {
		type: Boolean,
		default: false
	},
	titulo: {
		type: String,
		default: ''
	},
	mensaje: {
		type: String,
		default: ''
	}
})
</script>

<template>
	<!--CargandoModal.vue-->
	<va-modal v-model="props.mostrar" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
		<div class="row" style="display: inline">
			<div class="col-12">
				<div class="text-center" :class="props.bold ? 'bold' : ''">
					{{ props.titulo }}
				</div>
				<div class="text-center mt15 mb15">
					{{ props.mensaje }}
				</div>
				<va-progress-circle indeterminate color="#9d2449" style="margin: auto" />
			</div>
		</div>
	</va-modal>
</template>
