<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useLogRocketStore } from 'src/store/logrocket'
import SWManager from './components/SWManager.vue'
import { useMetaStore } from 'src/store/meta'
import TypeContainer from 'cofepris-typesafe/Types/Container'
import { IErrorModalArgs, IErrorModalService } from 'cofepris-typesafe/Types/ErrorModal'
import ErrorModal from './components/ErrorModal/ErrorModal.vue'
const logRocketStore = useLogRocketStore()
const errorModal = ref<Partial<IErrorModalArgs>>()
const errorService = TypeContainer.get<IErrorModalService>('IErrorModalService')
errorService.set = (v: Partial<IErrorModalArgs>) => {
	errorModal.value = v
	return errorService
}
const route = useRoute()
const cc = ref(0)
//Devsetings - R8: Permitir activar el componente con un huevo de pascua cuando se encuentra en entornos de producción
const mostrarDevSettings = computed(() => useMetaStore().env.DEV || (cc.value != 0 && cc.value % 5 == 0))
</script>

<template>
	<div>
		<Navbar />
		<DevSettings :show="mostrarDevSettings" />
		<ModoOperacion />
		<div class="app-container">
			<div v-show="logRocketStore.initialized" id="logrocket-status-bar" class="alert alert-info fs-xs p5" @click="cc++">
				<div class="row">
					<div class="col-md-2 bold">
						<div v-if="logRocketStore.sessionURL" class="pulse">Grabando sesión</div>
					</div>
					<div class="col-md-10 ma">
						{{ logRocketStore.sessionURL }}
					</div>
				</div>
			</div>
			<SWManager v-if="route.meta.ignoreServiceWorker !== true" />
			<Session :debug="false" :trace="false" class="" />
		</div>
		<ErrorModal v-if="errorModal != undefined" :model="errorModal" />
		<div class="cc" @click="cc += 0.5"></div>
	</div>
</template>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style type="text/css">
@import 'vue-select/dist/vue-select.css';
</style>
<style type="text/css" scoped>
.cc {
	position: fixed;
	left: 10px;
	bottom: 10px;
	width: 20px;
	height: 20px;
	z-index: 1000000;
}
.app-container {
	width: 100%;
	height: auto;
}

#logrocket-status-bar {
	position: fixed;
	text-align: center;
	top: 5px;
	left: 30%;
	width: 40%;
	z-index: 9999;
}

.pulse {
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
		color: rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(128, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(64, 0, 0, 0);
	}
}
</style>
