<script lang="ts" setup>
import { useUserStore } from 'src/store/auth'
import { useConfigStore } from 'src/store/config'
import { EnvironmentType } from 'cofepris-typesafe/Constants/Config'
import { computed, ref } from 'vue'

const props = defineProps({
	debug: {
		type: Boolean,
		default: false
	}
})

const modal = ref(false)

const commit = computed(() => {
	let response = ''
	if (import.meta.env.VITE_GITVERSION != undefined) {
		response = import.meta.env.VITE_GITVERSION
	}
	return response
})

const environment = computed<string>(() => {
	let response = useConfigStore().getEnvironment()
	return response
})

const environments = computed<EnvironmentType[]>(() => {
	let response: EnvironmentType[] = []

	for (let i in useConfigStore().getConfig().ENVIRONMENTS) {
		response.push(i as EnvironmentType)
	}
	return response
})

const show = computed(() => {
	let response = true
	return response
})
const showEnv = computed(() => ![EnvironmentType.DRL].includes(useConfigStore().getEnvironment()))
const getColor = computed(() => {
	let response = 'danger'

	if (environment.value == 'DRL' || environment.value == 'QA') {
		response = 'success'
	}
	return response
})

const clickEnvironment = () => {
	props.debug ? console.log('clickEnvironment') : null
	if (import.meta.env.DEV) {
		modal.value = true
	}
}

const selectEnvironment = (environment: string) => {
	props.debug ? console.log('selectEnvironment', environment) : null
	cancelarEnvironment()
	sessionStorage.setItem('ENVIRONMENT', environment)
	useUserStore().logOut()
	location.reload()
}

const cancelarEnvironment = () => {
	props.debug ? console.log('cancelarEnvironment') : null
	modal.value = false
}

const showButton = (environment: EnvironmentType) => {
	let response = false
	if (environment != useConfigStore().getEnvironment()) {
		response = true
	}
	return response
}
</script>

<template>
	<span v-if="show" @click="clickEnvironment()">
		<va-badge :text="commit" color="warning" class="mt10" />
		<va-badge v-if="showEnv" :text="environment" :color="getColor" class="mt10" />
	</span>

	<va-modal v-model="modal" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
		<div class="row">
			<div class="col-md-12 text-center">
				<div class="text-center">
					<div class="alert alert-info">
						<span class="bold"> El entorno actual es <va-badge :text="environment" :color="getColor" class="m10" /> </span><br /><br />
						<hr class="mt5" />
						<span class="bold">Selecciona un entorno (se cerrará sesión)</span><br />
						<span v-for="(v, k) in environments" :key="k">
							<button v-if="showButton(v)" class="btn btn-primary m10" type="button" @click="selectEnvironment(v)">Cambiar a {{ v }}</button>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt20">
			<div class="col-md-12 text-center">
				<button class="btn btn-danger" type="button" @click="cancelarEnvironment">CANCELAR</button>
			</div>
		</div>
	</va-modal>
</template>
