//Packages
import { AnyEventObject } from 'xstate'
import * as CBOR from 'cbor-redux'

//Team Packages
import {
	createPublicKeyCredential,
	getCredentialAssertionData,
	stringToByteArray,
	byteArrayToBase64,
	base64ToByteArray,
	base64UrlToBase64,
	stringSHA256,
	COSEtoASN1PublicKey,
	verifyECP256Signature
} from 'webauthn'

//Project imports
import { useUserStore } from 'src/store/auth'
import { useConfigStore } from 'src/store/config'
import { AuthenticationAPIRequest, AuthenticationAPIResponse, AuthenticationAPIRequestBody } from 'cofepris-typesafe/Types/Auth'
import { callAuthenticationAPI } from 'src/controllers/auth'

//local imports
import { WebAuthnContext } from './types'

const doGetRegistrationMessage = function (context: WebAuthnContext, event: AnyEventObject): Promise<string | null> {
	context.debug && console.log('on doGetRegistrationMessage', event)
	return new Promise((resolve, reject) => {
		const B64CBORCredentialRegistrationData = byteArrayToBase64(new Uint8Array(CBOR.encode(context.credential)))

		const body: AuthenticationAPIRequestBody['WEBAUTHN_GET_AUTHENTICATOR_MESSAGE'] = {
			credentialRegistrationData: context.credential as Credential,
			B64CBORCredentialRegistrationData
		}

		const request: AuthenticationAPIRequest = {
			accion: 'WEBAUTHN_GET_AUTHENTICATOR_MESSAGE',
			body: JSON.stringify(body)
		}

		callAuthenticationAPI(request, useUserStore().User.IdentityJWT).then((response: AuthenticationAPIResponse) => {
			if (response.status == 'OK') {
				const sha256 = stringSHA256(response.data.messageForSign?.message as string)
				if (sha256 !== (response.data.messageForSign?.sha256 as string)) {
					console.error('Los hashes del servicio y calculados no coinciden', [sha256, response.data.messageForSign?.sha256 as string])
				}

				resolve(response.data.messageForSign?.message as string)
			} else {
				console.error('Error al obtener el registro del mensaje', response)
				reject(response.message)
			}
		})
	})
}

const doCreateCredential = function (context: WebAuthnContext, event: AnyEventObject): Promise<Credential | null> {
	context.debug && console.log('on doCreateCredential', event)
	return new Promise((resolve, reject) => {
		const rpid = globalThis.location ? globalThis.location.hostname : 'localhost'
		const username = useUserStore().User.identityToken.data.uid + useConfigStore().getEnvironmentConfig().AUTHENTICATION.WEBAUTHN_USERNAME_POSTFIX
		const displayName = useUserStore().User.identityToken.data.nombre + useConfigStore().getEnvironmentConfig().AUTHENTICATION.WEBAUTHN_USERNAME_POSTFIX + '-' + rpid
		const options = {
			challenge: stringToByteArray(context.challenge as string),
			rp: {
				name: useConfigStore().getEnvironmentConfig().AUTHENTICATION.ORGANIZATION_GROUP,
				id: rpid
			},
			user: {
				id: stringToByteArray(username),
				name: username,
				displayName: displayName
			},
			pubKeyCredParams: [{ alg: -7, type: 'public-key' }],
			authenticatorSelection: {
				authenticatorAttachment: 'cross-platform',
				residentKey: 'required',
				userVerification: 'required'
			},
			timeout: 60000,
			attestation: 'direct'
		} as PublicKeyCredentialCreationOptions
		context.debug && console.log('options:', options)
		createPublicKeyCredential(options)
			.then(credential => {
				resolve(credential)
			})
			.catch(error => {
				console.error('error', error)
				reject('Error al solicitar el autenticador: ' + error)
			})
	})
}

const doGetAssertion = function (context: WebAuthnContext, event: AnyEventObject): Promise<Credential | null> {
	context.debug && console.log('doGetAssertion', event)
	return new Promise((resolve, reject) => {
		const asserttionOptions = {
			challenge: stringToByteArray(context.challenge as string),
			timeout: 60000,
			userVerification: 'required'
		} as PublicKeyCredentialRequestOptions

		if (context.credentialId) {
			try {
				context.debug && console.log('credentialId', context.credentialId)
				context.debug && console.log('base64UrlToBase64', base64UrlToBase64(context.credentialId))
				context.debug && console.log('base64ToByteArray', base64ToByteArray(context.credentialId))
			} catch (error) {
				console.error('error', error)
			}

			asserttionOptions.allowCredentials = [
				{
					transports: ['internal', 'usb', 'nfc', 'ble'],
					type: 'public-key',
					id: base64ToByteArray(context.credentialId)
				}
			]
		}

		navigator.credentials
			.get({ publicKey: asserttionOptions })
			.then(credential => {
				let signatureValidation = true
				const credentialAssertionData = getCredentialAssertionData(credential as PublicKeyCredential)

				if (context.publicKey) {
					const publicKey = COSEtoASN1PublicKey(context.publicKey?.['-2'], context.publicKey?.['-3'])
					signatureValidation = verifyECP256Signature(credentialAssertionData.response.signature, credentialAssertionData.signatureBase, publicKey)
				}

				if (signatureValidation) {
					resolve(credential)
				} else {
					console.error('Error en la verificación de la firma')
					reject({
						assertionErrorMessage: 'La verificación de la firma no corresponde con la llave pública registrada'
					})
				}
			})
			.catch(error => {
				console.error('error al solicitar llave de seguridad', error)
				reject({ assertionErrorMessage: 'Error al solicitar la llave de seguridad' })
			})
	})
}

const doRegisterCredential = function (context: WebAuthnContext, event: AnyEventObject): Promise<string | null> {
	context.debug && console.log('on doRegisterCredential', event)
	return new Promise((resolve, reject) => {
		const body: AuthenticationAPIRequestBody['WEBAUTHN_AUTHENTICATOR_ACTIVATE'] = {
			idAuthenticator: context.credential?.id as string,
			signature: context.registrationRSASignature as string
		}

		const request: AuthenticationAPIRequest = {
			accion: 'WEBAUTHN_AUTHENTICATOR_ACTIVATE',
			body: JSON.stringify(body)
		}
		callAuthenticationAPI(request, useUserStore().User.IdentityJWT).then((response: AuthenticationAPIResponse) => {
			if (response.status == 'OK') {
				resolve(response.message)
			} else {
				console.error('Error al autenticar', response.message)
				reject(response.message)
			}
		})
	})
}

const doLogin = function (context: WebAuthnContext, event: AnyEventObject) {
	context.debug && console.log('on doLogin', event)
	return new Promise((resolve, reject) => {
		const body: AuthenticationAPIRequestBody['WEBAUTHN_AUTHENTICATOR_LOGIN'] = {
			assertion: context.credential as Credential
		}

		const request: AuthenticationAPIRequest = {
			accion: 'WEBAUTHN_AUTHENTICATOR_LOGIN',
			body: JSON.stringify(body)
		}
		callAuthenticationAPI(request, useUserStore().User.IdentityJWT)
			.then((response: AuthenticationAPIResponse) => {
				if (response.status == 'OK') {
					resolve({ jwt: response.data.jwt, certificadoB64: response.data.certificadoB64 })
				} else {
					console.error('Error al autenticar', response)
					reject(response.message)
				}
			})
			.catch(error => {
				console.error('Error en llamada al API de autenticación', error)
				reject(error)
			})
	})
}

export default {
	doGetRegistrationMessage,
	doCreateCredential,
	doRegisterCredential,
	doGetAssertion,
	doLogin
}
