/**
 * @name CTDMedicamentosInstrucciones
 * @author @tirsomartinezreyes
 * @descripcion Instrucciones comúnes para el módulo de Medicamentos del CTD
 * @version 0.0.1
 *
 */
export enum ECTDMedicamentosInstruccionesCaracteresEspeciales {
	FOLDER = '{FOLDER}'
}

export enum ECTDMedicamentosInstrucciones {
	LITERATURA_REFERENCIA = `
1.- Por cada referencia proporcionada, genere un archivo pdf que se llame 'reference-N', siguiendo un número consecutivo para cada referencia, iniciando con el 1.


Ejemplo de uso: Para incluir 3 referencias deberá crear los archivos 'reference-1.pdf', 'reference-2.pdf' y 'reference-3.pdf'.

📁 {FOLDER}
└──📕  reference-1.pdf  
└──📕  reference-2.pdf
└──📕  reference-3.pdf 

Referencia: Guía eCTD del ICH versión 3.2.2
 - Apéndice 3: 'Module 3 Quality', 'Module 4 Nonclinical Study Reports', 'Module 5 Clinical Study Reports' 
 - Apéndice 4: 'File Organization for the eCTD' tabla 4.1, numerales 133, 134, 135, 136, 266, 267, 268, 269, 366, 367, 368 y 369
disponible en https://admin.ich.org/sites/default/files/inline-files/eCTD_Specification_v3_2_2_0.pdf
`,
	REPORTES_MODULO_5 = `
1.- Por cada estudio proporcionado genere una carpeta que se llame exactamente 'study-report-N', siguiendo un número consecutivo para cada estudio, iniciando con el 1.

2.- Dentro de cada carpeta, incluya los archivos del estudio correspondientes.

3.- Si lo requiere, puede agregar carpetas para organizar la información del estudio, pero no agregue carpetas vacías.


Ejemplo de uso: Para incluir 3 reportes deberá crear las carpetas 'study-report-1', 'study-report-2', 'study-report-3' y dentro de cada carpeta, los archivos y/o carpetas con la información del estudio.

📁 {FOLDER}
├──📁 study-report-1
|  └──📕  documento-1.pdf  
|  └──📕  documento-2.pdf
|  └──📕  documento-3.pdf 
├──📁 study-report-2
|  └──📕  documento-1.pdf  
|  └──📕  documento-2.pdf
|  └──📕  documento-3.pdf 
└──📁 study-report-3
   ├──📁 data
   |  └──📕  datos.pdf
   ├──📕  documento-1.pdf  
   ├──📕  documento-2.pdf
   └──📕  documento-3.pdf 

Referencia: Guía eCTD del ICH versión 3.2.2, Apéndice 3, 'Module 5 Clinical Study Reports' disponible en https://admin.ich.org/sites/default/files/inline-files/eCTD_Specification_v3_2_2_0.pdf`,

	DOSSIER = `Llene el dossier en la carpeta CTD con la información solicitada, en la estructura de carpetas y archivos correspondiente`
}

/**
 * @name CTDMedicamentosInstruccionesReemplazarCaracteresEspeciales
 * @description Reemplaza los caracteres especiales en un texto
 */
export function CTDMedicamentosInstruccionesReemplazarCaracteresEspeciales(texto: string, caracterEspecial: ECTDMedicamentosInstruccionesCaracteresEspeciales, reemplazo: string): string {
	let respuesta = texto
	respuesta = respuesta.replace(new RegExp(caracterEspecial as unknown as string, 'g'), reemplazo)
	return respuesta
}
