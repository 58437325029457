<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import ERROR_ROUTES_UNAUTHORIZED_ROUTE from 'src/errors/ERROR_ROUTES_UNAUTHORIZED_ROUTE'
import { useRoute } from 'vue-router'
import { useUserStore } from 'src/store/auth'
import { useConfigStore } from 'src/store/config'
import deniedPNG from 'src/assets/img/denied.png'

const route = useRoute()
const data = ref({})

onMounted(() => {
	data.value = {
		route: route.fullPath,
		role: useUserStore().User.activeRole.type,
		uid: useUserStore().User.accessToken.data.uid,
		uuid: useUserStore().User.accessToken.data.uuid
	}
	throw new ERROR_ROUTES_UNAUTHORIZED_ROUTE('Ruta No autorizada', data)
})
</script>

<template>
	<div class="container100p">
		<div class="section section-error vertical-buffer">
			<div class="row">
				<div class="col-sm-7">
					<h1>ACCESO DENEGADO</h1>
					<hr class="red" />
					<div class="alerta">
						No tiene priviegios de acceso a:<br />
						<div class="ff-mono text-center bold mt20">{{ $route.fullPath }}</div>

						<div class="text-center m20">
							<router-link :to="{ path: '/' }">
								<button type="button" class="btn btn-primary active bold">REGRESAR A {{ useConfigStore().getConfig().GENERAL.SYSTEM_NAME }}</button>
							</router-link>
						</div>

						<div class="fs-xs text-right">* Este incidente podría ser reportado al administrador</div>
					</div>
				</div>
				<div class="col-sm-4 col-sm-offset-1">
					<figure>
						<img :src="deniedPNG" alt="Acceso denegado" />
					</figure>
					<h3>Otras opciones:</h3>
					<ul>
						<li>
							<a href="http://www.gob.mx/">Regresar al inicio</a>
						</li>
						<li>
							<a href="http://www.gob.mx/busqueda">Buscar en gob.mx</a>
						</li>
						<li>
							<a href="javascript:history.back();">Volver a la página anterior</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.alerta {
	outline: 2px solid red;
	background-color: rgba(226, 242, 55, 0.35) !important;
	padding: 10px;
}
</style>
