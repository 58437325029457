/**
 * @namespace Utilities
 * @description Utilidades para el manejo de estructuras de datos a bajo nivel.
 * Este componente es crítico para el funcionamiento del proyecto, en particular para el producto WebApp TramitesDigitales
 * Modificaciones en este archivo pueden tener consecuencias en el performance de la aplicación, ya que se utiliza fuertemente en variables computadas y ciclos de renderizado.
 */

export class ExceptionUtilities {
	static getExceptionMessage(e: Error | unknown | any): string {
		if (e && e.message) {
			return e.message
		}
		return e
	}
}

/**
 * @name copyObjectRemovingKeys
 * @description Retorna una copia un objeto, eliminando las llaves especificadas
 */
function copyObjectRemovingKeys(obj: any, keys: string[]) {
	const output: any = { ...obj }
	for (const key of keys) {
		if (output[key] !== undefined) {
			delete output[key]
		}
	}
	return output
}

/*
 * @name copyObjectRemovingKey
 * @description Retorna una copia de un objeto, eliminando la llave especificada
 */
function copyObjectRemovingKey(obj: any, key: string) {
	return copyObjectRemovingKeys(obj, [key])
}

/**
 * @function objectValidate
 * @description Valida la existencia de propiedades en profundidad (como una ruta o un arreglo de sub rutas) en un objeto, regresando un valor por default en caso de no estar definidas.
 * @param {Object} A Objeto a validar, p,e, {'America':{'Mexico':{'CDMX':{'poblacion':'10000000'}}}}
 * @param {String|Array} keys Ruta de la propiedad a validar, p.e 'America.Mexico.CDMX' o ['America','Mexico','CDMX']
 */
function objectValidate(A: any, keys: any, defaultValue: any) {
	if (A) {
		// Compatibilidad con rutas estilo "a/b/c o a.b.c"
		if (typeof keys == 'string') {
			let separator = keys.indexOf('/') >= 0 ? '/' : false
			if (separator === false) {
				separator = keys.indexOf('.') >= 0 ? '.' : false
			}
			if (separator != false) {
				keys = keys.split(separator)
			} else {
				if (keys.length) {
					keys = [keys]
				}
			}
		}

		if (!(keys instanceof Array)) {
			return defaultValue
		}

		for (let i = 0; i < keys.length; i++) {
			A = A[keys[i]]

			if (A === undefined) {
				return defaultValue
			}

			if (!A && i < keys.length - 1) {
				return defaultValue
			}
		}
		return A
	}
	return defaultValue
}

/**
 * @function objectUpdate
 * @description Actualiza un objeto en profundidad (como una ruta o un arreglo de sub rutas) en un objeto, creando los sub, objetos en caso de no estar definidos.
 * @param {Object} A Objeto a actualizar, p,e, {'America':{'Mexico':{'CDMX':{'poblacion':'10000000'}}}}
 * @param {String|Array} keys Ruta de la propiedad a actualizar, p.e 'America.Peru.Lima.poblacion' o ['America','Peru','Lima','poblacion']
 * @param {Any} value Valor a actualizar, p.e '2000000'
 */
function objectUpdate(A: any, keys: any, value: any) {
	if (typeof keys == 'string') {
		let separator = keys.indexOf('/') >= 0 ? '/' : false
		if (!separator) {
			separator = keys.indexOf('.') >= 0 ? '.' : false
		}
		if (separator != false) {
			keys = keys.split(separator)
		}
	}
	const num = keys.length
	for (let i = 0; i < num - 1; i++) {
		if (A[keys[i]] == undefined) {
			A[keys[i]] = {}
		}
		A = A[keys[i]]
	}
	A[keys[num - 1]] = value
}

/**
 * @function objectToPathValue
 * @public
 * @description Obtiene todo los posibles paths de los elementos de un objeto y sus valores, convirtiendo la notación de punto.
 * @param {Object} obj Objeto de N niveles
 * @param {Array} [beforeKey]
 * @param {Array} [path]
 * @return {Array} Array de N Arrays (uno por cada posible path. Cada objeto tiene value como valor y path como un array de los elementos desde el raiz hasta el hijo)
 * @example
 * // objectToPathValue({a:{b:{b1:true},c:{c1:true}}});
 * // [['a','b','b1'],['c','c1']]
 */

function objectToPathValue(obj: any, beforeKey?: any, path?: any): string[] {
	//beforeKey y path son utilizados para recursión
	if (!beforeKey) {
		beforeKey = []
	}
	if (!path) {
		path = []
	}
	for (const i in obj) {
		if (typeof obj[i] == 'object') {
			path = objectToPathValue(obj[i], beforeKey.concat([i]), path)
		} else {
			path.push({
				path: beforeKey.concat([i]),
				value: obj[i]
			})
		}
	}
	return path
}

/**
 *
 * @name randomValueFromArray
 * @description Regresa un valor aleatorio de un arreglo
 * @param {Any[]} arr Arreglo de valores
 * @returns {Any} Valor aleatorio del arreglo
 */
function randomValueFromArray(arr: any[]): any {
	const min = 0
	const max = arr.length - 1

	if (max < 1) {
		return false
	}
	return arr[randomIntegerValueBetweenTwoValues(min, max)]
}

/**
 * @name randomIntegerValueBetweenTwoValues
 * @description Regresa un valor entero aleatorio entre dos valores, regresando 0 en caso de que los parámetros de entrada no sean números
 * @param {number} min
 * @param {number} max
 * @returns
 */
function randomIntegerValueBetweenTwoValues(min: number, max: number) {
	if (typeof min != 'number' || typeof max != 'number') {
		return 0
	}
	return Math.round(Math.random() * (max - min) + min)
}

/**
 *
 * @name orderObjects
 * @description Ordena un arreglo de objetos por una propiedad específica de manera ascendente o descendente
 * @param {Any[]} array
 * @param {string} property
 * @param {boolean} asc
 * @returns
 */
function orderObjects(array: any[], property: string, asc: boolean = true) {
	let output: any[] = []

	output = array.sort(function (a, b) {
		const _a = a[property] ?? ''
		const _b = b[property] ?? ''

		if (_a < _b) {
			return asc ? -1 : 1
		}

		if (_a > _b) {
			return asc ? 1 : -1
		}
		return 0
	})

	return output
}

/**
 * @name orderObjectsArrayByPropertyAsc
 * @description Ordena un arreglo de objetos por una propiedad específica de manera ascendente
 * @param {Any[]} array
 * @param {string} property
 * @returns {Any[]}
 */
function orderObjectsArrayByPropertyAsc(array: any[], property: string) {
	return orderObjects(array, property, true)
}

/*
 * @name orderObjectsArrayByPropertyDesc
 * @description Ordena un arreglo de objetos por una propiedad específica de manera descendente
 * @param {Any[]} array
 * @param {string} property
 * @returns {Any[]}
 */
function orderObjectsArrayByPropertyDesc(array: any[], property: string) {
	return orderObjects(array, property, false)
}

/**
 * @function getArrayWithOnlyNItem
 * @description Regresa un arreglo con un solo el n-ésimo elemento en caso de que el elemento exista en el arreglo original, en caso contrario regresa el valor de entrada íntegro
 * @param {Any[]} array
 * @param {number} n
 * @returns {Any | Any[]}
 */
function getArrayWithOnlyNItem(array: any[], n: number) {
	const output: any[] = []
	if (array[n] !== undefined) {
		output.push(array[n])
	}
	return output
}

/*
 * @function getNItemsFromArray
 * @description Regresa un arreglo con los primeros n elementos de un arreglo
 * @param {Any[]} array
 * @param {number} n
 * @returns {Any[]}
 */
function getNItemsFromArray(array: any[], n: number) {
	const output: any[] = []
	const num = array.length
	if (n > num) {
		n = num
	}
	for (let i = 0; i < n; i++) {
		output.push(array[i])
	}
	return output
}

/**
 * @function fileSize
 * @description Convierte el valor de entrada a Human Radable
 * @param {Number} bytes -  Valor en bytes
 * @param {Number} decimals -  Decimales a utilizar
 * @return {Array|String} Regresa un arreglo con o sin elementos
 */
function fileSize(bytes: number, decimals: number = 0): string {
	if (bytes === 0) return '0 Bytes'
	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	const i = Math.floor(Math.log(bytes) / Math.log(k))
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toString() + ' ' + sizes[i]
}

export {
	copyObjectRemovingKeys,
	copyObjectRemovingKey,
	objectValidate,
	objectUpdate,
	objectToPathValue,
	randomValueFromArray,
	randomIntegerValueBetweenTwoValues,
	orderObjectsArrayByPropertyAsc,
	orderObjectsArrayByPropertyDesc,
	getNItemsFromArray,
	getArrayWithOnlyNItem,
	fileSize
}
