import { CatalogGenericData } from './Catalogs'

export enum ERolType {
	SUPERADMINISTRADOR = 'SUPERADMINISTRADOR',
	ADMINISTRADOR = 'ADMINISTRADOR',
	AUTORIDAD = 'AUTORIDAD',
	COORDINADOR = 'COORDINADOR',
	DICTAMINADOR = 'DICTAMINADOR',
	SOLICITANTE_TITULAR_PERSONA_MORAL = 'SOLICITANTE_TITULAR_PERSONA_MORAL',
	SOLICITANTE_TITULAR_PERSONA_FISICA = 'SOLICITANTE_TITULAR_PERSONA_FISICA',
	SOLICITANTE_AUTORIZADOR = 'SOLICITANTE_AUTORIZADOR',
	SOLICITANTE_EDITOR = 'SOLICITANTE_EDITOR',
	SOLICITANTE_VISOR = 'SOLICITANTE_VISOR',
	ANONIMO = 'ANONIMO',
	SYSTEM = 'SYSTEM'
}

export type RolType = {
	[K in keyof ERolType]: {
		[K2 in keyof ERolType[K]]: ERolType[K][K2]
	}[keyof ERolType[K]]
}[keyof ERolType]

export const RolesTypeArray = Object.freeze([
	'SUPERADMINISTRADOR',
	'ADMINISTRADOR',
	'AUTORIDAD',
	'COORDINADOR',
	'DICTAMINADOR',
	'SOLICITANTE_TITULAR_PERSONA_MORAL',
	'SOLICITANTE_TITULAR_PERSONA_FISICA',
	'SOLICITANTE_AUTORIZADOR',
	'SOLICITANTE_EDITOR',
	'SOLICITANTE_VISOR',
	'ANONIMO',
	'SYSTEM'
]) as RolType[]

export interface Rol {
	type: RolType
	label: string
	code: string
}

export interface Grupo {
	grupo: string
	roles: Rol[]
	solicitante: string
}

export const ROL_SUPERADMINISTRADOR: Rol = {
	type: 'SUPERADMINISTRADOR',
	label: 'Superadministrador',
	code: 'SA'
}
export const ROL_ADMINISTRADOR: Rol = {
	type: 'ADMINISTRADOR',
	label: 'Administrador',
	code: 'AD'
}

export const ROL_AUTORIDAD: Rol = {
	type: 'AUTORIDAD',
	label: 'Autoridad',
	code: 'AU'
}

export const ROL_COORDINADOR: Rol = {
	type: 'COORDINADOR',
	label: 'Coordinador',
	code: 'C'
}
export const ROL_DICTAMINADOR: Rol = {
	type: 'DICTAMINADOR',
	label: 'Dictaminador',
	code: 'D'
}
export const ROL_SOLICITANTE_TITULAR_PERSONA_MORAL: Rol = {
	type: 'SOLICITANTE_TITULAR_PERSONA_MORAL',
	label: 'Solicitante Titular Persona Moral',
	code: 'SM'
}
export const ROL_SOLICITANTE_TITULAR_PERSONA_FISICA: Rol = {
	type: 'SOLICITANTE_TITULAR_PERSONA_FISICA',
	label: 'Solicitante Titular Persona Física',
	code: 'SF'
}
export const ROL_SOLICITANTE_AUTORIZADOR: Rol = {
	type: 'SOLICITANTE_AUTORIZADOR',
	label: 'Autorizador',
	code: 'A'
}
export const ROL_SOLICITANTE_EDITOR: Rol = {
	type: 'SOLICITANTE_EDITOR',
	label: 'Editor',
	code: 'E'
}
export const ROL_SOLICITANTE_VISOR: Rol = {
	type: 'SOLICITANTE_VISOR',
	label: 'Visor',
	code: 'V'
}
export const ROL_ANONIMO: Rol = {
	type: 'ANONIMO',
	label: 'Anónimo',
	code: 'X'
}

export interface IRoles {
	[key: string]: Rol
}

export const Roles: IRoles = {
	ROL_SUPERADMINISTRADOR,
	ROL_ADMINISTRADOR,
	ROL_AUTORIDAD,
	ROL_COORDINADOR,
	ROL_DICTAMINADOR,
	ROL_SOLICITANTE_TITULAR_PERSONA_MORAL,
	ROL_SOLICITANTE_TITULAR_PERSONA_FISICA,
	ROL_SOLICITANTE_AUTORIZADOR,
	ROL_SOLICITANTE_EDITOR,
	ROL_SOLICITANTE_VISOR,
	ROL_ANONIMO
}

interface IRolDescripcion {
	[key: string]: string
}

export const RolDescription: IRolDescripcion = {
	SUPERADMINISTRADOR:
		'Generar permisos operativos de acceso a usuarios de la organización. Revocar permisos operativos de acceso a usuarios de la organización. Habilitar o deshabilitar el acceso a usuarios del sistema. Restablecer el registro de llaves de seguridad de usuarios de la organización. Actualizar Unidades Organizativas a las que pertenece un usuario de la organización.',
	ADMINISTRADOR:
		'Habilitar o deshabilitar el acceso a usuarios del sistema. Restablecer el registro de llaves de seguridad de usuarios de la organización. Actualizar Unidades Organizativas a las que pertenece un usuario de la organización.',
	AUTORIDAD: 'Firmar electrónicamente la evaluación verificada de un trámite y emitir su resolución electrónica',
	COORDINADOR:
		'Administrar accesos  y roles de actores a usuarios de la organización a la atención de homoclave y modalidad definida (Recurso).  Solicitar firma de oficios de resolución a Autoridad. Revocar autorización de trámites (previa instrucción de autoridad)',
	DICTAMINADOR: 'Evaluación de trámites asignados por un coordinador. Definición del sentido de la resolución del trámite.',
	SOLICITANTE_TITULAR_PERSONA_MORAL: 'Administrar permisos de acceso para editores y visores en su grupo solicitante.',
	SOLICITANTE_TITULAR_PERSONA_FISICA:
		'Administrar permisos de acceso para editores y visores en su grupo solicitante. Generar , editar y eliminar  borradores de solicitudes de trámites. Firmar solicitudes de trámites para someterlas a evaluación. Editar respuestas a prevención. Firmar respuestas a prevención para someterlas a evaluación. Desistir trámites en evaluación. Consultar la información del solicitante , borradores de solicitudes y trámites realizados. Editar información de la organización.',
	SOLICITANTE_AUTORIZADOR:
		'Actúar en representación de la persona moral de la cual tiene acceso. Generar , editar y eliminar  borradores de solicitudes de trámites. Firmar solicitudes de trámites para someterlas a evaluación. Editar respuestas a prevención. Firmar respuestas a prevención para someterlas a evaluación. Desistir trámites en evaluación. Consultar la información del solicitante , borradores de solicitudes y trámites realizados. Editar información de la organización.',
	SOLICITANTE_EDITOR:
		'Generar , editar y eliminar borradores de solicitudes de trámites. Editar respuestas a prevención.  Desistir trámites en evaluación. Consultar la información del solicitante , borradores de solicitudes y trámites realizados. Editar información de la organización.',
	SOLICITANTE_VISOR: 'Consultar la información del solicitante , borradores de solicitudes y trámites realizados.',
	ANONIMO: 'Usuario sin privilegios de acceso en el sistema'
}

export interface Funcion {
	funcion?: string
	etiqueta: string
	descripcion: string
	roles: Rol[]
}

interface IFunciones<Funcion> {
	[key: string]: Funcion
}

export const Funciones: IFunciones<Funcion> = {
	SOLICITANTE_LEER_INFORMACION: {
		etiqueta: 'Acceso a la información del solicitante',
		descripcion: 'Acceso a los trámites e información del grupo solicitante en el sistema',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_TITULAR_PERSONA_MORAL, ROL_SOLICITANTE_AUTORIZADOR, ROL_SOLICITANTE_EDITOR, ROL_SOLICITANTE_VISOR]
	},

	SOLICITANTE_EDITAR_INFORMACION: {
		etiqueta: 'Editar la información del grupo solicitante',
		descripcion: 'Editar solicitudes e información de grupo solicitante en el sistema',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_AUTORIZADOR, ROL_SOLICITANTE_EDITOR]
	},

	SOLICITANTE_CREAR_PERMISO: {
		etiqueta: 'Agregar permisos de acceso a personas en el grupo solicitante',
		descripcion: 'Agregar permisos de acceso a personas en el grupo solicitante',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_TITULAR_PERSONA_MORAL]
	},

	SOLICITANTE_REVOCAR_PERMISO: {
		etiqueta: 'Revocar permisos otorgados a personas en el grupo solicitante',
		descripcion: 'Revocar permisos otorgados a personas en el grupo solicitante',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_TITULAR_PERSONA_MORAL]
	},

	SOLICITANTE_CREAR_SOLICITUD: {
		etiqueta: 'Crear solicitudes',
		descripcion: 'Crear nuevas solicitudes',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_AUTORIZADOR, ROL_SOLICITANTE_EDITOR]
	},

	SOLICITANTE_EDITAR_SOLICITUD: {
		etiqueta: 'Editar solicitudes',
		descripcion: 'Editar borradores de solicitudes',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_AUTORIZADOR, ROL_SOLICITANTE_EDITOR]
	},

	SOLICITANTE_BORRAR_SOLICITUD: {
		etiqueta: 'Borrar solicitudes',
		descripcion: 'Eliminar borradores de solicitudes',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_AUTORIZADOR, ROL_SOLICITANTE_EDITOR]
	},

	SOLICITANTE_FIRMAR_SOLICITUD: {
		etiqueta: 'Firmar solicitudes',
		descripcion: 'Firmar y enviar solicitudes',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_AUTORIZADOR]
	},

	SOLICITANTE_DESISTIR_TRAMITE: {
		etiqueta: 'Desistir trámites',
		descripcion: 'Desistir de un trámite que se encuentra en proceso de evaluación',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_AUTORIZADOR]
	},

	SOLICITANTE_EDITAR_RESPUESTA_PREVENCION: {
		etiqueta: 'Editar Respuesta a prevención',
		descripcion: 'Editar la información que será enviada como respuesta a una prevención',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_AUTORIZADOR, ROL_SOLICITANTE_EDITOR]
	},

	SOLICITANTE_FIRMAR_RESPUESTA_PREVENCION: {
		etiqueta: 'Firmar respuesta a prevención',
		descripcion: 'Firmar y enviar la respuesta a una prevención',
		roles: <Rol[]>[ROL_SOLICITANTE_TITULAR_PERSONA_FISICA, ROL_SOLICITANTE_AUTORIZADOR]
	},

	ORGANIZACION_LEER_INFORMACION: {
		etiqueta: 'Leer información de la organización',
		descripcion: 'Acceso de lectura a información de la organización',
		roles: <Rol[]>[ROL_SUPERADMINISTRADOR, ROL_ADMINISTRADOR, ROL_AUTORIDAD, ROL_COORDINADOR, ROL_DICTAMINADOR]
	},

	ORGANIZACION_AGREGAR_USUARIO: {
		etiqueta: 'Agregar usuarios',
		descripcion: 'Agregar usuarios al grupo de la organización',
		roles: <Rol[]>[ROL_SUPERADMINISTRADOR]
	},

	ORGANIZACION_RETIRAR_USUARIO: {
		etiqueta: 'Retirar usuarios asignados como miembros de la organización',
		descripcion: '',
		roles: <Rol[]>[ROL_SUPERADMINISTRADOR]
	},

	ORGANIZACION_EDITAR_UNIDADES_ORGANIZATIVAS_USUARIO: {
		etiqueta: 'Editar unidades organizativas a las que se encuentra adscrito un usuario',
		descripcion: '',
		roles: <Rol[]>[ROL_SUPERADMINISTRADOR]
	},

	ORGANIZACION_REQUERIR_AUTENTICADOR_USUARIO: {
		etiqueta: 'Habilitar o deshabiitar la autenticación con llave de seguridad para un usuario',
		descripcion: '',
		roles: <Rol[]>[ROL_SUPERADMINISTRADOR, ROL_ADMINISTRADOR]
	},

	ORGANIZACION_RESTABLECER_AUTENTICADOR_USUARIO: {
		etiqueta: 'Permitir el restablecimiento de la llave de seguridad de un usuario',
		descripcion: '',
		roles: <Rol[]>[ROL_SUPERADMINISTRADOR, ROL_ADMINISTRADOR]
	},

	ORGANIZACION_EDITAR_ACCESO_USUARIO: {
		etiqueta: 'Habilitar o deshabilitar el acceso de un usuario de la organización al sistema',
		descripcion: '',
		roles: <Rol[]>[ROL_SUPERADMINISTRADOR, ROL_ADMINISTRADOR]
	},

	ORGANIZACION_AGREGAR_PERMISO_ACTOR_TRAMITE: {
		etiqueta: 'Agregar permisos de acceso a usuarios de la unidad organizativa como actores específicos en la atención de un trámite',
		descripcion: '',
		roles: <Rol[]>[ROL_COORDINADOR]
	},

	ORGANIZACION_REVOCAR_PERMISO_ACTOR_TRAMITE: {
		etiqueta: 'Revocar permisos de acceso a usuarios de la unidad organizativa como actores específicos en la atención de un trámite',
		descripcion: '',
		roles: <Rol[]>[ROL_COORDINADOR]
	},

	ORGANIZACION_CANCELAR_EVALUACION_TRAMITE: {
		etiqueta: 'Cancelar evaluación de un trámite',
		descripcion: 'Cancelar la evaluación de un trámite del cuál se ha requerido verificación',
		roles: <Rol[]>[ROL_COORDINADOR]
	},

	ORGANIZACION_CANCELAR_VERIFICACION_TRAMITE: {
		etiqueta: 'Cancelar verificación de un trámite',
		descripcion: 'Cancelar la verificación de un trámite del cuál se ha requerido firma de resolución',
		roles: <Rol[]>[ROL_AUTORIDAD, ROL_COORDINADOR]
	},

	ORGANIZACION_SOLICITAR_FIRMA_RESOLUCION_TRAMITE: {
		etiqueta: 'Solicitar a la autoridad la firma de resolución de un trámite',
		descripcion: '',
		roles: <Rol[]>[ROL_COORDINADOR]
	},

	ORGANIZACION_REVOCAR_AUTORIZACION_TRAMITE: {
		etiqueta: 'Revocar una autorización otorgada a un trámite previa instrucción de la autoridad',
		descripcion: '',
		roles: <Rol[]>[ROL_COORDINADOR]
	},

	ORGANIZACION_EVALUAR_TRAMITE: {
		etiqueta: 'Evaluar y rubricar con llave de seguridad la atención de un trámite',
		descripcion: '',
		roles: <Rol[]>[ROL_DICTAMINADOR]
	},

	ORGANIZACION_VERIFICAR_TRAMITE: {
		etiqueta: 'Verificar que la dictaminación del trámite sea correcta y solicitar firma de resolución',
		descripcion: '',
		roles: <Rol[]>[ROL_COORDINADOR]
	},

	ORGANIZACION_FIRMAR_RESOLUCION_TRAMITE: {
		etiqueta: 'Firmar electrónicamente la evaluación verificada de un trámite y emitir su resolución electrónica',
		descripcion: '',
		roles: <Rol[]>[ROL_AUTORIDAD]
	}
}

export function getFunctionsByRolType(rolType: RolType): Funcion[] {
	const rol = getRoleByRolType(rolType)
	const response: Funcion[] = []
	Object.keys(Funciones).forEach(key => {
		const funcion: Funcion = Funciones[key]
		if (funcion.roles.includes(rol)) {
			const f = Object.assign({}, funcion)
			f.funcion = key
			response.push(f)
		}
	})
	return response
}

export function getRolesByFunction(funcion: keyof typeof Funciones): Rol[] {
	return Funciones[funcion].roles
}

export function isFuncionInRole(rol: Rol, funcion: Funcion): boolean {
	return funcion.roles.includes(rol)
}

export function getRoleByRolType(rolType: RolType): Rol {
	if (rolType === 'SUPERADMINISTRADOR') return ROL_SUPERADMINISTRADOR
	if (rolType === 'ADMINISTRADOR') return ROL_ADMINISTRADOR
	if (rolType === 'AUTORIDAD') return ROL_AUTORIDAD
	if (rolType === 'COORDINADOR') return ROL_COORDINADOR
	if (rolType === 'DICTAMINADOR') return ROL_DICTAMINADOR
	if (rolType === 'SOLICITANTE_TITULAR_PERSONA_MORAL') return ROL_SOLICITANTE_TITULAR_PERSONA_MORAL
	if (rolType === 'SOLICITANTE_TITULAR_PERSONA_FISICA') return ROL_SOLICITANTE_TITULAR_PERSONA_FISICA
	if (rolType === 'SOLICITANTE_AUTORIZADOR') return ROL_SOLICITANTE_AUTORIZADOR
	if (rolType === 'SOLICITANTE_EDITOR') return ROL_SOLICITANTE_EDITOR
	if (rolType === 'SOLICITANTE_VISOR') return ROL_SOLICITANTE_VISOR
	if (rolType === 'ANONIMO') return ROL_ANONIMO
	return ROL_ANONIMO
}

export function getDescriptionByRolType(rolType: ERolType): string {
	let response = ''
	if (RolDescription[rolType] != undefined) {
		response = RolDescription[rolType]
	}
	return response
}

export function getDiccionarioRolesOrganizacion(): CatalogGenericData[] {
	const diccionario: CatalogGenericData[] = []
	diccionario.push({
		key: 'SUPERADMINISTRADOR',
		value: ROL_SUPERADMINISTRADOR as any
	})
	diccionario.push({ key: 'ADMINISTRADOR', value: ROL_ADMINISTRADOR as any })
	diccionario.push({ key: 'AUTORIDAD', value: ROL_AUTORIDAD as any })
	diccionario.push({ key: 'COORDINADOR', value: ROL_COORDINADOR as any })
	diccionario.push({ key: 'DICTAMINADOR', value: ROL_DICTAMINADOR as any })
	return diccionario
}

export function getDiccionarioRolesSolicitante(): CatalogGenericData[] {
	const diccionario: CatalogGenericData[] = []
	diccionario.push({
		key: 'SOLICITANTE_TITULAR_PERSONA_MORAL',
		value: ROL_SOLICITANTE_TITULAR_PERSONA_MORAL as any
	})
	diccionario.push({
		key: 'SOLICITANTE_TITULAR_PERSONA_FISICA',
		value: ROL_SOLICITANTE_TITULAR_PERSONA_FISICA as any
	})
	diccionario.push({
		key: 'SOLICITANTE_AUTORIZADOR',
		value: ROL_SOLICITANTE_AUTORIZADOR as any
	})
	diccionario.push({
		key: 'SOLICITANTE_EDITOR',
		value: ROL_SOLICITANTE_EDITOR as any
	})
	diccionario.push({
		key: 'SOLICITANTE_VISOR',
		value: ROL_SOLICITANTE_VISOR as any
	})
	return diccionario
}
