import { AnyEventObject, RSASignContext } from '../bom'

const isLoginMode = function (context: RSASignContext, event: AnyEventObject): boolean {
    return context.mode == 'LOGIN' ? true : false
}

const isSignMode = function (context: RSASignContext, event: AnyEventObject): boolean {
    return context.mode == 'SIGN' && context.message && context.certificadoB64 ? true : false
}

const isSignatureB64 = function (context: RSASignContext, event: AnyEventObject): boolean {
    return context.signatureB64 ? true : false
}

const isValidSignature = function (context: RSASignContext, event: AnyEventObject): boolean {
    return context.validSignature ? true : false
}

const isCertificadoB64 = function (context: RSASignContext, event: AnyEventObject): boolean {
    return context.certificadoB64 ? true : false
}

const isJwt = function (context: RSASignContext, event: AnyEventObject): boolean {
    return context.jwt ? true : false
}

export default {
    isSignatureB64,
    isLoginMode,
    isSignMode,
    isValidSignature,
    isCertificadoB64,
    isJwt
}
