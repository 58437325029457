import { IComponent } from '../forms/IComponent'
import PopoverOptions from '../componentOptions/PopoverOptions'
import { ComponentViews } from '../components/ComponentViews'
import IOption from '../models/IOption'
import { ValidationRuleAny } from '../validations/interfaces/IValidationRule'

export enum NIVEL_ACCESO {
	NO_CLASIFICADO = 'NO_CLASIFICADO', //alertar al usuario sobre este caso y no presentar ninguna información
	PUBLICO = 'PUBLICO',
	INTERNO = 'INTERNO',
	RESTRINGIDO = 'RESTRINGIDO',
	CONFIDENCIAL = 'CONFIDENCIAL' //este caso no deberia presentarse nunca!
}

export enum CUSTOM_TYPE {
	STRING = 'STRING',
	NUMBER = 'NUMBER',
	FILE = 'FILE',
	BOOLEAN = 'BOOLEAN',
	DATE = 'DATE',
	ENUM = 'ENUM',
	ARRAY = 'ARRAY',
	TABLE = 'TABLE'
}

export interface ICustomTypeValueString {
	valueString?: string
	type: CUSTOM_TYPE
	typeName: string
}

export interface ICustomTypeValueNumber {
	valueNumber?: number
	type: CUSTOM_TYPE
	typeName: string
}

export interface ICustomTypeValueDate {
	valueDate?: Date
	type: CUSTOM_TYPE
	typeName: string
}
export interface ICustomTypeValueBoolean {
	valueBoolean?: boolean
	type: CUSTOM_TYPE
	typeName: string
}
export enum FileUploadStatus {
	PREPROCESING = 'PREPROCESING',
	UPLOADED = 'UPLOADED',
	UPLOADING = 'UPLOADING',
	ERROR = 'ERROR',
	PENDING = 'PENDING'
}
export interface IFileValue {
	fileName: string
	s3Key: string
	sha256: string
	eTag: string
	size: number
	fileType: string
	status?: FileUploadStatus
}

export interface ICustomTypeValueFile {
	valueFile?: IFileValue[]
	type: CUSTOM_TYPE
	typeName: string
}

export interface IEnumValue {
	selected?: string
	enumName: string
}

export interface ICustomTypeValueEnum {
	valueEnum?: IEnumValue[]
	type: CUSTOM_TYPE
	typeName: string
}

export interface ICustomTypeValueTable {
	valueTable?: ICustomTypeUnion[][] //celdas cualquier ComponentViews
	type: CUSTOM_TYPE
	typeName: string
}

export type ICustomTypeUnion = ICustomTypeValueFile | ICustomTypeValueString | ICustomTypeValueNumber | ICustomTypeValueDate | ICustomTypeValueBoolean | ICustomTypeValueEnum | ICustomTypeValueTable
export type ICustomValueUnion = IFileValue[] | string | number | Date | boolean | undefined | ICustomValueUnion[][]
export type ICustomTypeKeyUnion = keyof ICustomTypeUnion &
	keyof ICustomTypeValueString &
	keyof ICustomTypeValueNumber &
	keyof ICustomTypeValueBoolean &
	keyof ICustomTypeValueDate &
	keyof ICustomTypeValueFile &
	keyof ICustomTypeValueEnum &
	keyof ICustomTypeValueTable
export interface ICustomTypeAny {
	_id?: string | undefined
	value?: ICustomTypeUnion
	valuePath?: ICustomTypeKeyUnion
	description?: string | undefined
	get type(): CUSTOM_TYPE
	rules?: ValidationRuleAny[] | undefined
	options?: IOption[] | undefined
	component?: (() => IComponent | undefined) | undefined
	defaultComponent?: ComponentViews
	defaultPopOver?: PopoverOptions
}
