import { ref, computed } from 'vue'
export const containerClasses: string[] = ['container100p', 'containerHD', 'containerWXGA', 'containerWXSGA', 'containerFHD', 'containerQHD']
export const containerClassDefault = computed(() => sessionStorage.getItem('containerClass') ?? 'containerHD')
export const containerClassFullWidth: string = 'container100p'
export const previousContainerClass = ref<string>(containerClassDefault.value)
export const containerClass = ref<string>(containerClassDefault.value)
export const containerLabel = computed(() => getLabelByClass(containerClass.value))

export const toggleSize = function () {
	const index = containerClasses.indexOf(containerClass.value)
	if (index === containerClasses.length - 1) {
		setSizeByNumber(0)
	} else {
		setSizeByNumber(index + 1)
	}
}

export const setSizeByClass = function (className: string) {
	const index = containerClasses.indexOf(className)
	setSizeByNumber(index)
}

export const setSizeByNumber = function (size: number) {
	if (size >= 0 && size < containerClasses.length) {
		previousContainerClass.value = containerClass.value
		containerClass.value = containerClasses[size]
		sessionStorage.setItem('containerClass', containerClass.value)
	}
}

export const setFullWidth = function () {
	setSizeByClass(containerClassFullWidth)
}

export const restorePreviousSize = function () {
	setSizeByClass(previousContainerClass.value)
}

export const getLabelByClass = function (label: string) {
	switch (label) {
		case 'container100p':
			return '100%'
		case 'containerHD':
			return 'HD'
		case 'containerWXGA':
			return 'WXGA'
		case 'containerWXSGA':
			return 'WXSGA'
		case 'containerFHD':
			return 'Full HD'
		case 'containerQHD':
			return 'QHD'
		default:
			return ''
	}
}
