import { x509Certificate } from 'e.firma'

//Funciones
const loadX509CertificateFromBase64String = function (b64File: string) {
	const binaryFile = atob(b64File)
	const certificate = new x509Certificate(binaryFile)
	return certificate
}

export { loadX509CertificateFromBase64String }
