/**
 * @name CTDNodo.class
 * @autor @tirsomartinezreyes
 * @description Clase para gestionar un nodo del árbol CTD de una manera más eficiente y de manera programática
 * manteniendo la estructura de un árbol jerárquico con dobles enlaces: referencia a nodo padre e hijos
 * @version 0.0.9
 * @changelog 0.0.9 - 03/sep/24 - Se agrega método exportarArchivosFrontEnd y eliminarArchivos
 *
 * Responsabilidad:
 * 0. Implementar la interfaz ICTDNodo
 * 1. Crear un nodo del árbol CTD que implemente ICTDNodo
 * 2. Agregar hijos a un nodo (al inicio, al final o en una posición específica)
 * 3. Agregar hermanos a un nodo (al inicio, al final, a la izquierda o a la derecha)
 * 4. Borrar un nodo por su id
 * 5. Implementar un id interno numérico automático interno y autorganizable
 * 6. Buscar un nodo por su id
 * 7. Buscar nodos por tipo
 * 8. Buscar nodos por cadena en una llave específica
 * 9. Aplicar las transformaciones necesarias para folders múltiples
 * 10. Exportar un nodo como ICTDNodo (JSON estático)
 * 11. Imprimir el nodo actual como árbol en consola (Emojis y vista de jerarquía tipo explorador de archivos)
 * 12. Loggear el nodo actual como árbol en consola (Texto plano, menor nivel de detalle)
 * 13. Loggear cualquier nodo en consola
 *
 */
import {
	ICTDNodo, // Requerido para implementar la interfaz
	ECTDTipoNodo, //Requerido para implementar interfaz ICTDNodo y definicion de parámetros de búsqueda
	ECTDFormatoDocumento, //Requerido para implementar interfaz ICTDNodo
	ECTDCaracteresIdentificadoresReservados, //Requerido para obtener el caracter de un nodo múltiple
	ICTDParametrosProcesadorFoldersMultiples, //Requerido para procesar identificadores reservados
	obtenerFormatoDocumentoPorExtension
} from './'
import { IFileType, IFileTypeFrontEnd } from 'cofepris-typesafe/Types/FilesType'

/**
 * @name ICTDNodoParams
 * @description Parámetros para la función constructora de la clase CTDNodo (pácticamente ICTDNodo con todas las llaves opcionales)
 */
export interface ICTDNodoParams {
	tipo?: ECTDTipoNodo
	nombre?: string
	id?: string
	descripcion?: string
	instrucciones?: string
	documentoFormato?: ECTDFormatoDocumento[]
	hijos?: ICTDNodo[]
	archivos?: IFileType[]
}

/**
 * @name ECTDNodoPosicionNodo
 * @description Ubicación de nodos al agregar hijos y hermanos en el árbol
 */
export enum ECTDNodoPosicionNodo {
	INICIO = 'INICIO',
	FINAL = 'FINAL',
	IZQUIERDA = 'IZQUIERDA',
	DERECHA = 'DERECHA'
}

/**
 * @name ECTDNodoPosicionBusquedaCadena
 * @description Parámetros para la búsqueda de nodos por cadena en una llave específica
 */
export enum ECTDNodoPosicionBusquedaCadena {
	INICIO = 'INICIO', //Solo al inicio
	FINAL = 'FINAL', //Solo al final
	MEDIO = 'MEDIO', //En cualquier parte, pero no al inicio ni al final
	INCLUYE = 'INCLUYE', //En cualquier parte, incluyendo inicio y final e igual
	IGUAL = 'IGUAL' //Igual a la cadena
}

/**
 * @name ECTDNodoLlaveBusquedaCadena
 * @description Llaves de búsqueda para la búsqueda de nodos por cadena en una llave específica
 */
export enum ECTDNodoLlaveBusquedaCadena {
	ID = '_id',
	ID_INTERNO = '_idInterno',
	NOMBRE = '_nombre',
	DESCRIPCION = '_descripcion',
	INSTRUCCIONES = '_instrucciones'
}

/**
 * @name ECTDNodoVistaArbolConsola
 * @description Llaves de visualización de os nodos de un árbol CTD en consola
 */
export enum ECTDNodoVistaArbolConsola {
	ID = 'id',
	NOMBRE = 'nombre',
	DESCRIPCION = 'descripcion',
	ID_NOMBRE = 'id-nombre'
}

/**
 * @name CTDNodo
 * @description Clase CTDNodo que implementa la interfaz ICTDNodo para gestionar un nodo del árbol CTD
 */
export class CTDNodo implements ICTDNodo {
	private _tipo: ECTDTipoNodo
	private _id: string
	private _nombre: string
	private _descripcion: string
	private _instrucciones: string
	private _documentoFormato: ECTDFormatoDocumento[]
	private _hijos: CTDNodo[]
	private _archivos: IFileType[]
	private _padre: CTDNodo | null
	private _idInterno: string | null

	constructor(args: ICTDNodoParams) {
		this._idInterno = args.tipo == ECTDTipoNodo.RAIZ ? null : '1'
		this._tipo = args.tipo ?? ECTDTipoNodo.DOCUMENTO
		this._id = args.id ?? ''
		this._nombre = args.nombre ?? ''
		this._descripcion = args.descripcion ?? ''
		this._instrucciones = args.instrucciones ?? ''
		this._documentoFormato = args.documentoFormato ?? []
		this._archivos = args.archivos ?? []
		this._padre = null
		this._hijos = []
		if (args.hijos) {
			args.hijos.forEach(hijo => {
				this.agregarHijo(hijo)
			})
		}
	}

	// Getters and setters
	get tipo(): ECTDTipoNodo {
		return this._tipo
	}

	set tipo(value: ECTDTipoNodo) {
		this._tipo = value
	}

	get id(): string {
		return this._id
	}

	set id(value: string) {
		this._id = value
	}

	get nombre(): string {
		return this._nombre
	}

	set nombre(value: string) {
		this._nombre = value
	}

	get descripcion(): string {
		return this._descripcion
	}

	set descripcion(value: string) {
		this._descripcion = value
	}

	get instrucciones(): string {
		return this._instrucciones
	}

	set instrucciones(value: string) {
		this._instrucciones = value
	}

	get documentoFormato(): ECTDFormatoDocumento[] {
		return this._documentoFormato
	}

	set documentoFormato(value: ECTDFormatoDocumento[]) {
		this._documentoFormato = value
	}

	get archivos(): IFileType[] {
		return this._archivos
	}

	set archivos(value: IFileType[]) {
		this._archivos = value
	}

	get hijos(): CTDNodo[] {
		return this._hijos
	}

	get padre(): CTDNodo | null {
		return this._padre
	}

	//Métodos de modificación del árbol

	/**
	 * @name agregarHijo
	 * @description Método para agregar un hijo a un nodo al inicio, al final o en uan posición específica
	 */
	agregarHijo(params: CTDNodo | ICTDNodoParams, posicion: ECTDNodoPosicionNodo.INICIO | ECTDNodoPosicionNodo.FINAL | number = ECTDNodoPosicionNodo.FINAL): boolean {
		let hijo: CTDNodo | null = null
		if (params instanceof CTDNodo) {
			hijo = params
		} else {
			hijo = new CTDNodo(params)
		}

		if (hijo.tipo == ECTDTipoNodo.RAIZ) {
			console.error('No se puede agregar un nodo de tipo RAIZ como hijo', hijo.id)
			return false
		}

		hijo._padre = this
		if (this._idInterno == null) {
			hijo._idInterno = (this._hijos.length + 1).toString()
		} else {
			hijo._idInterno = `${this._idInterno}.${(this._hijos.length + 1).toString()}`
		}

		if (posicion == ECTDNodoPosicionNodo.INICIO) {
			this._hijos.unshift(hijo)
			this.renumerarHijos()
			return true
		} else if (posicion == ECTDNodoPosicionNodo.FINAL) {
			this._hijos.push(hijo)
			this.renumerarHijos()
			return true
		} else if (Number.isInteger(posicion) && posicion >= 0 && posicion < this._hijos.length) {
			this._hijos.splice(posicion, 0, hijo)
			this.renumerarHijos()
			return true
		} else {
			console.error(`Posicion incorrecta para agregar hijo ${hijo.id} : ${posicion}`)
			return false
		}
	}

	/**
	 * @name agregarHermano
	 * @description Método para agregar un hermano a un nodo al inicio, al final, a la izquierda o a la derecha
	 */
	agregarHermano(hermano: CTDNodo, posicion: ECTDNodoPosicionNodo = ECTDNodoPosicionNodo.FINAL): boolean {
		if (hermano.tipo == ECTDTipoNodo.RAIZ) {
			console.error('No se puede agregar un nodo de tipo RAIZ como hermano', hermano.id)
			return false
		}
		// this.padre!.agregarHijo(hermano, posicion)
		switch (posicion) {
			case ECTDNodoPosicionNodo.INICIO:
				return this.padre?.agregarHijo(hermano, ECTDNodoPosicionNodo.INICIO) || false
			case ECTDNodoPosicionNodo.FINAL:
				return this.padre?.agregarHijo(hermano) || false
			case ECTDNodoPosicionNodo.IZQUIERDA:
				return this.padre?.agregarHijo(hermano, this.padre.hijos.indexOf(this)) || false
			case ECTDNodoPosicionNodo.DERECHA:
				return this.padre?.agregarHijo(hermano, this.padre.hijos.indexOf(this) + 1) || false
			default:
				console.error(`Posicion incorrecta para agregar hermano ${hermano.id} : ${posicion as string}`)
				return false
		}
	}

	/**
	 * @name borraNodo
	 * @description Método para borrar el nodo actual
	 */
	borraNodo(): void {
		if (this.padre) {
			const index = this.padre.hijos?.indexOf(this)
			if (index !== undefined && index !== -1) {
				this.padre.hijos?.splice(index, 1)
				this.padre?.renumerarHijos()
			}
		}

		// Eliminar referencias de este nodo como padre de sus hijos
		if (this.hijos) {
			for (const hijo of this.hijos) {
				hijo._padre = null
			}
		}
	}

	/**
	 * @name borrarNodoPorId
	 * @description Método para borrar un nodo por su id de manera recursiva
	 */
	borrarNodoPorId(id: string): boolean {
		if (this.id === id) {
			this.borraNodo()
			return true
		}
		// Buscar el índice del nodo con el ID especificado en los hijos
		const index = this.hijos?.findIndex(hijo => hijo.id === id)
		if (index !== undefined && index !== -1) {
			const nodoBorrado = this.hijos?.splice(index, 1)[0] // Eliminar el nodo encontrado de la lista de hijos
			nodoBorrado._padre = null // Actualizar la referencia al padre
			nodoBorrado.hijos?.forEach(hijo => hijo.borrarNodoPorId(hijo.id)) // Borrar los descendientes del nodo borrado de manera recursiva
			this.renumerarHijos() // Recalcular idInterno después de borrar el nodo
			return true // Se logró borrar el nodo
		}

		// Si el nodo no se encuentra en los hijos, buscar en los descendientes de manera recursiva
		if (this.hijos) {
			for (const hijo of this.hijos) {
				if (hijo.borrarNodoPorId(id)) {
					return true // Se logró borrar el nodo
				}
			}
		}
		return false // No se logró borrar el nodo
	}

	/**
	 * @name renumerarHijos
	 * @description Método para renumerar los hijos de un nodo después de borrar un nodo y matener actualizado el idInterno
	 */
	private renumerarHijos(): void {
		if (this._hijos) {
			this._hijos.forEach((hijo, i) => {
				if (this._idInterno == null) {
					hijo._idInterno = (i + 1).toString()
				} else {
					hijo._idInterno = `${this._idInterno}.${i + 1}`
				}
				hijo.renumerarHijos() // Llamar recursivamente para los descendientes
			})
		}
	}

	//Métodos de búsqueda
	/**
	 * @name buscarHijoPorId
	 * @description Método para buscar un hijo por su id de manera recursiva
	 */
	buscarHijoPorId(id: string): CTDNodo | null {
		// Verificar si el nodo actual es el buscado
		if (this.id === id) {
			return this
		}

		// Buscar en los hijos de manera recursiva
		if (this.hijos) {
			for (const hijo of this.hijos) {
				const resultado = hijo.buscarHijoPorId(id)
				if (resultado !== null) {
					return resultado
				}
			}
		}

		// Si no se encuentra en los hijos, retornar null
		return null
	}

	/**
	 * @name buscarHijoPorNombre
	 * @description Método para buscar un hijo por su nombre de manera recursiva
	 */
	buscarHijoPorNombre(nombre: string): CTDNodo | null {
		// Verificar si el nodo actual es el buscado
		if (this.nombre === nombre) {
			return this
		}

		// Buscar en los hijos de manera recursiva
		if (this.hijos) {
			for (const hijo of this.hijos) {
				const resultado = hijo.buscarHijoPorNombre(nombre)
				if (resultado !== null) {
					return resultado
				}
			}
		}

		// Si no se encuentra en los hijos, retornar null
		return null
	}

	/**
	 * @name buscarHijoPorArrayIds
	 * @description Método para buscar un hijo por un arreglo de Id's de manera recursiva
	 */
	buscarHijoPorArrayIds(idsArray: string[]): CTDNodo | null {
		let response: CTDNodo | null = null
		if (idsArray.length === 0) {
			return null
		}

		let id = idsArray.shift()
		response = this.buscarHijoPorId(id!)

		while (idsArray.length > 0) {
			id = idsArray.shift()
			response = response?.buscarHijoPorId(id!) || null
		}
		return response
	}

	/**
	 * @name buscarHijoPorArrayNombres
	 * @description Método para buscar un hijo por un arreglo de Inombres de manera recursiva
	 */
	buscarHijoPorArrayNombres(nombresArray: string[]): CTDNodo | null {
		let response: CTDNodo | null = null
		if (nombresArray.length === 0) {
			return null
		}

		let nombre = nombresArray.shift()
		response = this.buscarHijoPorNombre(nombre!)

		while (nombresArray.length > 0) {
			nombre = nombresArray.shift()
			response = response?.buscarHijoPorNombre(nombre!) || null
		}
		return response
	}

	/**
	 * @name buscarNodosPorCadenaEnLlave
	 * @description Método para buscar cadenas en diferentes posiciones, en la llave requerida y  de manera recursiva
	 */
	buscarNodosPorCadenaEnLlave(cadena: string, llave: ECTDNodoLlaveBusquedaCadena, posicion: ECTDNodoPosicionBusquedaCadena = ECTDNodoPosicionBusquedaCadena.INCLUYE): CTDNodo[] {
		const nodos: CTDNodo[] = []
		function buscarNodo(nodo: CTDNodo, llave: ECTDNodoLlaveBusquedaCadena, posicion: ECTDNodoPosicionBusquedaCadena) {
			if (compararCadenaEnTextoPorPosicion(cadena, nodo[llave] ?? '', posicion)) {
				nodos.push(nodo)
			}

			if (nodo.hijos && Array.isArray(nodo.hijos)) {
				for (const hijo of nodo.hijos) {
					buscarNodo(hijo, llave, posicion)
				}
			}
		}

		buscarNodo(this, llave, posicion)
		return nodos
	}

	/**
	 * @name buscarNodosPorTipo
	 * @description Método para buscar nodos por tipo de manera recursiva
	 */
	buscarNodosPorTipo(tipo: ECTDTipoNodo): CTDNodo[] {
		const nodos: CTDNodo[] = []

		function buscarNodo(nodo: CTDNodo) {
			if (nodo.tipo === tipo) {
				nodos.push(nodo)
			}
			if (nodo.hijos && Array.isArray(nodo.hijos)) {
				for (const hijo of nodo.hijos) {
					buscarNodo(hijo)
				}
			}
		}
		buscarNodo(this)
		return nodos
	}

	/**
	 * @name obtenerRutaAbsoluta
	 * @description Método para obtener la ruta absoluta de un nodo en el árbol
	 */
	obtenerRutaAbsoluta(): string {
		if (this.padre) {
			return `${this.padre.obtenerRutaAbsoluta()}/${this.nombre}`
		} else {
			return this.nombre
		}
	}

	/**
	 * @name validarArchivoEnHijos
	 * @description Método para validar si un archivo es válido en los hijos de un nodo
	 */
	validarArchivoEnHijos(archivo: IFileType): boolean {
		let respuesta = false
		if (this.hijos.length > 0) {
			this.hijos.forEach(hijo => {
				const extension = archivo.name.substring(archivo.name.lastIndexOf('.') + 1).toLowerCase()
				const formatoDocumento = obtenerFormatoDocumentoPorExtension(extension) as ECTDFormatoDocumento
				if (hijo.documentoFormato.includes(formatoDocumento)) {
					respuesta = true
				}
			})
		}
		return respuesta
	}

	/**
	 * @name validarArchivo
	 * @description Método para validar si un archivo es válido en un nodo
	 */
	validarArchivo(archivo: IFileType): boolean {
		let respuesta = false
		const extension = archivo.name.substring(archivo.name.lastIndexOf('.') + 1).toLowerCase()
		const formatoDocumento = obtenerFormatoDocumentoPorExtension(extension) as ECTDFormatoDocumento
		if (this.documentoFormato.includes(formatoDocumento)) {
			respuesta = true
		}
		return respuesta
	}

	tieneHijosConDocumentosEsperados(): boolean {
		let respuesta = true
		if (this.hijos.length > 0) {
			this.hijos.forEach(hijo => {
				if (hijo.documentoFormato.length == 0) {
					respuesta = false
				}
			})
		} else {
			respuesta = false
		}
		return respuesta
	}

	tieneDocumentosEsperados(): boolean {
		return this.documentoFormato.length > 0
	}

	tieneArchivos(): boolean {
		return this.archivos.length > 0
	}

	/**
	 * @name obtenerArchivos
	 * @description Método para obtener los archivos de un nodo y sus hijos de manera recursiva
	 */
	obtenerArchivos(): IFileType[] {
		let archivos: IFileType[] = []
		if (this.archivos.length > 0) {
			archivos = this.archivos
		}
		if (this.hijos.length > 0) {
			this.hijos.forEach(hijo => {
				archivos.push(...hijo.obtenerArchivos())
			})
		}
		return archivos
	}

	/**
	 * @name obtenerArchivosFrontEnd
	 * @description Método para obtener los archivos de un nodo y sus hijos de manera recursiva en formato IFileTypeFrontEnd
	 */
	obtenerArchivosFrontEnd(): IFileTypeFrontEnd[] {
		let archivos: IFileTypeFrontEnd[] = []
		if (this.archivos.length > 0) {
			archivos = this.archivos.map(archivo => ({
				...archivo,
				webkitRelativePath: `${this.obtenerRutaAbsoluta()}${archivo.name}`
			}))
		}
		if (this.hijos.length > 0) {
			this.hijos.forEach(hijo => {
				archivos.push(...hijo.obtenerArchivosFrontEnd())
			})
		}
		return archivos
	}

	/**
	 * @name eliminarArchivos
	 * @description Método para eliminar los archivos de un nodo y sus hijos de manera recursiva
	 */
	eliminarArchivos(): void {
		this.archivos = []
		if (this.hijos.length > 0) {
			this.hijos.forEach(hijo => {
				hijo.eliminarArchivos()
			})
		}
	}

	/**
	 * @name agregarArchivo
	 * @description Método para agregar o reemplazar un archivo a un nodo
	 */
	agregarArchivo(archivo: IFileType): void {
		//validar extensión
		const extension: string = archivo.name.substring(archivo.name.lastIndexOf('.') + 1).toLowerCase()
		const formatoDocumento: ECTDFormatoDocumento = obtenerFormatoDocumentoPorExtension(extension) as ECTDFormatoDocumento
		if (this._documentoFormato.includes(formatoDocumento)) {
			//reemplazar si ya existe
			if (this._archivos.find(arch => arch.uuid === archivo.uuid)) {
				this._archivos = this._archivos.map(arch => (arch.uuid === archivo.uuid ? archivo : arch))
			} else {
				//agregar si no existe
				this._archivos.push(archivo)
			}
		}
	}

	obtenerFormatosValidosHijos(): ECTDFormatoDocumento[] {
		const respuesta: ECTDFormatoDocumento[] = []
		if (this.hijos.length > 0) {
			this.hijos.forEach(hijo => {
				hijo.documentoFormato.forEach(formato => {
					if (!respuesta.includes(formato)) {
						respuesta.push(formato)
					}
				})
			})
		}

		return respuesta
	}

	//PROCESAMIENTO DE NODOS del CTD
	/**
	 *
	 * @name procesarIdentificadoresReservados
	 * @description Método para procesar identificadores reservados en nodos múltiples de un CTD, generando los nodos específicos
	 */
	procesarIdentificadoresReservados(reemplazos: ICTDParametrosProcesadorFoldersMultiples[]): void {
		const nodosDuplicadosPorBorrar: CTDNodo[] = []
		reemplazos.forEach(reemplazo => {
			const nodoBase = reemplazo.base instanceof Array ? this.buscarHijoPorArrayIds(JSON.parse(JSON.stringify(reemplazo.base))) : this.buscarHijoPorId(reemplazo.base)
			if (nodoBase) {
				nodoBase.buscarNodosPorTipo(reemplazo.tipo).forEach(nodoMultiple => {
					reemplazo.valores.forEach(valor => {
						const nodoDuplicado = new CTDNodo(nodoMultiple.exportarComoICTDNodo())
						nodoDuplicado.id = valor.id
						nodoDuplicado.nombre = valor.nombre
						nodoDuplicado.tipo = ECTDTipoNodo.FOLDER
						nodoMultiple.agregarHermano(nodoDuplicado, ECTDNodoPosicionNodo.FINAL)
					})
					nodosDuplicadosPorBorrar.push(nodoMultiple)
				})
			}
		})
		nodosDuplicadosPorBorrar.forEach(nodo => nodo.borraNodo())
	}

	/**
	 * @namespace CTD
	 * @name esNodoMultiple
	 * @description Función que determina si un nodo es de tipo múltiple (Se debe repetir en el CTD) o no.
	 */
	esNodoMultiple() {
		return [
			ECTDTipoNodo.DOCUMENTO_MULTIPLE,
			ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO,
			ECTDTipoNodo.FOLDER_MULTIPLE_SITIO,
			ECTDTipoNodo.FOLDER_MULTIPLE_PRODUCTO_TERMINADO,
			ECTDTipoNodo.FOLDER_MULTIPLE_INDICACION_TERAPEUTICA,
			ECTDTipoNodo.FOLDER_MULTIPLE_SISTEMA_CIERRE_CONTENEDOR,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUBPROCESO,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUJETO,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO_SITIO,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUBPROCESO_SUJETO_SITIO,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUJETO_SITIO,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUBPROCESO_SUJETO_SITIO,
			ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO_SITIO_UNION_SUBPROCESO_SUJETO_SITIO
		].includes(this._tipo)
	}

	/**
	 * @namespace CTD
	 * @name obtenerCaracterNodoMultiple
	 * @description Función que obtiene el caracter identificador de un nodo de tipo múltiple.
	 */
	obtenerCaracterNodoMultiple() {
		let caracter = ''
		if (this.esNodoMultiple()) {
			caracter = this.obtenerCaracterMultiple()
		}
		return caracter
	}

	/**
	 * @namespace CTD
	 * @name obtenerCaracterNodoMultiple
	 * @description Función que obtiene el caracter identificador de un tipo de nodo múltiple.
	 */
	obtenerCaracterMultiple() {
		let caracter = ''
		switch (this._tipo as keyof typeof ECTDTipoNodo) {
			case ECTDTipoNodo.DOCUMENTO_MULTIPLE:
				caracter = ECTDCaracteresIdentificadoresReservados.NUMERO //Number
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE:
				caracter = ECTDCaracteresIdentificadoresReservados.MULTIPLE_REPORTE //Report
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA:
				caracter = ECTDCaracteresIdentificadoresReservados.SUSTANCIA //Substance
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO:
				caracter = ECTDCaracteresIdentificadoresReservados.SUJETO //Manufacturer, acondicionador, almacen, distribuidor
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SITIO:
				caracter = ECTDCaracteresIdentificadoresReservados.SITIO //Facility, lugar de fabricación, acondicionamiento, almacen, distribución
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_PRODUCTO_TERMINADO:
				caracter = ECTDCaracteresIdentificadoresReservados.PRODUCTO_TERMINADO //Finished Product
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_INDICACION_TERAPEUTICA:
				caracter = ECTDCaracteresIdentificadoresReservados.INDICACION_TERAPEUTICA //Therapeutic Indication
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SISTEMA_CIERRE_CONTENEDOR:
				caracter = ECTDCaracteresIdentificadoresReservados.SISTEMA_CIERRE_CONTENEDOR //Container Closure System
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUBPROCESO:
				caracter = ECTDCaracteresIdentificadoresReservados.SUBPROCESO //Activity
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUJETO:
				caracter = ECTDCaracteresIdentificadoresReservados.SUSTANCIA_SUJETO //Substance and Manufacturer
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO_SITIO:
				caracter = ECTDCaracteresIdentificadoresReservados.SUJETO_SITIO //Manufacturer and Facility
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUBPROCESO_SUJETO_SITIO:
				caracter = ECTDCaracteresIdentificadoresReservados.SUBPROCESO_SUJETO_SITIO //Activity, Manufacturer, Facility
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO_SITIO_UNION_SUBPROCESO_SUJETO_SITIO:
				caracter = ECTDCaracteresIdentificadoresReservados.SUJETO_SITIO_UNION_SUBPROCESO_SUJETO_SITIO //Manufacturer and Facility union Activity, Manufacturer, Facility
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUJETO_SITIO:
				caracter = ECTDCaracteresIdentificadoresReservados.SUSTANCIA_SUJETO_SITIO //Substance and Manufacturer and Facility
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUBPROCESO_SUJETO_SITIO:
				caracter = ECTDCaracteresIdentificadoresReservados.SUSTANCIA_SUBPROCESO_SUJETO_SITIO //Substance and Activity and Manufacturer and Facility
				break
		}
		return caracter
	}

	//Utilidades de exportación y Logging
	/**
	 * @name obtenerNodosComoArray
	 * @description Método recursivo para obtener los nodos como un arreglo de nodos, con posibilida de filtar solo cierto tipo de nodos
	 */
	obtenerNodosComoArray(filtro: ECTDTipoNodo[] = []): CTDNodo[] {
		const nodos: CTDNodo[] = []
		nodos.push(this)
		if (this.hijos) {
			this.hijos.forEach(hijo => {
				nodos.push(...hijo.obtenerNodosComoArray())
			})
		}
		if (filtro?.length) {
			return nodos.filter(nodo => filtro.includes(nodo.tipo))
		} else {
			return nodos
		}
	}

	/**
	 * @name exportarComoICTDNodo
	 * @description Método para exportar un nodo como ICTDNodo para utilizarlo en otros componentes
	 */
	exportarComoICTDNodo(reemplazarIdsPorInternos: boolean = false): ICTDNodo {
		const nodoExportado: ICTDNodo = {
			tipo: this.tipo,
			id: reemplazarIdsPorInternos ? this._idInterno ?? 'root' : this.id, // Reemplazar el ID por el ID interno si se especifica ()
			nombre: this.nombre,
			descripcion: this.descripcion,
			instrucciones: this.instrucciones,
			documentoFormato: this.documentoFormato,
			archivos: this.archivos
		}

		if (this.hijos) {
			nodoExportado.hijos = this.hijos.map(hijo => hijo.exportarComoICTDNodo(reemplazarIdsPorInternos))
		}

		return nodoExportado
	}

	/**
	 * @name exportarArbolLineas
	 * @description Método para exportar el árbol como líneas de texto de manera recursiva
	 */
	private exportarArbolLineas(indentacion: number = 0): string[] {
		const respuesta: string[] = []
		const espacio = '  '.repeat(indentacion)
		respuesta.push(`${espacio}${this._idInterno ? this._idInterno : 'root'} : (${this.id || this.nombre}) [${this.tipo}]`)

		if (this.hijos) {
			this.hijos.forEach(hijo => {
				respuesta.push(...hijo.exportarArbolLineas(indentacion + 1))
			})
		}
		return respuesta
	}

	/*
	 * @name imprimirArbolLineas
	 * @description Método para imprimir el árbol en consola
	 */
	imprimirArbolLineas(): void {
		console.log('='.repeat(20))
		console.log(this.exportarArbolLineas().join('\n'))
		console.log('\n\n')
	}

	/**
	 * @name exportarArbolCTDConsola
	 * @description Método para exportar el árbol como líneas de texto de manera recursiva para visualización en consola.
	 */
	exportarArbolCTDConsola(vista: ECTDNodoVistaArbolConsola = ECTDNodoVistaArbolConsola.ID_NOMBRE, mostrarDescripcion: boolean = false, interlineado = 1, prefijo = ''): string[] {
		//return obtenerCTDConsola(this, vista as unknown as ECTDVistaArbolConsola, mostrarDescripcion)
		const lineas: string[] = []
		let linea = ''
		if (prefijo == '') {
			linea = this.obtenerIcono() + this.obtenerEtiqueta(vista)
			if (mostrarDescripcion && this.descripcion) {
				linea += `${this.espaciosEntreEtiquetaYDescripcionetiqueta(this.obtenerEtiqueta(vista))}${this.descripcion}`
			}
			lineas.push(linea)
		}

		if (this.hijos) {
			this.hijos.forEach((hijo: CTDNodo, i: number) => {
				linea = ''
				const isLast = i === this.hijos.length - 1
				const nuevoPrefijo = prefijo + (isLast ? '   ' : '│  ')
				if (interlineado >= 1) {
					for (let i = 1; i < interlineado; i++) {
						linea += prefijo + '|\n'
					}
				}
				linea += prefijo + (isLast ? '└──' : '├──') + hijo.obtenerIcono() + hijo.obtenerEtiqueta(vista)
				if (mostrarDescripcion && hijo._descripcion) {
					const relleno = hijo.espaciosEntreEtiquetaYDescripcionetiqueta(hijo.obtenerEtiqueta(vista))
					const descripcion = hijo.obtenerEtiqueta(ECTDNodoVistaArbolConsola.DESCRIPCION).substring(0, 500)
					linea += `${relleno} ${descripcion} `
				}
				lineas.push(linea)
				lineas.push(...hijo.exportarArbolCTDConsola(vista, mostrarDescripcion, interlineado, nuevoPrefijo))
			})
		}
		return lineas
	}

	/**
	 * @name exportarArbolCTDaCSV
	 * @description Método para exportar el árbol como archivo csv de manera recursiva
	 */
	exportarArbolCTDaCSV(): string {
		const lineas: string[] = []
		lineas.push('id,nombre,tipo,descripcion,formato, path, longitudPath')
		const exportarNodo = (nodo: CTDNodo) => {
			const linea = `${nodo.id},${nodo.nombre},${nodo.tipo},"${escaparCadena(nodo.descripcion)}","${escaparCadena(nodo.documentoFormato.join())}", "${nodo.obtenerRutaAbsoluta()}", ${escaparCadena(
				nodo.obtenerRutaAbsoluta().length.toString()
			)}`
			lineas.push(linea)
			if (nodo.hijos) {
				nodo.hijos.forEach(hijo => {
					exportarNodo(hijo)
				})
			}
		}

		const escaparCadena = (cadena: string) => {
			let response = cadena
			response = response.replace(/,/g, '\\,')
			response = response.replace(/"/g, '\\"')
			return response
		}
		exportarNodo(this)
		return lineas.join('\n')
	}

	/**
	 * @name exportarArbolCTDArray
	 * @description Método para exportar el árbol como un arreglo de nodos de manera recursiva
	 */
	exportarArbolCTDArray(): CTDNodo[] {
		const nodos: CTDNodo[] = []
		nodos.push(this)
		if (this.hijos) {
			this.hijos.forEach(hijo => {
				if (hijo.id) {
					nodos.push(...hijo.exportarArbolCTDArray())
				}
			})
		}
		return nodos
	}

	/**
	 * @name imprimirArbolCTDConsola
	 * @description Método para imprimir el árbol en consola
	 */
	imprimirArbolCTDConsola(vista: ECTDNodoVistaArbolConsola = ECTDNodoVistaArbolConsola.ID_NOMBRE, mostrarDescripcion: boolean = false): void {
		console.log(this.exportarArbolCTDConsola(vista, mostrarDescripcion).join('\n'))
	}

	/**
	 * @name log
	 * @description Método para loggear un nodo en consola
	 */
	static logNodo(nodo: CTDNodo | ICTDNodo) {
		console.log(
			`id: ${nodo.id}, nombre: ${nodo.nombre}, tipo: ${nodo.tipo}, descripcion: ${nodo.descripcion}, instrucciones: ${
				nodo.instrucciones
			}, formato: ${nodo.documentoFormato?.join()}, archivos: ${nodo.archivos?.join()}`
		)
	}

	/**
	 * @name log
	 * @description Método para loggear el nodo en consola
	 */
	log(): void {
		console.log(
			`idInterno:${this._idInterno} id: ${this.id}, nombre: ${this.nombre}, tipo: ${this.tipo}, descripcion: ${this.descripcion}, instrucciones: ${
				this.instrucciones
			}, formato: ${this.documentoFormato?.join()}, archivos: ${this.archivos?.join()}`
		)
	}

	/**
	 * @name obtenerIcono
	 * @description Método para obtener el icono del nodo en función de su tipo, auxiliar para exportarArbolCTDConsola
	 */
	obtenerIcono() {
		let icono = ''
		switch (this._tipo) {
			case ECTDTipoNodo.RAIZ:
				icono = '🌐 '
				break
			case ECTDTipoNodo.FOLDER:
				icono = '📁 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA:
				icono = '🧪 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO:
				icono = '🏭 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SITIO:
				icono = '🗺 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_PRODUCTO_TERMINADO:
				icono = '💊 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_INDICACION_TERAPEUTICA:
				icono = '⚕️ '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SISTEMA_CIERRE_CONTENEDOR:
				icono = '🛢 '
				break
			case ECTDTipoNodo.DOCUMENTO:
				icono = '📕 '
				break
			case ECTDTipoNodo.DOCUMENTO_MULTIPLE:
				icono = '📚 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE:
				icono = '🗂 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUBPROCESO:
				icono = '🏗  '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUJETO:
				icono = '🧪 - 🏭 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO_SITIO:
				icono = '🏭 - 🗺 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUBPROCESO_SUJETO_SITIO:
				icono = '🏗  - 🏭 - 🗺 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUJETO_SITIO_UNION_SUBPROCESO_SUJETO_SITIO:
				icono = '🏗  🏭 - 🗺 | U | 🏭 - 🗺 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUJETO_SITIO:
				icono = '🧪 - 🏭 - 🗺 '
				break
			case ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUBPROCESO_SUJETO_SITIO:
				icono = '🧪 - 🏗  - 🏭 - 🗺 '
				break
		}
		return icono
	}

	/**
	 * @name obtenerEtiqueta
	 * @description Método para obtener la etiqueta del nodo en función de la vista requerida, auxiliar para exportarArbolCTDConsola
	 */
	obtenerEtiqueta(campoEtiqueta: ECTDNodoVistaArbolConsola) {
		let etiqueta = ''
		switch (campoEtiqueta) {
			case ECTDNodoVistaArbolConsola.ID:
				etiqueta = this._id || ''
				break
			case ECTDNodoVistaArbolConsola.NOMBRE:
				etiqueta = this._nombre || ''
				break
			case ECTDNodoVistaArbolConsola.DESCRIPCION:
				etiqueta = this._descripcion || ''
				break
			case ECTDNodoVistaArbolConsola.ID_NOMBRE:
				etiqueta = `${this._id || ''} : ${this._nombre || ''}`
				break
		}
		return etiqueta
	}

	/**
	 * @name espaciosEntreEtiquetaYDescripcionetiqueta
	 * @description Método para obtener los espacios entre la etiqueta y la descripción del nodo, auxiliar para exportarArbolCTDConsola
	 */
	espaciosEntreEtiquetaYDescripcionetiqueta(etiqueta: string) {
		let espacios = ''
		try {
			const maxlengEtiquetas = 70
			const n = maxlengEtiquetas - etiqueta.length
			//Agregamos el margen izquierdo
			espacios += ' '.repeat(n > 0 ? n : 0)
			//Agregamos la identación en función de la profundidad del nodo
			espacios += ' '.repeat(this.id.split('.').length)
		} catch (e) {
			console.error(e)
			console.log(this, etiqueta)
		}
		return espacios
	}
}

/**
 * @name compararCadenaEnTextoPorPosicion
 * @description Función que compara una cadena en un texto en función de la posición requerida
 */
function compararCadenaEnTextoPorPosicion(cadena: string, texto: string, posicion: ECTDNodoPosicionBusquedaCadena): boolean {
	let response = false
	switch (posicion) {
		case ECTDNodoPosicionBusquedaCadena.INICIO:
			if (texto.startsWith(cadena) && texto !== cadena) {
				response = true
			}
			break
		case ECTDNodoPosicionBusquedaCadena.MEDIO:
			if (texto.includes(cadena) && !texto.startsWith(cadena) && !texto.endsWith(cadena)) {
				response = true
			}
			break
		case ECTDNodoPosicionBusquedaCadena.FINAL:
			if (texto.endsWith(cadena) && texto !== cadena) {
				response = true
			}
			break
		case ECTDNodoPosicionBusquedaCadena.INCLUYE:
			if (texto.includes(cadena)) {
				response = true
			}
			break
		case ECTDNodoPosicionBusquedaCadena.IGUAL:
			if (texto == cadena) {
				response = true
			}
			break
	}
	return response
}
