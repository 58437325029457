import { useConfigStore } from 'src/store/config'
import { ErrorHandler } from 'cofepris-typesafe/Types/Errors'
import ERROR_API from 'src/errors/ERROR_API'
import { AuthenticationAPIRequest, AuthenticationAPIResponse } from 'cofepris-typesafe/Types/Auth'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const callAuthenticationAPI = async function (request: AuthenticationAPIRequest, jwt?: string, forceResponse?: AuthenticationAPIResponse): Promise<AuthenticationAPIResponse> {
	return new Promise(resolve => {
		//Forzar la respuesta simulada del API
		if (forceResponse) {
			resolve(forceResponse)
			return
		}

		const headers = {
			'Content-Type': 'application/json'
		}

		if (jwt) {
			Object.assign(headers, { Authorization: jwt })
		}

		const requestOptions: AxiosRequestConfig = {
			method: 'POST',
			headers: headers,
			data: request.body,
			url: useConfigStore().getEnvironmentConfig().API_SERVICES.AUTHENTICATION[request.accion]
		}
		axios(requestOptions)
			.then((response: AxiosResponse) => {
				const json: AuthenticationAPIResponse = response.data || {} // (await response.json()) || {}
				switch (response.status) {
					case 200:
						resolve({
							status: 'OK',
							message: json.message,
							data: json.data
						} as AuthenticationAPIResponse)
						break
					case 400:
						resolve({
							status: 'ERROR',
							message: json.message,
							data: json.data
						} as AuthenticationAPIResponse)
						break
					default:
						resolve({
							status: 'NETWORK_ERROR',
							message: 'Error ' + response.status + ': ' + (json.message || 'Ocurrió un error de red en la solicitud'),
							data: {}
						} as AuthenticationAPIResponse)

						throw new ERROR_API('Error en solicitud a API', {
							timestamp: Date.now().toString(),
							environment: useConfigStore().getEnvironment(),
							url: useConfigStore().getEnvironmentConfig().API_SERVICES.AUTHENTICATION[request.accion],
							method: 'POST',
							request,
							response: json,
							status: response.status.toString()
						})
				}
			})
			.catch(error => {
				resolve({
					status: 'NETWORK_ERROR',
					message: error.response?.data?.message || error.message || 'Ocurrió un error de red en la solicitud @ ' + useConfigStore().getEnvironmentConfig().API_SERVICES.AUTHENTICATION[request.accion],
					data: {}
				} as AuthenticationAPIResponse)
				ErrorHandler(error)
			})
			.finally(() => null)
	})
}
