export function numeroCertificadoCorto(certificado: string): string {
	let response = ''
	const certificadoArray = Object.values(certificado)

	certificadoArray.forEach((value, index) => {
		if (index % 2 == 1) {
			response += value
		}
	})
	return response
}
