//const notSerializable = ['ComputedRefImpl', 'AccessLevelContainer']
export default class JsonUtils {
	public static stringify(obj: any, spaces: number = 4) {
		const m = new Map()
		const v = new Map()
		let init: any = null

		const s = JSON.stringify(
			obj,
			(field, value) => {
				const p = m.get(this) + (Array.isArray(this) ? `[${field}]` : '.' + field)
				const isComplex = value === Object(value)

				if (isComplex) m.set(value, p)

				const pp = v.get(value) || ''
				const path = p.replace(/undefined\.\.?/, '')
				let val = pp ? `#REF:${pp[0] == '[' ? '$' : '$.'}${pp}` : value

				!init ? (init = value) : val === init ? (val = '#REF:$') : 0
				if (!pp && isComplex) v.set(value, path)

				return val
			},
			spaces
		)
		// console.log(s)
		return s
	}
}
