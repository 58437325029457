import { defineStore } from 'pinia'
import LogRocket from 'logrocket'
import { ref } from 'vue'
import { EnvironmentType } from 'cofepris-typesafe/Constants/Config'
import { useConfigStore } from 'src/store/config'

export const useLogRocketStore = defineStore('logrocket', () => {
	const initialized = ref(false)
	const lastUser = ref('')
	const sessionURL = ref('')

	function initLogRocket(forceInit = false) {
		if ([EnvironmentType.QA, EnvironmentType.STAGE, EnvironmentType.SEC].includes(useConfigStore().getEnvironment()) || forceInit) {
			console.log('Inicializando LogRocket para el webapp')
			LogRocket.init('ngmbew/tramitesdigitales', {
				release: (import.meta as any).env.VITE_GITVERSION
			})
			initialized.value = true
			getSessionURL()
			setTimeout(() => {
				getSessionURL()
			}, 5000)
		}
	}

	function setUser(uid: string) {
		if (initialized.value) {
			LogRocket.identify(uid)
			if (lastUser.value == '') {
				track('NUEVO_USUARIO')
			} else if (lastUser.value != uid) {
				track('CAMBIO_USUARIO')
				newSession()
			} else {
				track('USUARIO_ACTUAL')
			}
			getSessionURL()
			lastUser.value = uid
		}
	}

	function getSessionURL(): string {
		if (initialized.value) {
			sessionURL.value = LogRocket.sessionURL || ''
			return LogRocket.sessionURL || ''
		}
		return ''
	}

	function newSession() {
		if (initialized.value) {
			LogRocket.startNewSession()
			getSessionURL()
		}
	}

	function track(event: string) {
		if (initialized.value) {
			getSessionURL()
			LogRocket.track(event)
		}
	}

	return {
		initialized,
		sessionURL,
		initLogRocket,
		setUser,
		getSessionURL,
		track
	}
})
