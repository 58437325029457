<script lang="ts" setup>
import { ACL } from 'src/router/acl'
//import { useUserStore } from 'src/store/auth'
import { RolType, getRoleByRolType, ERolType } from 'cofepris-typesafe/Types/Roles'
import { onMounted, computed } from 'vue'
import { RouteRecordNormalized, useRouter } from 'vue-router'
//const userStore = useUserStore()
const router = useRouter()
const props = withDefaults(
	defineProps<{
		rol: RolType
		ruta: string
		grupo?: string
		mostrarRolesRequeridos?: boolean
		class?: string
		debug?: boolean
		trace?: boolean
		id?: string
	}>(),
	{
		mostrarRolesRequeridos: false,
		debug: false,
		trace: false,
		id: ''
	}
)

onMounted(() => {
	props.debug && console.log(`ValidarRolRuta:(${props.id})`, props)
})

const obtenerMatchingRoute = (ruta: string): RouteRecordNormalized | null => {
	props.trace && console.log('obtenerMatchingRoute:', ruta)

	let response = router.resolve(ruta)
	for (let r of response.matched) {
		let route = r.path
		if (route.indexOf(':') >= 0) {
			for (let param in response.params) {
				route = ruta.replace(`:${param}`, response.params[param] as string)
			}
		}
		if (route == ruta) {
			return r
		}
	}
	return null
}

const isGrupoEnRuta = (ruta: string, grupo: string): boolean => {
	props.trace && console.log('isGrupoEnRuta:', ruta, grupo)

	let response = false
	let route = router.resolve(ruta)
	if (route) {
		if (route.params) {
			if (route.params.RFC) {
				if (route.params.RFC == grupo) {
					response = true
				}
			}
		}
	}
	return response
}

const rutaNormalizada = computed(() => {
	let ruta = obtenerMatchingRoute(props.ruta)
	return ruta ? ruta.path : ''
})

const permitido = computed(() => {
	let response = false
	let rol = getRoleByRolType(props.rol)
	let ruta = obtenerMatchingRoute(props.ruta)
	props.trace && console.log('props.ruta:', props.ruta)
	props.trace && console.log('props.grupo:', props.grupo)
	props.trace && console.log('ruta:', ruta)
	if (ruta) {
		//Validar Rol
		response = (ruta.meta.requiresRoles as []).includes(rol.type as never)

		//Validar Grupo
		if (response && ruta.meta.requiresAccessTokenGroupMatch) {
			if (props.grupo && isGrupoEnRuta(props.ruta, props.grupo)) {
				response = true
			} else {
				response = false
			}
		}
	}
	props.debug && response ? console.info('Permitido:', response) : null
	props.debug && !response ? console.log('Permitido:', response) : null
	return response
})

const redirigir = computed(() => {
	let response = false
	let rol = getRoleByRolType(props.rol)
	let ruta = obtenerMatchingRoute(props.ruta)
	props.trace && console.log('props.ruta:', props.ruta)
	props.trace && console.log('props.grupo:', props.grupo)
	props.trace && console.log('ruta:', ruta)
	if (ruta) {
		//Validar Rol
		response = rol.type != ERolType.ANONIMO && (ruta.meta.requiresRoles as []).includes(ERolType.ANONIMO as never)
	}
	props.debug && response ? console.info('Redirigir:', response) : null
	return response
})

const rolesRequeridos = computed(() => {
	let response: string[] = []
	let prefix = ''
	let ruta = ACL[rutaNormalizada.value]
	if (ruta) {
		ruta.requiresRoles.forEach(rol => {
			response.push(getRoleByRolType(rol).label)
		})

		if (response.length == 1) {
			prefix = ' requiere el rol de '
		}

		if (response.length > 1) {
			prefix = ' requiere alguno de los siguientes roles: '
		}

		return {
			mensaje: `La ruta ${props.ruta} ${prefix}`,
			roles: response.join(', ')
		}
	} else {
		return {
			mensaje: `La ruta ${props.ruta} no existe`,
			roles: ''
		}
	}
})

const status = computed(() => {
	let response = 'INEXISTENTE'
	if (rutaNormalizada.value && permitido.value && !redirigir.value) {
		response = 'PERMITIDO'
	}

	if (rutaNormalizada.value && !permitido.value && !redirigir.value) {
		response = 'DENEGADO'
	}

	if (rutaNormalizada.value && !permitido.value && redirigir.value) {
		response = 'REDIRIGIR'
	}

	if (!rutaNormalizada.value) {
		response = 'INEXISTENTE'
	}

	if (rutaNormalizada.value == '/sitio/logout') {
		response = 'PERMITIDO'
	}
	props.debug && console.log(rutaNormalizada.value + '  ' + response)
	props.debug && console.log([permitido.value, redirigir.value])
	return response
})
</script>

<template>
	<div :class="props.class">
		<slot v-if="status == 'PERMITIDO' || status == 'REDIRIGIR'" name="permitido"></slot>
		<slot v-if="status == 'DENEGADO'" name="denegado"></slot>
		<slot v-if="status == 'REDIRIGIR'" name="redirigir"></slot>
		<slot v-if="status == 'INEXISTENTE'" name="inexistente"></slot>

		<div v-if="status == 'DENEGADO' && mostrarRolesRequeridos" class="alert alert-warning">
			<div class="text-justify">
				* {{ rolesRequeridos.mensaje }} <span class="bold">{{ rolesRequeridos.roles }}</span>
			</div>
		</div>
	</div>
</template>
