import { AnyEventObject } from 'xstate'

const setDebug = function (context: any, event: AnyEventObject): any {
	context.debug ? console.log('setDebug', [context, event]) : null
	const newContext = { ...context }
	newContext.debug = event.debug ? event.debug : false
	context.debug ? console.log('newContext', newContext) : null
	return newContext
}

const setRequestRoleError = function (context: any, event: AnyEventObject): any {
	context.debug ? console.log('setRequestRoleError', [context, event]) : null
	const newContext = { ...context }
	newContext.requestRoleError = event.requestRoleError || null
	context.debug ? console.log('newContext', newContext) : null
	return newContext
}

const setRequestLoginError = function (context: any, event: AnyEventObject): any {
	context.debug ? console.log('setRequestRoleError', [context, event]) : null
	const newContext = { ...context }
	newContext.requestLoginError = event.requestLoginError || null
	context.debug ? console.log('newContext', newContext) : null
	return newContext
}

export { setRequestRoleError, setRequestLoginError, setDebug }
