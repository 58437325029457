<script lang="ts" setup>
import { computed, onMounted, useRouter, useRoute, useSessionStore, useUserStore, ref } from './bom'
import { useConfigStore } from 'src/store/config'
import WebAuthn from 'src/components/WebAuthn/WebAuthn.vue'
import Unauthorized from 'src/pages/Unauthorized.vue'
import NotFound from 'src/pages/NotFound.vue'
import Redirigir from 'src/pages/Redirigir.vue'
import { Grupo, Rol, ERolType, ROL_ANONIMO } from 'cofepris-typesafe/Types/Roles'
import VueCountdown from '@chenfengyuan/vue-countdown'

const props = defineProps({
	debug: {
		type: Boolean,
		//required: true,
		default: false
	},
	trace: {
		type: Boolean,
		//required: true,
		default: false
	},
	class: {
		type: String,
		default: ''
	}
})

const userStore = useUserStore()
const sessionMachine = useSessionStore().machine
const router = useRouter()

const idleSessionRemaningSeconds = ref(0)
const renewAccessTokenInProgress = ref(false)
let accessTokenInterval: ReturnType<typeof setInterval> | null = null

onMounted(() => {
	props.debug && console.log('on mounted')
	sessionMachine.send('START', { debug: props.debug })
})

const nextEvents = computed(() => sessionMachine.state.nextEvents)

const getButtonColor = (rol: ERolType) => {
	let response = useConfigStore().getConfig().COLORS.ROLES.ANONIMO
	let rolesColor = useConfigStore().getConfig().COLORS.ROLES
	if (rolesColor[rol]) {
		response = rolesColor[rol]
	}
	return response
}

const getAvatarFontSize = function (code: string) {
	let response = '20px'
	if (code.length > 1) {
		return '16px'
	}
	return response
}

const logout = async () => {
	props.debug && console.log('on logout')
	clearAccessTokenTimeInterval()
	router.replace({ path: '/sitio/logout' })
	sessionMachine.send('LOGOUT')
	await useUserStore().logOut()
}

//Trancisiones de la máquina de estados
sessionMachine.service.onTransition(state => {
	let previousState = state.history ? state.history.toStrings().join(',') : null
	let currentState = state.toStrings().join(',')
	let id = state.machine ? state.machine.id : ''
	props.debug && console.log(`${id}: %c${previousState} %c-->(${state.event.type}) --> %c${currentState}`, 'color:red', 'color:blue', 'color:green')

	if (state.event.type == 'FORCE_LOGOUT') {
		props.debug && console.log('FORCE_LOGOUT interceptado')
		clearAccessTokenTimeInterval()
		useUserStore().localLogOut()
	}
})

const isState_unauthenticated_idle = computed(() => {
	if (sessionMachine.state.matches('unauthenticated.idle')) {
		clearAccessTokenTimeInterval()
		return true
	} else {
		return false
	}
})

const isState_request_login_fail = computed(() => sessionMachine.state.matches('unauthenticated.request_login_fail'))

const isState_authenticated_selected_role_idle = computed(() => {
	if (sessionMachine.state.matches('authenticated.selected_role.idle')) {
		setAccessTokenTimeInterval()
		return true
	} else {
		return false
	}
})

const isState_authenticated_unselected_role_idle = computed(() => sessionMachine.state.matches('authenticated.unselected_role.idle'))

const isState_logout_confirmation = computed(() => sessionMachine.state.matches('authenticated.selected_role.logout_confirmation'))

const isState_logging_out = computed(() => sessionMachine.state.matches('authenticated.selected_role.logging_out'))

const isState_logging_out_error = computed(() => sessionMachine.state.matches('authenticated.selected_role.logging_out_error'))

const isState_role_waiting = computed(() => sessionMachine.state.matches('authenticated.unselected_role.role_waiting'))

const isState_role_request_fail = computed(() => sessionMachine.state.matches('authenticated.unselected_role.role_request_fail'))

const isState_authenticator_register = computed(() => sessionMachine.state.matches('authenticated.authenticator_register'))

const routeData = computed(() => {
	let response = {
		ruta: useRoute().path,
		grupo: userStore.User.activeGroup.grupo,
		rol: userStore.User.activeRole.type
	}
	props.debug && console.log('routeData:', response)
	return response
})

const setCurrentRole = async (group: Grupo, role: Rol) => {
	props.debug && console.log('on setCurrentRole', [group, role])
	if (userStore.User.activeRole.type != ROL_ANONIMO.type) {
		router.replace({ path: '/perfil/cuenta' })
	}

	try {
		await userStore.setCurrentRole(group, role, userStore.User.IdentityJWT)
		return
	} catch (e) {
		props.debug && console.error('Error al establecer el rol', [e, userStore.User.activeRole, group, role])
		return
	}
}

function clearAccessTokenTimeInterval() {
	props.debug ? console.log('on clearAccessTokenTimeInterval') : null
	if (accessTokenInterval) {
		clearInterval(accessTokenInterval)
		accessTokenInterval = null
	}
}

//ToDo: Evaluar si esta responsabilidad debería mudarse a la máquina de estados de sesión
function setAccessTokenTimeInterval() {
	props.debug ? console.log('on setAccessTokenTimeInterval') : null

	if (accessTokenInterval) {
		clearAccessTokenTimeInterval()
	}

	if (!userStore.User.IdentityJWT || !userStore.User.AccessJWT || !userStore.User.activeGroup.grupo || !userStore.User.activeRole.type || userStore.User.activeRole.type == ROL_ANONIMO.type) {
		return
	}

	accessTokenInterval = setInterval(() => {
		let ttl = userStore.getAccessTokenLiveRemainSeconds()
		if (ttl <= 0) {
			props.debug && console.error('El token de acceso ha expirado')
			sessionMachine.send('FORCE_LOGOUT')
			router.replace({ path: '/sitio/logout' })
		} else {
			props.debug ? console.log('Quedan ' + ttl / 60 + ' minutos para que expire el token de acceso') : null
		}

		if (ttl <= useConfigStore().getEnvironmentConfig().AUTHENTICATION.JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL) {
			props.debug && console.log('El token de acceso debe ser renovado')
			if (userStore.User.activeRole.type == ROL_ANONIMO.type) {
				props.debug && console.log('El token de acceso no se renueva para el rol anónimo.Cerrando sesión')
				sessionMachine.send('FORCE_LOGOUT')
				router.replace({ path: '/sitio/logout' })
			} else {
				renovarAccessToken()
			}
		}
	}, 30000)
}

function confirmLogout() {
	props.debug ? console.log('on confirmLogout') : null
	sessionMachine.send('CONFIRM_LOGOUT')
}

async function renovarAccessToken() {
	props.debug ? console.log('on renovarAccessToken') : null
	renewAccessTokenInProgress.value = true
	await userStore.renewRoleAccessToken()
	renewAccessTokenInProgress.value = false
}

//IDLE SESSION CONTROL
function onIdle() {
	props.debug && console.log('Cierre de sesión forzado por inactividad')
	sessionMachine.send('FORCE_LOGOUT')
	router.replace({ path: '/sitio/logout' })
}

function onRemind() {
	props.debug && console.log('Alerta de cierre de sesión por inactividad')
	idleSessionRemaningSeconds.value = useConfigStore().getEnvironmentConfig().SESSION.ALERT_IDLE_TIME_SECONDS
}

const sessionCloseByIdleAlert = computed(() => {
	let response = false
	if (isState_authenticated_selected_role_idle.value && idleSessionRemaningSeconds.value > 0) {
		response = true
	}
	return response
})
</script>
<template>
	<div id="sessionContainer" class="container100p" :class="props.class">
		<!--INICIA DEBUG-->
		<details v-if="props.debug" class="debug fs-xs">
			<summary class="">
				Máquina: <span class="bold">{{ sessionMachine.service.id }}</span> | Estado:<span class="bold">{{ sessionMachine.state.value }}</span>
			</summary>
			<div class="p5 m5 bss bw1 bclg">
				<button v-for="(event, index) in nextEvents" :key="index" class="btn btn-md fs-xs m10" @click="sessionMachine.send(event)">
					{{ event }}
				</button>
			</div>
			<pre class="code"> Contexto: {{ JSON.stringify(sessionMachine.state.context, undefined, 2) }}</pre>
		</details>

		<!--REQUEST LOGIN FAIL -->
		<va-modal v-model="isState_request_login_fail" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12">
					<div class="text-center font-weight-bold">
						Ocurrió un error al solicitar el inicio de sesión:
						{{ sessionMachine.state.context.requestLoginError }}
					</div>
					<br />
				</div>
			</div>
		</va-modal>

		<!--AUTHENTICATOR REGISTER -->
		<va-modal v-model="isState_authenticator_register" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12">
					<div class="text-center font-weight-bold">
						<WebAuthn :debug="false" mode="REGISTER" :challenge="useUserStore().User.identityToken.jti" />
					</div>
					<br />
				</div>
			</div>
		</va-modal>

		<!-- START ROLE REQUEST FAIL-->
		<va-modal v-model="isState_role_request_fail" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12">
					<div class="text-center font-weight-bold">
						Ocurrió un error al solicitar el rol: {{ sessionMachine.state.context.requestRoleError }} <br /><br />
						<button class="btn btn-primary" @click="logout">CERRAR SESIÓN</button>
						<button class="btn btn-default ml10" @click="sessionMachine.send('RETRY')">REINTENTAR</button>
					</div>
					<br />
				</div>
			</div>
		</va-modal>
		<!-- END ROLE REQUEST FAIL-->

		<!--START ROLE IN PROGRESS -->
		<va-modal v-model="isState_role_waiting" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12">
					<div class="text-center font-weight-bold">
						Obteniendo permisos del rol<br /><br />
						<va-progress-circle indeterminate :color="useConfigStore().getConfig().COLORS.GENERAL.RED" style="margin: auto" />
					</div>
				</div>
			</div>
		</va-modal>
		<!--END ROLE IN PROGRESS-->

		<!-- START ROLE SELECTOR -->
		<va-modal
			v-model="isState_authenticated_unselected_role_idle"
			allow-body-scroll
			message="Seleccione un solicitante titular y un rol para continuar"
			no-dismiss
			hide-default-actions
			style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)"
		>
			<va-card v-for="(group, groupIndex) in userStore.User.identityToken.data.grupos" :key="groupIndex" outlined style="margin-bottom: 4%">
				<va-card-title class="">
					<div class="mxw500 va-text-left">{{ group.solicitante }}</div>
				</va-card-title>
				<va-card-actions :align="'stretch'" vertical>
					<va-button
						v-for="(role, roleIndex) in group.roles"
						:cyid="'roleSelector_'+group.grupo+'_'+role.type"
						:key="roleIndex"
						style="border-radius: 0"
						:color="getButtonColor(role.type as ERolType)"
						hover-behavior="opacity"
						:hover-opacity="0.4"
						@click="setCurrentRole(group, role)"
					>
						<va-avatar :color="useConfigStore().getConfig().COLORS.ROLE_AVATAR_BADGE" style="opacity: 1" size="30px" :font-size="getAvatarFontSize(role.code)">{{ role.code }}</va-avatar>
						&nbsp;
						{{ role.label }}
					</va-button>
				</va-card-actions>
			</va-card>
			<br />
		</va-modal>
		<!--END ROLE SELECTOR-->

		<!-- START SESSION ALERT-->
		<va-modal v-model="isState_logout_confirmation" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12" style="text-align: center">
					<div class="text-center font-weight-bold">¿Esta seguro de cerrar sesión?</div>
					<br />
					<button class="btn btn-default" type="button" @click="sessionMachine.send('CANCEL_LOGOUT')">No, Cancelar</button>
					&nbsp;&nbsp;
					<button class="btn btn-primary" type="button" @click="confirmLogout">Cerrar Sesión</button>
				</div>
			</div>
		</va-modal>
		<!-- END SESSION ALERT-->

		<!-- START CLOSE ALERT-->
		<va-modal v-model="isState_logging_out" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12">
					<div class="text-center font-weight-bold">Cerrando sesión de manera segura</div>
					<br /><br />
					<va-progress-circle indeterminate :color="useConfigStore().getConfig().COLORS.GENERAL.RED" style="margin: auto" />
				</div>
			</div>
		</va-modal>
		<!-- END CLOSE ALERT-->

		<!-- START ERROR CLOSE ALERT-->
		<va-modal v-model="isState_logging_out_error" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12">
					<div class="text-center font-weight-bold">Ocurrió un error al cerrar sesión</div>
					<br />
					<button class="btn btn-primary" type="button" @click="sessionMachine.send('RETRY_LOGOUT')">Reintentar</button>
					&nbsp;&nbsp;
					<button class="btn btn-default" type="button" @click="sessionMachine.send('CANCEL_LOGOUT')">Cancelar</button>
				</div>
			</div>
		</va-modal>
		<!-- END ERROR CLOSE ALERT-->

		<!-- START REFRESH TOKEN ALERT-->
		<va-modal v-model="sessionCloseByIdleAlert" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12">
					<div class="text-center bold">
						<vue-countdown v-slot="{ minutes, seconds }" :time="idleSessionRemaningSeconds * 1000" :emit-events="false">
							<span class="bold">
								La sesión se cerrará de manera segura en <br />
								<div class="fs-xl red mt20">{{ minutes < 10 ? '0' + minutes : minutes }}:{{ seconds < 10 ? '0' + seconds : seconds }}</div>
							</span>
						</vue-countdown>
						<br />
						¿Desea continuar con la sesión?
					</div>
					<br />
					<div v-if="!renewAccessTokenInProgress" class="text-center">
						<button class="btn btn-primary" type="button" @click="idleSessionRemaningSeconds = 0">Continuar</button>
						&nbsp;&nbsp;
						<button class="btn btn-default" type="button" @click="onIdle">Cerrar Sesión</button>
					</div>
					<div v-else class="text-center">
						<va-progress-circle indeterminate :color="useConfigStore().getConfig().COLORS.GENERAL.RED" style="margin: auto" />
					</div>
				</div>
			</div>
		</va-modal>

		<!-- UNAUTHENTICATED IDLE || AUTHENTICATED SELECTED ROLE-->
		<div v-if="isState_unauthenticated_idle || isState_authenticated_selected_role_idle" id="Session" class="container100p">
			<ValidarRolRuta :rol="routeData.rol" :ruta="routeData.ruta" :grupo="routeData.grupo" :debug="false" :trace="false" class="container100p">
				<template #permitido>
					<v-idle
						v-if="isState_authenticated_selected_role_idle"
						:reminders="[useConfigStore().getEnvironmentConfig().SESSION.ALERT_IDLE_TIME_SECONDS]"
						:duration="useConfigStore().getEnvironmentConfig().SESSION.MAX_IDLE_TIME_SECONDS"
						class="hide"
						@idle="onIdle"
						@remind="onRemind"
					/>
					<router-view />
				</template>
				<template #denegado>
					<div class="containerHD portalLayout mt50 fc">
						<Unauthorized />
					</div>
				</template>
				<template #redirigir>
					<Redirigir :rol="routeData.rol" :grupo="routeData.grupo" />
				</template>
				<template #inexistente>
					<div class="containerHD portalLayout mt50 fc">
						<NotFound />
					</div>
				</template>
			</ValidarRolRuta>
		</div>
	</div>
</template>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="css">
.va-modal__inner {
	overflow: auto;
	display: flex;
	position: relative;
	flex-flow: column;
	padding: var(--va-modal-padding);
	min-width: 500px !important;
	margin: auto;
}
</style>
