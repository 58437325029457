/**
 * @description Módulo para la extracción de datos persistidos en las evaluaciones de dictaminadores a la información de evaluación en el contexto de homoclave
 * Es importante comprender el Módulo "Datos" primero para entender la estructura de los datos
 */
import { IHomoclaveContext, IHomoclaveRegistryModalidadConfiguracionEvaluacion, IComentarioCampo, IDatosFormulario, EEvaluacionValor } from 'cofepris-typesafe/Types/Homoclave'
import { EDatosFase, EDatosNodo, getDatosFormatosFromContextoByFase, getDatosSeccionesFromFormato, getDatosFormulariosFromSeccion, IDatoValorNormalizado } from './Datos'
import { EFirmaDelimitador, wrapValor } from './Firma'

export const EvaluacionConfiguracionDefault: IHomoclaveRegistryModalidadConfiguracionEvaluacion = {
	permitirOk: true,
	permitirWarning: true,
	permitirError: false,
	permitirObservaciones: true,
	longitudMaximaObservaciones: 100
}

export enum EEvaluacionValores {
	OK = 'OK',
	WARNING = 'WARNING',
	ERROR = 'ERROR',
	UNDEFINED = 'UNDEFINED'
}

export enum EEvaluacionIconos {
	OK = 'check',
	WARNING = 'warning',
	ERROR = 'close',
	OBSERVACIONES = 'comment'
}

export const EvaluacionColores = {
	OK_ACTIVO: '#81bc6b',
	OK_INACTIVO: '#81bc6b22',
	WARNING_ACTIVO: '#f7b531',
	WARNING_INACTIVO: '#f7b53122',
	ERROR_ACTIVO: '#DA3A42',
	ERROR_INACTIVO: '#DA3A4222',
	BOTON_GUARDAR: '#007bff'
}

export interface IEvaluacion {
	[key: string]: IEvaluacionItem
}

export interface IEvaluacionItem extends IComentarioCampo {
	idFormato: string
	idSeccion: string
	idFormulario: string
	idCampo: string
	guardando: boolean
	errorGuardando: boolean
}

export const getEvaluacionConfiguracionDefault = (): IHomoclaveRegistryModalidadConfiguracionEvaluacion => JSON.parse(JSON.stringify({ ...EvaluacionConfiguracionDefault }))

/**
 * @description Obtiene un objeto map de IEvaluacionItem con su idCampo como llave
 */
export const getEvaluacionMapping = (datos: IEvaluacionItem[]): IEvaluacion => {
	const response: IEvaluacion = {}
	try {
		datos.forEach(dato => {
			response[dato.idCampo] = dato
		})
	} catch (e) {
		console.error('Error al obtener mapping de evaluaciones ', datos, e)
	}
	return response
}

/**
 * @description Extrae los datos persistido en el contexto de la homoclave de una fase específica
 */
export const getEvaluacionFromContextoByFase = (context: IHomoclaveContext, fase: EDatosFase, nodoDatos: EDatosNodo = EDatosNodo.COMENTARIOS, indice?: number | undefined): IEvaluacionItem[] => {
	const response: IEvaluacionItem[] = []
	try {
		const formatos = getDatosFormatosFromContextoByFase(context, fase, nodoDatos, indice)
		formatos.forEach(formato => {
			const secciones = getDatosSeccionesFromFormato(formato)
			secciones.forEach(seccion => {
				const formularios = getDatosFormulariosFromSeccion(seccion)
				formularios.forEach(formulario => {
					const campos = getEvaluacionCamposFromFormulario(formulario)
					campos.forEach(campo => {
						const evaluacion: IEvaluacionItem = {
							idFormato: formato.idFormato as string,
							idSeccion: seccion.idSeccion,
							idFormulario: formulario.idFormulario,
							idCampo: campo.idCampo,
							evaluacion: campo.evaluacion ?? undefined,
							observaciones: campo.observaciones ?? undefined,
							fechaCreacion: campo.fechaCreacion ?? undefined,
							fechaActualizacion: campo.fechaActualizacion ?? undefined,
							guardando: false,
							errorGuardando: false
						}
						response.push(evaluacion)
					})
				})
			})
		})
	} catch (e) {
		console.error('Error al obtener evaluaciones desde contexto ', e)
	}
	return response
}

/**
 * @description Extrae los campos evaluados de un formulario
 */
export const getEvaluacionCamposFromFormulario = (formulario: IDatosFormulario): IComentarioCampo[] => {
	const response: IComentarioCampo[] = []
	try {
		if (formulario.campos) {
			const campos: IComentarioCampo[] = formulario.campos as unknown as IComentarioCampo[]
			response.push(...campos)
		}
	} catch (e) {
		console.error('Error al obtener evaluaciones de campos desde formuario ' + formulario.idFormulario, e)
	}
	return response
}

export function getIndiceUltimaEvaluacionFromContexto(context: IHomoclaveContext): number | undefined {
	let response = undefined
	try {
		if (context.metadatos != undefined) {
			if (context.metadatos.contadorEvaluaciones != undefined) {
				if (context.metadatos.contadorEvaluaciones >= 0) {
					if (context.evaluacion != undefined) {
						let indexEvaluacion = context.metadatos.contadorEvaluaciones - 1
						if (indexEvaluacion < 0) {
							indexEvaluacion = 0
						}
						response = indexEvaluacion
					}
				}
			}
		}
	} catch (e) {
		console.error('Error al obtener indice de ultima evaluacion ', e)
	}
	return response
}

/**
 * @description Extrae el valor normalizado de un Comentario de evaluación
 */
export function getEvaluacionValorNormalizadoFromComentario(comentario: IComentarioCampo) {
	const response: IDatoValorNormalizado = {
		valorNormalizado: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		error: undefined
	}
	try {
		if (comentario.evaluacion != undefined) {
			let valor: string = comentario.evaluacion as string
			if (comentario.observaciones) {
				valor = valor + '_' + comentario.observaciones
			}
			response.valorNormalizado = wrapValor(valor)
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado de evaluacion ', comentario, e)
	}
	return response
}

/**
 * @description Responde con boleano si un valor concreto de evaluación coincide con un conjunto de valores (incluido el undefined)
 */
export function evaluacionMatchValores(valor: EEvaluacionValor | undefined, valores: EEvaluacionValores[]): boolean {
	let response = false
	if (valor == undefined && valores.includes(EEvaluacionValores.UNDEFINED)) {
		response = true
	} else if (valor == EEvaluacionValor.OK && valores.includes(EEvaluacionValores.OK)) {
		response = true
	} else if (valor == EEvaluacionValor.WARNING && valores.includes(EEvaluacionValores.WARNING)) {
		response = true
	} else if (valor == EEvaluacionValor.ERROR && valores.includes(EEvaluacionValores.ERROR)) {
		response = true
	}
	return response
}
