/**
 * @name MedicamentoDictionaries
 * @author @MrCalamitus
 * @version 0.0.4
 *
 * @description Diccionarios de las enumeraciones de un medicamento para el Activo de información de un insumo de salud IInsumoSalud["medicamento"]
 *
 * @changelog 0.0.1 - 12/sep/2024 - Se agrega propiedad CMedicamentoFormaFarmaceutica
 * @changelog 0.0.3 - 21/ago/2024 - Se modifica CMedicamentoFormaFarmaceutica separando TABLETA y COMPRIMIDO y se freezean todos los diccionarios
 */

import {
	EMedicamentoAnexoTipo,
	EMedicamentoClaseLGS226,
	EMedicamentoClaseOrigenBiologicoLGS229,
	EMedicamentoConsideracionUso,
	EMedicamentoFormaFarmaceutica,
	EMedicamentoFormulaTipo,
	EMedicamentoTipo,
	EMedicamentoGrupoCoordinacion,
	EMedicamentoGrupoPago,
	EMedicamentoTipoAlopatico,
	EMedicamentoViaAdministracion,
	EMedicamentoFormulaProporcion
} from './Medicamento'

/**
 * @name CMedicamentoTipo
 */
export const CMedicamentoTipo: { [key in EMedicamentoTipo]: string } = Object.freeze({
	VACUNA: 'VACUNA', //Medicamento biológico o biotecnológico que estimula el sistema inmunológico para prevenir enfermedades
	HEMODERIVADO: 'HEMODERIVADO', //Medicamento biológico o biotecnológico derivado de la sangre
	FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA: 'FÓRMULA DE ALIMENTACIÓN ENTERAL ESPECIALIZADA', //Medicamento especializado para alimentación por sonda
	HERBOLARIO: 'HERBOLARIO', //Preparado de plantas medicinales, o sus partes, individuales o combinadas y sus derivados, presentado en forma farmacéutica, al cual se le atribuye por conocimiento popular o tradicional, el alivio para algunos síntomas participantes o aislados de una enfermedad
	HOMEOPATICO: 'HOMEOPÁTICO', //Medicamento a base de sustancias naturales diluidas
	VITAMINICO: 'VITAMÍNICO', //Producto que en su composición contiene únicamente vitaminas o minerales indicados para prevenir o tratar padecimientos por insuficiencia
	BIOLOGICO: 'BIOLÓGICO', //Medicamento biológico
	BIOTECNOLOGICO: 'BIOTECNOLÓGICO', //Los biofármacos y los biomedicamentos. Biofármaco es toda substancia que haya sido producida por biotecnología molecular, que tenga actividad farmacológica, que se identifique por sus propiedades físicas, químicas y biológicas, que reúna condiciones para ser empleada como principio activo de un medicamento o ingrediente de un medicamento. Biomedicamento es toda substancia que haya sido producida por biotecnología molecular, que tenga efecto terapéutico, preventivo o rehabilitatorio, que se presente en forma farmacéutica, que se identifique como tal por su actividad farmacológica y propiedades físicas, químicas y biológicas.
	HUERFANO: 'HUÉRFANO', //Medicamento para la atención a enfermedades raras
	FARMOQUIMICO: 'FARMOQUÍMICO' //Medicamento sintético
})

/**
 * @name CMedicamentoTipoAlopatico
 */
export const CMedicamentoTipoAlopatico: { [key in EMedicamentoTipoAlopatico]: string } = Object.freeze({
	VACUNA: 'VACUNA', //Medicamento biológico o biotecnológico que estimula el sistema inmunológico para prevenir enfermedades
	GENERICO: 'GENÉRICO', //Medicamento que contiene el mismo principio activo que un medicamento de marca, pero no está protegido por una patente
	BIOLOGICO_MOLECULA_NUEVA: 'BIOLÓGICO MOLECULA NUEVA', //Medicamento biológico con molécula nueva
	BIOLOGICO_BIOCOMPARABLE: 'BIOLÓGICO BIOCOMPARABLE', //Medicamento biológico con molécula similar a un medicamento biológico de referencia
	BIOTECNOLOGICO_INNOVADOR: 'BIOTECNOLÓGICO INNOVADOR', //Medicamento biotecnológico innovador
	BIOTECNOLOGICO_BIOCOMPARABLE: 'BIOTECNOLÓGICO BIOCOMPARABLE', //Medicamento biotecnológico con molécula similar a un medicamento biotecnológico de referencia
	FARMOQUIMICO_MOLECULA_NUEVA: 'FARMOQUÍMICO MOLÉCULA NUEVA' //Medicamento farmoquímico con molécula nueva
})

/**
 * @name CMedicamentoGrupoCoordinacion
 */
export const CMedicamentoGrupoCoordinacion: { [key in EMedicamentoGrupoCoordinacion]: string } = Object.freeze({
	FQ: 'FARMOQUÍMICOS', //Farmoquímico
	BBV: 'BIOLÓGICOS, BIOTECNOLÓGICOS Y VACUNAS', //Biológico Biotecnológico Vacuna
	HHV: 'HOMEOPÁTICOS, HERBOLARIOS Y VITAMÍNICOS', //Homeopáticos, Herbolarios y Vitamínicos
	FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA: 'FORMULAS DE ALIMENTACIÓN ENTERAL ESPECIALIZADA', //Fórmula de alimentación enteral especializada
	HUERFANO: 'HUÉRFANOS' //Medicamento huérfano
})

/**
 * @name EMedicamentoGrupoPago
 */
export const CMedicamentoGrupoPago: { [key in EMedicamentoGrupoPago]: string } = Object.freeze({
	MAXIMO: 'PAGO MÁXIMO', //Grupo de pago máximo
	GENERICO_BIOCOMPARABLE: 'PAGO GENÉRICO BIOCOMPARABLE', //Grupo de pago para genéricos biocomparables
	HHV: 'PAGO HOMEOPÁTICOS, HERBOLARIOS Y VITAMÍNICOS', //Grupo de pago para homeopáticos, herbolarios y vitamínicos
	HUERFANO: 'PAGO HUÉRFANO' //Grupo de pago para medicamentos huérfanos
})

/**
 * @name CMedicamentoClaseLGS226
 */
export const CMedicamentoClaseLGS226: { [key in EMedicamentoClaseLGS226]: string } = Object.freeze({
	I: 'I', //Medicamentos que sólo pueden adquirirse con receta o permiso especial, expedido por la Secretaría de Salud
	II: 'II', //Medicamentos que sólo pueden adquirirse con receta (receta retenida, registrada y con vigencia de 30 días), máximo 2 presentaciones por receta
	III: 'III', //Medicamentos que sólo pueden adquirirse con receta o autorización especial (receta sellada, registrada y retenida en el tercer surtido), máximo 3 surtidos por receta
	IV: 'IV', //Medicamentos que sólo pueden adquirirse con receta sin límite de surtido
	V: 'V', //Medicamentos no requieren receta para su adquisición en farmacias
	VI: 'VI' //Medicamentos no requieren receta para su adquisición en otros establecimiento que no sean farmacias
})

/**
 * @name CMedicamentoFormaFarmaceutica
 */
export const CMedicamentoFormaFarmaceutica: { [key in EMedicamentoFormaFarmaceutica]: string } = Object.freeze({
	AEROSOL: 'AEROSOL',
	CAPSULA: 'CÁPSULA',
	COLIRIO: 'COLIRIO',
	COMPRIMIDO: 'COMPRIMIDO',
	CREMA: 'CREMA',
	ELIXIR: 'ELIXIR',
	EMULSION: 'EMULSIÓN',
	ESPUMA: 'ESPUMA',
	GAS_MEDICINAL: 'GAS MEDICINAL',
	GEL: 'GEL',
	GLOBULO: 'GLÓBULO',
	GOMA: 'GOMA',
	GRANULADO: 'GRANULADO',
	IMPLANTE: 'IMPLANTE',
	JABON: 'JABÓN',
	JALEA: 'JALEA',
	JARABE: 'JARABE',
	LAMINILLA: 'LAMINILLA',
	LINIMENTO: 'LINIMENTO',
	LOCION: 'LOCIÓN',
	OBLEA: 'OBLEA',
	OVULO: 'ÓVULO',
	POMADA: 'POMADA',
	PARCHE: 'PARCHE',
	PASTA: 'PASTA',
	PASTILLA: 'PASTILLA',
	POLVO: 'POLVO',
	SISTEMA_LIBERACION: 'SISTEMA DE LIBERACIÓN',
	SOLUCION: 'SOLUCIÓN',
	SOLUCION_HOMEOPATICA: 'SOLUCIÓN HOMEOPÁTICA',
	SUPOSITORIO: 'SUPOSITORIO',
	SUSPENSION: 'SUSPENSIÓN',
	TABLETA: 'TABLETA',
	UNGUENTO: 'UNGÜENTO',
	OTRO: 'OTRO'
})

/**
 * @name CMedicamentoViaAdministracion
 */
export const CMedicamentoViaAdministracion: { [key in EMedicamentoViaAdministracion]: string } = Object.freeze({
	BUCAL: 'BUCAL',
	CUTANEA: 'CUTÁNEA',
	ENDOTRAQUEAL: 'ENDOTRAQUEAL',
	EPILESONAL: 'EPILESONAL',
	EPIDURAL: 'EPIDURAL',
	INHALACION: 'INHALACIÓN',
	INTRAARTICULAR: 'INTRAARTICULAR',
	INTRALESIONAL: 'INTRALESIONAL',
	INTRAMUSCULAR: 'INTRAMUSCULAR',
	INTRAOCULAR: 'INTRAOCULAR',
	INTRAPERITONEAL: 'INTRAPERITONEAL',
	INTRATECAL: 'INTRATECAL',
	INTRAUTERINA: 'INTRAUTERINA',
	INTRAVENOSA: 'INTRAVENOSA',
	NASAL: 'NASAL',
	OFTALMICA: 'OFTALMICA',
	ORAL: 'ORAL',
	OTICA: 'ÓTICA',
	RECTAL: 'RECTAL',
	SUBCUTANEA: 'SUBCUTÁNEA',
	SUBLINGUAL: 'SUBLINGUAL',
	TOPICA: 'TOPICA',
	TRANSDERMICA: 'TRANSDERMICA',
	TRONCULAR: 'TRONCULAR',
	URETAL: 'URETAL',
	VAGINAL: 'VAGINAL'
})

/**
 * @name CMedicamentoConsideracionUso
 */
export const CMedicamentoConsideracionUso: { [key in EMedicamentoConsideracionUso]: string } = Object.freeze({
	DISPERSABLE: 'DISPERSABLE',
	EFERVESCENTE: 'EFERVESCENTE',
	INYECTABLE: 'INYECTABLE',
	LIBERACION_PROLONGADA: 'LIBERACIÓN PROLONGADA',
	LIBERACION_RETARDADA: 'LIBERACIÓN RETARDADA',
	MASTICABLE: 'MASTICABLE',
	ORODISPERSABLE: 'ORODISPERSABLE',
	DIALISIS_PERITONEAL: 'DIÁLISIS PERITONEAL',
	ENEMA: 'ENEMA',
	INHALACION: 'INHALACIÓN',
	IRRIGACION: 'IRRIGACIÓN',
	NEBULIZACION: 'NEBULIZACIÓN',
	SOLUCION: 'SOLUCIÓN',
	SUSPENSION: 'SUSPENSIÓN'
})

/**
 * @name CMedicamentoFormulaTipo
 */
export const CMedicamentoFormulaTipo: { [key in EMedicamentoFormulaTipo]: string } = Object.freeze({
	MEDICAMENTO: 'MEDICAMENTO',
	DILUYENTE: 'DILUYENTE'
})

/**
 * @name CMedicamentoAnexoTipo
 */
export const CMedicamentoAnexoTipo: { [key in EMedicamentoAnexoTipo]: string } = Object.freeze({
	INSTRUCCIONES_PRESCRIBIR: 'INSTRUCCIONES PARA PRESCRIBIR',
	MARBETES: 'MARBETES',
	ETIQUETAS: 'ETIQUETAS'
})

/**
 * @name CMedicamentoClaseOrigenBiologicoLGS229

 */
export const CMedicamentoClaseOrigenBiologicoLGS229: { [key in EMedicamentoClaseOrigenBiologicoLGS229]: string } = Object.freeze({
	I: 'I', //Toxoides, vacunas y preparaciones bacterianas de uso parenteral
	II: 'II', //Vacunas virales de uso oral o parenteral
	III: 'III', //Sueros y antitoxinas de origen animal
	IV: 'IV', //Hemoderivados
	V: 'V', //Vacunas y preparaciones microbianas de uso oral
	VI: 'VI', //Materiales biológicos para diagnóstico que se administran al IMedicamentoPresentacionDiluyente
	VII: 'VII', //Antibióticos
	VIII: 'VIII', //Hormonas macromoelculares y enzimas
	IX: 'IX' //Las demás que determine la Secretaría de Salud
})

export const CMedicamentoFormulaProporcion: { [key in EMedicamentoFormulaProporcion]: string } = Object.freeze({
	[EMedicamentoFormulaProporcion.CADA_ML_CONTIENE]: 'Cada mL contiene:',
	[EMedicamentoFormulaProporcion.CADA_100_ML_CONTIENEN]: 'Cada 100 mL contienen:',
	[EMedicamentoFormulaProporcion.CADA_G_CONTIENE]: 'Cada g contiene:',
	[EMedicamentoFormulaProporcion.CADA_100_G_CONTIENEN]: 'Cada 100 g contienen:',
	[EMedicamentoFormulaProporcion.CADA_AMPOLLETA_CON_DILUYENTE_CONTIENE]: 'Cada ampolleta con diluyente contiene:',
	[EMedicamentoFormulaProporcion.CADA_CAPSULA_CONTIENE]: 'Cada cápsula contiene:',
	[EMedicamentoFormulaProporcion.CADA_CARTUCHO_CONTIENE]: 'Cada cartucho contiene:',
	[EMedicamentoFormulaProporcion.CADA_CILINDRO_DE_ACERO_CONTIENE]: 'Cada cilindro de acero contiene:',
	[EMedicamentoFormulaProporcion.CADA_COMPRIMIDO_CONTIENE]: 'Cada comprimido contiene:',
	[EMedicamentoFormulaProporcion.EL_DISPOSITIVO_INHALADOR_CONTIENE]: 'El dispositivo inhalador contiene:',
	[EMedicamentoFormulaProporcion.CADA_EMPLASTO_CONTIENE]: 'Cada emplasto contiene:',
	[EMedicamentoFormulaProporcion.CADA_ENVASE_CONTIENE]: 'Cada envase contiene:',
	[EMedicamentoFormulaProporcion.CADA_FRASCO_AMPULA_CONTIENE]: 'Cada frasco ámpula contiene:',
	[EMedicamentoFormulaProporcion.CADA_FRASCO_AMPULA_CON_DILUYENTE_CONTIENE]: 'Cada frasco ámpula con diluyente contiene:',
	[EMedicamentoFormulaProporcion.CADA_FRASCO_AMPULA_CON_LIOFILIZADO_CONTIENE]: 'Cada frasco ámpula con liofilizado contiene:',
	[EMedicamentoFormulaProporcion.CADA_FRASCO_CON_LIOFILIZADO_CONTIENE]: 'Cada frasco con liofilizado contiene:',
	[EMedicamentoFormulaProporcion.CADA_FRASCO_CON_POLVO_CONTIENE]: 'Cada frasco con polvo contiene:',
	[EMedicamentoFormulaProporcion.CADA_FRASCO_NEBULIZADOR_CONTIENE]: 'Cada frasco nebulizador contiene:',
	[EMedicamentoFormulaProporcion.CADA_GLOBULO_CONTIENE]: 'Cada glóbulo contiene:',
	[EMedicamentoFormulaProporcion.CADA_GLOBULO_HOMEOPATICO_CONTIENE]: 'Cada glóbulo homeopático contiene:',
	[EMedicamentoFormulaProporcion.CADA_GOMA_CONTIENE]: 'Cada goma contiene:',
	[EMedicamentoFormulaProporcion.CADA_GRAGEA_CONTIENE]: 'Cada gragea contiene:',
	[EMedicamentoFormulaProporcion.CADA_GRAGEA_HOMEOPATICA_CONTIENE]: 'Cada gragea homeopática contiene:',
	[EMedicamentoFormulaProporcion.CADA_IMPLANTE_CONTIENE]: 'Cada implante contiene:',
	[EMedicamentoFormulaProporcion.CADA_JABON_CONTIENE]: 'Cada jabón contiene:',
	[EMedicamentoFormulaProporcion.CADA_LAMINILLA_CONTIENE]: 'Cada laminilla contiene:',
	[EMedicamentoFormulaProporcion.CADA_OBLEA_CONTIENE]: 'Cada oblea contiene:',
	[EMedicamentoFormulaProporcion.CADA_OVULO_CONTIENE]: 'Cada óvulo contiene:',
	[EMedicamentoFormulaProporcion.CADA_PARCHE_CONTIENE]: 'Cada parche contiene:',
	[EMedicamentoFormulaProporcion.CADA_PASTILLA_CONTIENE]: 'Cada pastilla contiene:',
	[EMedicamentoFormulaProporcion.CADA_PASTILLA_HOMEOPATICA_CONTIENE]: 'Cada pastilla homeopática contiene:',
	[EMedicamentoFormulaProporcion.CADA_PERLA_CONTIENE]: 'Cada perla contiene:',
	[EMedicamentoFormulaProporcion.CADA_SISTEMA_DE_LIBERACION_CONTIENE]: 'Cada sistema de liberación contiene:',
	[EMedicamentoFormulaProporcion.CADA_SOBRE_CONTIENE]: 'Cada sobre contiene:',
	[EMedicamentoFormulaProporcion.CADA_SUPOSITORIO_CONTIENE]: 'Cada supositorio contiene:',
	[EMedicamentoFormulaProporcion.CADA_TABLETA_CONTIENE]: 'Cada tableta contiene:',
	[EMedicamentoFormulaProporcion.CADA_TABLETA_HOMEOPATICA_CONTIENE]: 'Cada tableta homeopática contiene:',
	[EMedicamentoFormulaProporcion.CADA_TROCISCO_CONTIENE]: 'Cada trocisco contiene:',
	[EMedicamentoFormulaProporcion.CADA_TROCISCO_HOMEOPATICO_CONTIENE]: 'Cada trocisco homeopático contiene:',
	[EMedicamentoFormulaProporcion.CADA_DOSIS_CONTIENE]: 'Cada dosis contiene:',
	[EMedicamentoFormulaProporcion.EL_FRASCO_AMPULA_CON_LIOFILIZADO_CONTIENE]: 'El frasco ámpula con liofilizado contiene:',
	[EMedicamentoFormulaProporcion.EL_FRASCO_AMPULA_CON_DILUYENTE_CONTIENE]: 'El frasco ámpula con diluyente contiene:',
	[EMedicamentoFormulaProporcion.LA_AMPOLLETA_CONTIENE]: 'La ampolleta contiene:',
	[EMedicamentoFormulaProporcion.LA_AMPOLLETA_CON_DILUYENTE_CONTIENE]: 'La ampolleta con diluyente contiene:'
})
