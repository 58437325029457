import { computed, WritableComputedRef } from 'vue'
import { defineVuesticConfig, useColors } from 'vuestic-ui'

export default class VuesticTheme {
	_primary: WritableComputedRef<string>
	public static get primary() {
		return this.Instance._primary.value
	}

	_textPrimary: WritableComputedRef<string>
	public static get textPrimary() {
		try {
			return this.Instance._textPrimary.value
		} catch {
			return this.getConfig().colors?.variables?.textPrimary
		}
	}

	constructor() {
		const { presets, setColors, getColor, getColors } = useColors()
		this._primary = computed({
			get() {
				return getColors().primary
			},
			set(value) {
				setColors({ primary: value })
			}
		})
		this._textPrimary = computed({
			get() {
				return getColors().textPrimary
			},
			set(value) {
				setColors({ textPrimary: value })
			}
		})
	}

	private static _Instance: VuesticTheme

	public static get Instance() {
		return this._Instance || (this._Instance = new VuesticTheme())
	}

	public static applyTheme() {
		const config = this.getConfig()
		this.Instance._primary.value = config!.colors!.variables!.primary!
	}

	public static getColors() {
		const { getColors } = useColors()
		return getColors()
	}

	public static getConfig() {
		const config = defineVuesticConfig({
			colors: {
				variables: {
					primary: '#9D2449',
					secondary: '#6F7271',
					info: '#d9edf7',
					success: '#dff0d8',
					warning: '#fcf8e3',
					danger: '#D0021B'
				}
			},
			breakpoint: {
				enabled: false
			}
		})
		return config
	}
}
