import { RolType } from './Roles'
import { CatalogGenericData } from './Catalogs'
import { IConfigValues, IGlobalConfig } from 'cofepris-typesafe/Types/ConfigsSystem'

export interface Usuario {
	uid: string //CURP DEL USUARIO extraído del certificado X.509 de e.firma
	uuid: string //UUID DEL USUARIO extraído del certificado X.509 de e.firma
	rfc: string //RFC DEL USUARIO extraído del certificado X.509 de e.firma
	nombre: string //NOMBRE DEL USUARIO extraído del certificado X.509 de e.firma
	email: string //EMAIL DEL USUARIO extraído del certificado X.509 de e.firma
	accesoPermitido: boolean //ESTATUS DEL USUARIO para obtener tokens de Identidad y de Acceso
	certificadoActivo: string //Número de serie del CERTIFICADO X.509 DE e.firma activo
	requiereAutenticador: boolean //Indica si el usuario requiere un autenticador para acceder al sistema
	autenticadorRegistrado: boolean //Indica si el usuario ya ha registrado un autenticador
	grupos?: string //CSV de los grupos a los que pertenece el usuario  // No requerido para los nodos del arbol de recursos en el grupo de los solicitantes
	unidadesOrganizativas?: string //CSV de las unidades organizativas a las que pertenece el usuario cuando es un usuario interno de la organización // No requerido para los nodos del arbol de recursos en el grupo de los solicitantes
	permisos?: Permiso[] //Arreglo de permisos que el usuario tiene asignados // No requerido para los nodos del arbol de recursos en el grupo de los solicitantes
	perteneceOrganizacion?: boolean //Indica si el usuario pertenece a la organización // No requerido para los nodos del arbol de recursos en el grupo de los solicitantes
}
export interface IUsuarioUnidadOrganizativa {
	id: string //id de la Unidad Organizativa
	uid: string //CURP DEL USUARIO extraído del certificado X.509 de e.firma
	uuid: string //UUID DEL USUARIO generado
	nombre: string //NOMBRE DEL USUARIO extraído del certificado X.509 de e.firma
}

export interface IUsuarioUUID {
	uuid: string //UUID DEL USUARIO
	nombre?: string //NOMBRE DEL USUARIO
	name?: string //NOMBRE DEL USUARIO (compatibilidad con el servicio)
}

export interface OrganizacionUsuarioAPIRequest {
	//Solo válido para el servicio de ORGANIZATION
	accion?:
		| 'LISTAR_USUARIOS'
		| 'OBTENER_USUARIO'
		| 'AGREGAR_USUARIO_ORGANIZACION'
		| 'RETIRAR_USUARIO_ORGANIZACION'
		| 'PERMITIR_ACCESO'
		| 'DENEGAR_ACCESO'
		| 'REMOVER_AUTENTICADOR'
		| 'ACTUALIZAR_UNIDADES_ORGANIZATIVAS'
		| 'CREAR_PERMISOS' //crear permiso de un usuario de la organización. Válido para SUPERADMINISTRADOR (con restricciones de que un usuario no puee editarse a si mismo)
		| 'REVOCAR_PERMISO' //remover permiso de un usuario de la organización. Válido para SUPERADMINISTRADOR (conrestricciones de que un usuario no puee editarse a si mismo)
		| 'REQUERIR_AUTENTICADOR' //Activar la solicitud de llave webAuthn a un usuario. Válido para los roles SUPERADMINISTRADOR y ADMINISTRADOR (conrestricciones de que un usuario no puee editarse a si mismo y un administrador no puede modificar a otro administrador)
		| 'NO_REQUERIR_AUTENTICADOR' //Desactivar la solicitud de llave webAuthn a un usuario. Válido para los roles SUPERADMINISTRADOR y ADMINISTRADOR (conrestricciones de que un usuario no puee editarse a si mismo y un administrador no puede modificar a otro administrador)
		| 'AGREGAR_USUARIO_SOLICITANTE' //agregar un usuario y permiso ak grupo solicitante, Válido para los roles SOLICITANTE_TITULAR_PERSONA_FISICA, SOLICITANTE_TITULAR_PERSONA_MORAL
		| 'LISTAR_USUARIOS_SOLICITANTE' //Mostrar los usuarios y permisos del grupo solicitante, Válido para los roles SOLICITANTE_TITULAR_PERSONA_FISICA, SOLICITANTE_TITULAR_PERSONA_MORAL, SOLICITANTE_AUTORIZADOR. SOLICITANTE_EDITOR y SOLICITANTE_VISOR

	uuid?: string //Requerido para acciones OBTENER_USUARIO, PERMITIR_ACCESO, DENEGAR_ACCESO,REMOVER_AUTENTICADOR ,ACTUALIZAR_UNIDADES_ORGANIZATIVAS, REVOCAR_PERMISO, REQURIR_AUTENTICADOR, NO_REQUERIR_AUTENTICADOR
	unidadesOrganizativas?: string //Solo válido para la acción de actualizar unidades ACTUALIZAR_UNIDADES_ORGANIZATIVAS
	certificadoB64?: string //Solo válido para la acción de CREAR_USUARIO y AGREGAR_USUARIO_SOLICITANTE
	permisoRequest?: OrganizacionPermisoRequest //Solo válido para la acción de CREAR_PERMISOS
	uuids?: string[] //Solo válido para la acción de GET_USERS_BY_UUIDS

	//Solo válido para el servicio de USERS_BY_ORGANIZATIVE_UNIT
	unidadOrganizativa?: string
	rol?: RolType //Solo válido para la acción de USERS_BY_ORGANIZATIVE_UNIT
}

export interface OrganizacionUsuarioAPIResponse {
	status?: 'OK' | 'ERROR' | 'NETWORK_ERROR' //esta valor es poblado por el manejador de llamadas a api de Frontend a la respuesta de API o error de Browser OK=200,ERROR=400, NETWORK_ERROR cualquier otro código HTTP. No se espera en el cuerpo de la respuesta del API
	message: string
	data: {
		usuario?: Usuario //válido para las acciónes de OBTENER_USUARIO y como respuesta de CREAR_USUARIO,PERMITIR_ACCESO, DENEGRAR_ACCESO, REMOVER_AUTENTICADOR, ACTUALIZAR_UNIDADES_ORGANIZATIVAS, CREAR_PERMISOS, REVOCAR_PERMISO, REQURIR_AUTENTICADOR, NO_REQUERIR_AUTENTICADOR
		usuarios?: Usuario[] //Solo válido para la acción de LISTAR_USUARIOS
		users?: IUsuarioUnidadOrganizativa[] | IUsuarioUUID[] //IUsuarioUnidadOrganizativa válido para el servicio USERS_BY_ORGANIZATIVE_UNIT, IUsuarioUUID válido para el servicio GET_USERS_BY_UUIDS
	}
}

export interface OrganizacionConfigAPIResponse {
	status?: 'OK' | 'ERROR' | 'NETWORK_ERROR' //esta valor es poblado por el manejador de llamadas a api de Frontend a la respuesta de API o error de Browser OK=200,ERROR=400, NETWORK_ERROR cualquier otro código HTTP. No se espera en el cuerpo de la respuesta del API
	message: string
	data: IConfigValues | IGlobalConfig
}

export type Operaciones = 'READ' | 'UPDATE' | 'DELETE' | 'CREATE'

export interface OrganizacionPermisoRequest {
	uuid?: string // Requerido para CREAR_PERMISOS y REVOCAR_PERMISO
	rol?: RolType // Solo válido para la acción CREAR_PERMISOS y AGREGAR_USUARIO_SOLICITANTE
	dias?: number // Solo válido para la acción CREAR_PERMISOS y AGREGAR_USUARIO_SOLICITANTE
	idPermiso?: string // Solo válido para la acción REVOCAR_PERMISO
	recursos?: { homoclave: string; modalidad: string }[] // Solo válido para la acción CREAR_PERMISOS
}

export interface Permiso {
	idPermiso: string
	uid?: string //No disponible en casos en los que se comprometa la identidad de los evaluadores, coordinadores o administradores hacia los solicitantes
	uuid: string
	grupo: string
	rol: RolType
	recurso: string
	inicio: number
	fin: number
	otorganteUid?: string //No disponible en casos en los que se comprometa la identidad de los evaluadores, coordinadores o administradores hacia los solicitantes
	otorganteUuid: string | null
	otorganteNombre?: string | null
	otorganteRol: RolType | null
	revocado: boolean | null
	revocanteUid?: string //No disponible en casos en los que se comprometa la identidad de los evaluadores, coordinadores o administradores hacia los solicitantes
	revocanteUuid: string | null
	revocanteRol: RolType | null
	fechaRevocacion: number | null
}

export interface IActor {
	idActor: string
	actor: string
}

export enum EActor {
	DICTAMINADOR_RESPONSABLE = 'DICTAMINADOR_RESPONSABLE',
	DICTAMINADOR_ESTADISTICO = 'DICTAMINADOR_ESTADISTICO',
	AUXILIAR_SOLO_LECTURA = 'AUXILIAR_SOLO_LECTURA',
	COORDINADOR_COMITE_MOLECULAS_NUEVAS = 'COORDINADOR_COMITE_MOLECULAS_NUEVAS'
}

export const Actores: Record<EActor, IActor> = {
	[EActor.DICTAMINADOR_RESPONSABLE]: {
		idActor: 'DICTAMINADOR_RESPONSABLE',
		actor: 'Dictaminador Responsable'
	},
	[EActor.DICTAMINADOR_ESTADISTICO]: {
		idActor: 'DICTAMINADOR_ESTADISTICO',
		actor: 'Dictaminador Estadístico'
	},
	[EActor.AUXILIAR_SOLO_LECTURA]: {
		idActor: 'AUXILIAR_SOLO_LECTURA',
		actor: 'Auxiliar Solo Lectura'
	},
	[EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]: {
		idActor: 'COORDINADOR_COMITE_MOLECULAS_NUEVAS',
		actor: 'Coordinador de Comité de Moléculas Nuevas'
	}
}

export interface UnidadOrganizativa {
	id: string
	unidadOrganizativa: string
	seleccionable?: boolean
	actores?: EActor[]
	hijos?: UnidadOrganizativa[]
}

export const ORGANIGRAMA: UnidadOrganizativa = {
	id: '/',
	unidadOrganizativa: '/',
	seleccionable: false,
	hijos: [
		{
			id: 'COFEPRIS',
			unidadOrganizativa: 'COFEPRIS',
			hijos: [
				{
					id: 'COFEPRIS_CSFS',
					unidadOrganizativa: 'Coordinación General del Sistema Federal Sanitario',
					seleccionable: false,
					hijos: [
						{
							id: 'COFEPRIS_CSFS_DESP',
							unidadOrganizativa: 'Dirección Ejecutiva de Sistemas y Procesos',
							seleccionable: true
						}
					]
				},
				{
					id: 'COFEPRIS_CAS',
					unidadOrganizativa: 'Comisión de Autorización Sanitaria',
					seleccionable: true,
					hijos: [
						{
							id: 'COFEPRIS_CAS_DEAPE',
							unidadOrganizativa: 'Dirección Ejecutiva de Autorización de Productos y Establecimientos',
							hijos: [
								{
									id: 'COFEPRIS_CAS_DEAPE_SEFM',
									unidadOrganizativa: 'Subdirección Ejecutiva de Fármacos y Medicamentos',
									hijos: [
										{
											id: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
											unidadOrganizativa: 'Ensayos Clínicos',
											seleccionable: true,
											actores: [EActor.DICTAMINADOR_RESPONSABLE, EActor.DICTAMINADOR_ESTADISTICO, EActor.AUXILIAR_SOLO_LECTURA, EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
										},
										{
											id: 'COFEPRIS_CAS_DEAPE_SEFM_GMA',
											unidadOrganizativa: 'Gerencia de Medicamentos Alopáticos',
											seleccionable: true
										},
										{
											id: 'COFEPRIS_CAS_DEAPE_SEFM_GMHHA',
											unidadOrganizativa: 'Gerencia de Medicamentos Herbolarios, Homeopáticos y Alternativos',
											seleccionable: true
										}
									]
								}
							]
						}
					]
				}
			]
		}
	]
}

export function getUnidadOrganizativaById(id: string, root: UnidadOrganizativa = ORGANIGRAMA): UnidadOrganizativa | null {
	const unidadOrganizativa = root.hijos?.find(uo => uo.id === id)
	if (unidadOrganizativa) {
		return unidadOrganizativa
	} else {
		if (root.hijos) {
			for (const uo of root.hijos) {
				const unidadOrganizativa = getUnidadOrganizativaById(id, uo)
				if (unidadOrganizativa) {
					return unidadOrganizativa
				}
			}
		}
	}
	return null
}

export function getDiccionarioActoresByUnidadOrganizativaId(id: string, root: UnidadOrganizativa = ORGANIGRAMA): CatalogGenericData[] {
	const diccionario: CatalogGenericData[] = []

	const unidadOrganizativa = getUnidadOrganizativaById(id, root)
	if (unidadOrganizativa) {
		unidadOrganizativa.actores?.forEach(actor => {
			diccionario.push({
				key: actor,
				value: Actores[actor] as any
			})
		})
	}
	return diccionario
}
