/**
 * @package ModificacionesMedicamentosFQ
 * @author @Jorel254
 * @version 0.0.2
 *
 * @description Registro de modificaciones a medicamentos Alopáticos, Homeopáticos, Herbolarios y Vitaminicos
 */
import { EModificacionesMedicamentoSeccionesOficio, EModificacionesMedicamentoEtiquetas, EModificacionesMedicamentoTipo, IModificacionMedicamento } from './IModificacionesMedicamentos'
import { EMedicamentoTipo } from '../InsumoSalud'
export const CModificacionesMedicamentosFQ: IModificacionMedicamento[] = [
	{
		numeral: '8.1.1',
		nombre: 'Cambio o actualización de la Unidad de Farmacovigilancia',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio o actualización de la Unidad de Farmacovigilancia',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.UNIDAD_FARMACOVIGILANCIA],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	},
	{
		numeral: '8.1.3',
		nombre:
			'Cambio o actualización de la razón social y/o domicilio del fabricante del fármaco, del fabricante que realiza los subprocesos al fármaco (micronización, mezcla, diluciones, entre otros), fabricante del diluyente, fabricante del medicamento y acondicionador',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio o actualización de la razón social y/o actualización del domicilio del fabricante del fármaco (nacional o extranjero)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO, EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion:
					'Cambio o actualización de la razón social y/o actualización del domicilio del fabricante que realiza los subprocesos al fármaco (micronización, mezcla, diluciones, entre otros) (nacional o extranjero)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO, EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Cambio o actualización de la razón social y/o actualización del domicilio del fabricante del medicamento (nacional o extranjero)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_MEDICAMENTO, EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['Si aplica']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: [' Únicamente en fracción: nombre y domicilio del laboratorio (si aplica)']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambio o actualización de la razón social y/o actualización del domicilio del fabricante del diluyente (nacional o extranjero)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_DILUYENTE, EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambio o actualización de la razón social y/o actualización del domicilio del acondicionador (nacional o extranjero)',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL
				],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	},
	{
		numeral: '8.1.5',
		nombre: 'Cambio, adición o eliminación del material de envase secundario',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio, adición o eliminación del material de envase secundario',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.PRESENTACION],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['Si aplica']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	},
	{
		numeral: '8.1.7',
		nombre: 'Cambio, adición o eliminación del licenciatario y/o acondicionador secundario y/o almacén y/o distribuidor del medicamento',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio, adición o eliminación del licenciatario y/o acondicionador secundario (nacional o extranjero) y/o almacén y/o distribuidor del medicamento',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.LICENCIATARIO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.ALMACEN,
					EModificacionesMedicamentoSeccionesOficio.DISTRIBUIDOR
				],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['Si aplica']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['Únicamente en fracción: nombre y domicilio del laboratorio (si aplica)']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	},
	{
		numeral: '8.1.8',
		nombre: 'Cambio, adición o eliminación del acondicionador secundario y/o almacén y/o distribuidor del medicamento',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio, adición o eliminación del acondicionador secundario y/o almacén y/o distribuidor de medicamentos que no requieran red o cadena de frío',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.DISTRIBUIDOR,
					EModificacionesMedicamentoSeccionesOficio.ALMACEN
				],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['Si aplica']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['Únicamente en fracción: nombre y domicilio del laboratorio (si aplica)']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	},
	{
		numeral: '8.3.1',
		nombre:
			'Modificación del fabricante del fármaco o inclusión de un fabricante, incluyendo al fabricante que realiza los subprocesos del fármaco (micronización, mezcla, diluciones, entre otros) y cambio en el proceso de manufactura del fármaco',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio del sitio de fabricación dentro del establecimiento previamente autorizado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Cambio del sitio de fabricación, sitio adyacente al establecimiento previamente autorizado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	},
	{
		numeral: '8.3.2',
		nombre: 'Modificación por cambio de aditivos sin cambios en la forma farmacéutica o fármaco(s)',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación parcial o total de un aditivo que afecta el color o sabor del medicamento',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FORMULA],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Cambio del ingrediente de la tinta para impresión sobre el medicamento por otro ingrediente aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FORMULA],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Cambio en aditivos, expresado en porcentaje (m/m) de la formulación total, no mayor a un total de 5.00%',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FORMULA],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambio en aditivos, expresado en porcentaje (m/m) de la formulación total (el efecto total del cambio de todos los aditivos no debe de ser más de un total de 10.00%)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FORMULA],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambio en las especificaciones y grado técnico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FORMULA],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	},
	{
		numeral: '8.3.5',
		nombre: 'Modificación en los procesos de fabricación del medicamento',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio de equipo',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Cambio en proceso',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Cambio a un equipo de diferente diseño y/o diferente principio de operación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambio en el tamaño de lote de más de 10 veces el tamaño del lote piloto o del lote utilizado para estudios clínicos',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	},
	{
		numeral: '8.3.6',
		nombre: 'Modificación por cambio en el envase primario',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio en el tamaño del envase primario previamente autorizado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.ENVASE_PRIMARIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Cambio en la composición y/o especificaciones del envase primario',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.ENVASE_PRIMARIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Cambio de un envase reusable a desechable sin cambios en el material de contacto con el producto (por ejemplo, cambio de una pluma reusable a una pluma desechable)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.PRESENTACION, EModificacionesMedicamentoSeccionesOficio.ENVASE_PRIMARIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['Si aplica']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Eliminación de un sistema contenedor-cierre',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO,
					EModificacionesMedicamentoSeccionesOficio.FABRICANTE_MEDICAMENTO,
					EModificacionesMedicamentoSeccionesOficio.FABRICANTE_DILUYENTE,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.PRESENTACION,
					EModificacionesMedicamentoSeccionesOficio.ENVASE_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL,
					EModificacionesMedicamentoSeccionesOficio.FORMULA
				],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['Si aplica']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.FARMOQUIMICO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
	}
]
