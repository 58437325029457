import { ERROR_CATEGORY, ERROR_LEVEL, ICustomError } from 'cofepris-typesafe/Types/Errors'

//ruta no encontrada
export default class ERROR_ROUTES_ROUTE_NOT_FOUND extends Error implements ICustomError {
	category = 'ROUTES' as ERROR_CATEGORY
	level = 'ERROR' as ERROR_LEVEL
	error = 'ERROR_ROUTES_ROUTE_NOT_FOUND'
	data = null
	constructor(message: string, data?: any) {
		super(message)
		this.data = data
	}

	public callback(): void {
		console.log('Procesando Error...', this.data)
		console.log('TODO:Notificar por alerta al sistema del problema detectado')
	}
}
