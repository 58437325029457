/**
 * @name CTDMedicamentosModulo5
 * @author @tirsomartinezreyes
 * @version 0.0.5
 *
 * @description Define la estructura del Módulo 5 (Clinical Study Reports) del CTD (Common Technical Document) para Registro de Medicamentos.
 */
import {
	ECTDCaracteresIdentificadoresReservados, //Enumeración que define los caracteres identificadores reservados en identificadores de nodos del CTD
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

import {
	ECTDMedicamentosInstrucciones //Enumeración que define las instrucciones comúnes para los nodos del CTD
} from './CTDMedicamentosInstrucciones'

/**
 * @name CTDMedicamentosModulo5
 */
export const CTDMedicamentosModulo5: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm5',
	nombre: 'm5',
	descripcion: 'Módulo 5: Información Clínica',
	hijos: [
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '5.1',
			nombre: '51-toc',
			descripcion: 'Tabla de Contenido del Módulo 5',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '5.2',
			nombre: '52-tab-list',
			descripcion: 'Listado tabular de todos los estudios clínicos',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '5.3',
			nombre: '53-clin-stud-rep',
			descripcion: 'Reporte de estudios clínicos',
			hijos: [
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '5.3.1',
					nombre: '531-rep-biopharm-stud',
					descripcion: 'Reporte de los estudios biofarmacéuticos',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.1.1',
							nombre: '5311-ba-stud-rep',
							descripcion: 'Reportes de los estudios de biodisponibilidad (BD)',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.1.2',
							nombre: '5312-compar-ba-be-stud-rep',
							descripcion: 'Reportes de estudios de biodisponibilidad (BD) comparativa y bioequivalencia (BE)',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.1.3',
							nombre: '5313-in-vitro-in-vivo-corr-stud-rep',
							descripcion: 'Reportes de estudios de correlación In vivo-In vitro',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.1.4',
							nombre: '5314-bioanalyt-analyt-met',
							descripcion: 'Reportes de los métodos bioanáliticos y analíticos para estudios en humanos',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '5.3.2',
					nombre: '532-rep-stud-pk-human-biomat',
					descripcion: 'Reporte de estudios relacionados a la farmacocinética usando biomateriales humanos',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.2.1',
							nombre: '5321-plasma-prot-bind-stud-rep',
							descripcion: 'Reportes de estudios de unión a proteínas plasmáticas',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.2.2',
							nombre: '5322-rep-hep-metab-interact-stud',
							descripcion: 'Reportes de los estudios de metabolismo hepático e interacción farmacológica',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.2.3',
							nombre: '5323-stud-other-human-biomat',
							descripcion: 'Reportes de los estudios que utilizan otros biomateriales humanos',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '5.3.3',
					nombre: '533-rep-human-pk-stud',
					descripcion: 'Reportes de estudios de farmacocinética (PK) en humanos',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.3.1',
							nombre: '5331-healthy-subj-pk-init-tol-stud-rep',
							descripcion: 'Reportes de estudios PK en sujetos sanos y estudios de tolerabilidad inicial',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.3.2',
							nombre: '5332-patient-pk-init-tol-stud-rep',
							descripcion: 'Reportes de estudios PK en pacientes y estudios de tolerabilidad inicial',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.3.3',
							nombre: '5333-intrin-factor-pk-stud-rep',
							descripcion: 'Reportes de estudios PK factor intrínseco',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.3.4',
							nombre: '5334-extrin-factor-pk-stud-rep',
							descripcion: 'Reportes de estudios PK factor extrínseco',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.3.5',
							nombre: '5335-popul-pk-stud-rep',
							descripcion: 'Reportes de estudios PK en la población',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '5.3.4',
					nombre: '534-rep-human-pd-stud',
					descripcion: 'Reportes de estudios de farmacodinamia (PD) en humanos',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.4.1',
							nombre: '5341-healthy-subj-pd-stud-rep',
							descripcion: 'Reportes de estudios de PD y PK/PD en sujetos sanos',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						},
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
							id: '5.3.4.2',
							nombre: '5342-patient-pd-stud-rep',
							descripcion: 'Reportes de los estudios PD y PK/PD en pacientes',
							instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
							documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '5.3.5',
					nombre: '535-rep-effic-safety-stud',
					descripcion: 'Reportes de estudios de eficacia y seguridad',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_INDICACION_TERAPEUTICA,
							nombre: 'indication-name',
							id: ECTDCaracteresIdentificadoresReservados.INDICACION_TERAPEUTICA,
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
									id: '5.3.5.1',
									nombre: '5351-stud-rep-contr',
									descripcion: 'Reportes de estudios clínicos controlados relacionados a la indicación terapéutica solicitada',
									instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
									documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
								},
								{
									tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
									id: '5.3.5.2',
									nombre: '5352-stud-rep-uncontr',
									descripcion: 'Reporte de estudios clínicos no controlados',
									instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
									documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
								},
								{
									tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
									id: '5.3.5.3',
									nombre: '5353-rep-analys-data-more-one-stud',
									descripcion: 'Reporte del análisis de datos para más de un estudio',
									instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
									documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
								},
								{
									tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
									id: '5.3.5.4',
									nombre: '5354-other-stud-rep',
									descripcion: 'Otros reportes de estudios clínicos',
									instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
									documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '5.3.6',
					nombre: '536-postmark-exp',
					descripcion: 'Reportes de experiencia post-comercialización',
					hijos: [
						{
							tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
							documentoFormato: [ECTDFormatoDocumento.PDF]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE,
					id: '5.3.7',
					nombre: '537-crf-ipl',
					descripcion: 'Formatos de reporte de casos y listados de pacientes individuales',
					instrucciones: ECTDMedicamentosInstrucciones.REPORTES_MODULO_5,
					documentoFormato: [ECTDFormatoDocumento.PDF, ECTDFormatoDocumento.FOLDER]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '5.4',
			nombre: '54-lit-ref',
			descripcion: 'Literatura de Referencia',
			instrucciones: ECTDMedicamentosInstrucciones.LITERATURA_REFERENCIA,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		}
	]
}
