import { AnyEventObject, stringSHA256, loadX509CertificateFromBase64String, RSASignContext } from '../bom'

const setDebug = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setDebug', event) : null
    let newContext = { ...context }
    newContext.debug = event.debug || event.data?.debug || false
    return newContext
}

const setMode = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setMode', event) : null
    let newContext = { ...context }
    newContext.mode = event.mode || event.data.mode || null
    return newContext
}

const setMessage = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setMessage', event) : null
    let newContext = { ...context }
    newContext.message = event.message || event.data?.message || null
    if (newContext.message) {
        newContext.messageSHA256 = stringSHA256(newContext.message) || null
    } else {
        newContext.messageSHA256 = null
    }
    return newContext
}

const setSignatureB64 = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setSignatureB64', event) : null
    let newContext = { ...context }
    newContext.signatureB64 = event.signatureB64 || event.data?.signatureB64 || null
    return newContext
}

const setValidSignature = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setValidSignature', event) : null
    let newContext = { ...context }
    newContext.validSignature = event.validSignature || event.data?.validSignature || null
    return newContext
}

const setCertificadoB64 = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setCertificadoB64', event) : null
    let newContext = { ...context }
    newContext.certificadoB64 = event.certificadoB64 || event.data?.certificadoB64 || null
    if (newContext.certificadoB64) {
        let certificadoX509 = loadX509CertificateFromBase64String(event.certificadoB64) || null
        if (certificadoX509) {
            certificadoX509.ans1Object = null
            certificadoX509.certificate = null
        }
        newContext.certificadoX509 = certificadoX509
    } else {
        newContext.certificadoX509 = null
    }
    return newContext
}

const setCertificadoErrorMessage = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setCertificadoErrorMessage', event) : null
    let newContext = { ...context }
    newContext.certificadoErrorMessage = event.certificadoErrorMessage || event.data?.certificadoErrorMessage || null
    return newContext
}

const setGetMessageError = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: getMessageError', event) : null
    let newContext = { ...context }
    newContext.getMessageError = event.getMessageError || event.data?.getMessageError || null
    return newContext
}

const setSignaturePreviewError = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setSignaturePreviewError', event) : null
    let newContext = { ...context }
    newContext.signaturePreviewError = event.signaturePreviewError || event.data?.signaturePreviewError || null
    return newContext
}

const setPkB64 = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setPkB64', event) : null
    let newContext = { ...context }
    newContext.pkB64 = event.pkB64 || event.data?.pkB64 || null
    return newContext
}

const setPkPass = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setPkPass', '********') : null
    let newContext = { ...context }
    newContext.pkPass = event.pkPass || event.data?.pkPass || null
    return newContext
}

const setJwt = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setJwt', event) : null
    let newContext = { ...context }
    newContext.jwt = event.jwt || event.data?.jwt || null
    return newContext
}

const setSignatureSendingError = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setSignatureSendingError', event) : null
    let newContext = { ...context }
    newContext.signatureSendingError = event.signatureSendingError || event.data?.signatureSendingError || null
    return newContext
}

const clearContext = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: setDebug', event) : null
    let newContext: RSASignContext = {
        debug: context.debug,
        mode: context.mode,
        message: context.message,
        certificadoB64: context.certificadoB64,
        messageSHA256: null,
        signatureB64: null,
        validSignature: null,
        certificadoX509: null,
        certificadoErrorMessage: null,
        getMessageError: null,
        signaturePreviewError: null,
        signatureSendingError: null,
        pkB64: null,
        pkPass: null,
        jwt: null
    }

    return newContext
}

const clearContextForLogin = function (context: RSASignContext, event: AnyEventObject): RSASignContext {
    context.debug ? console.log('Assignation: clearContextForLogin', event) : null
    let newContext = { ...context }
    newContext.message = null
    newContext.messageSHA256 = null
    newContext.signatureB64 = null
    newContext.validSignature = null
    newContext.certificadoB64 = null
    newContext.certificadoX509 = null
    newContext.certificadoErrorMessage = null
    newContext.getMessageError = null
    newContext.signaturePreviewError = null
    newContext.signatureSendingError = null
    newContext.pkB64 = null
    newContext.pkPass = null
    newContext.jwt = null
    return newContext
}

export default {
    clearContext,
    clearContextForLogin,
    setDebug,
    setMode,
    setMessage,
    setSignatureB64,
    setValidSignature,
    setCertificadoB64,
    setCertificadoErrorMessage,
    setGetMessageError,
    setSignaturePreviewError,
    setPkB64,
    setPkPass,
    setJwt,
    setSignatureSendingError
}
