/**
 * @name CTDDispositivosModulo1
 * @author @tirsomartinezreyes
 * @version 0.0.3
 *
 * @description Define la estructura del Módulo 1 (Regional Administrativo Legal)
 * del CTD (Common Technical Document) para Registro de Dispositivos.
 * @changelog - 0.0.3 - 09/sep/24 - Se corrige typo en 1.1.6
 * @changelog - 0.0.2 Se corrige typo en 1.3
 */

import {
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

/**
 * @name CTDDispositivosModulo1
 * @description Define la estructura del Módulo 1 del CTD (Common Technical Document) para Registro de Dispositivos.
 */
export const CTDDispositivosModulo1: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm1',
	nombre: 'm1',
	descripcion: 'Módulo 1: REGIONAL ADMINISTRATIVO - LEGAL',
	hijos: [
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.1',
			nombre: '11-solicitud-documentos',
			descripcion: 'Solicitud y documentos relacionados',
			hijos: [
				{
					id: '1.1.1',
					nombre: '111-tabla-contenido',
					descripcion: 'Tabla de contenido del expediente',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '1.1.2',
					nombre: '112-formato-autorizacion',
					descripcion: 'Formato de solicitud: Autorizaciones, Certificados y Visitas (FF-COFEPRIS-01)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '1.1.3',
					nombre: '113-formato-modificacion',
					descripcion: 'Formato de solicitud: Modificación a las condiciones del Registro Sanitario (FF-COFEPRIS-15)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '1.1.4',
					nombre: '114-formato-prorroga',
					descripcion: 'Formato de solicitud:  Prórroga de Registro Sanitario (FF-COFEPRIS-14)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '1.1.5',
					nombre: '115-formato-aviso',
					descripcion: 'Formato de solicitud: Aviso de funcionamiento, de Responsable Sanitario y de Modificación o Baja (FF-COFEPRIS-02)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '1.1.6',
					nombre: '116-licencia-cnsns',
					descripcion: 'Licencia expedida por la Comisión Nacional de Seguridad Nuclear y Salvaguardias',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.2',
			nombre: '12-reportes-eficacia',
			descripcion: 'Reportes de estudios de evaluación (eficacia) emitidos por un laboratorio autorizado por la Secretaría de Salud',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.3',
			nombre: '13-cbpf-equiv',
			descripcion: 'Certificado de buenas prácticas de fabricación del (os) establecimiento (s) del fabricante (s) real (es) del (os) dispositivo médico o su documento equivalente',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.4',
			nombre: '14-clv-equiv',
			descripcion: 'Certificado de libre venta (CLV) o documento equivalente para fabricación extranjera.',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.5',
			nombre: '15-pago-derechos',
			descripcion: 'Comprobante del pago de derechos',
			hijos: [
				{
					id: '1.5.1',
					nombre: '151-comprobante-pago',
					descripcion: 'Comprobante de pago',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.6',
			nombre: '16-conformidad-y-doc',
			descripcion: 'Declaraciones de conformidad y documentos relacionados',
			hijos: [
				{
					id: '1.6.1',
					nombre: '161-cert-anali-prod-term',
					descripcion: 'Certificado analítico o certificado de producto terminado',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.7',
			nombre: '17-cartas-repre',
			descripcion: 'Carta(s) de representación',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.8',
			nombre: '18-otra-info-reg-admin',
			descripcion: 'Otra información Regional Administrativa',
			hijos: [
				{
					id: '1.8.1',
					descripcion: 'Carta de respuesta a Prevención',
					nombre: '181-carta-prevencion',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '1.8.2',
					descripcion: 'Cartas aclaratorias y/o escritos',
					nombre: '182-carta-aclar-escritos',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '1.8.3',
					descripcion: 'Declaración de aval de responsable sanitario ante la COFEPRIS',
					nombre: '183-decl-aval',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '1.8.4',
					descripcion: 'Documento que acredite personalidad jurídica o RUPA.',
					nombre: '184-acred-juridica',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '1.9',
			nombre: '19-info-adicional',
			descripcion: 'Información adicional',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		}
	]
}
