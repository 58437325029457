/**
 * @name CTDMedicamentosModulo4
 * @author @tirsomartinezreyes
 * @version 0.0.6
 *
 * @changelog 0.0.6 - 28/ago/24 - Se corrigen typos en 4.2.3.7.3
 * @changelog 0.0.5 - Corrección de typos en 4.2.3.7.3
 * @description Define la estructura del Módulo 4 (Nonclinical Study Reports) del CTD (Common Technical Document) para Registro de Medicamentos.
 */
import {
	//ECTDCaracteresIdentificadoresReservados, //Enumeración que define los caracteres identificadores reservados en identificadores de nodos del CTD
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

import {
	ECTDMedicamentosInstrucciones //Enumeración que define las instrucciones comúnes para los nodos del CTD
} from './CTDMedicamentosInstrucciones'

/**
 * @name CTDMedicamentosModulo4
 */
export const CTDMedicamentosModulo4: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm4',
	nombre: 'm4',
	descripcion: 'Módulo 4: Información Preclínica',
	hijos: [
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '4.1',
			nombre: '41-toc',
			descripcion: 'Tabla de Contenido del Módulo 4',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '4.2',
			nombre: '42-stud-rep',
			descripcion: 'Reportes de estudios',
			hijos: [
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '4.2.1',
					nombre: '421-pharmacol',
					descripcion: 'Farmacología',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.1.1',
							nombre: '4211-prim-pd',
							descripcion: 'Farmacodinamia primaria',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.1.2',
							nombre: '4212-sec-pd',
							descripcion: 'Farmacodinamia secundaria',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.1.3',
							nombre: '4213-safety-pharmacol',
							descripcion: 'Seguridad farmacológica',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.1.4',
							nombre: '4214-pd-drug-interact',
							descripcion: 'Interacciones farmacodinámicas',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '4.2.2',
					nombre: '422-pk',
					descripcion: 'Farmacocinética',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.2.1',
							nombre: '4221-analyt-met-val',
							descripcion: 'Métodos analíticos y reportes de validación (si están disponibles reportes separados)',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.2.2',
							nombre: '4222-absorp',
							descripcion: 'Absorción',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.2.3',
							nombre: '4223-distrib',
							descripcion: 'Distribución',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.2.4',
							nombre: '4224-metab',
							descripcion: 'Metabolismo',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.2.5',
							nombre: '4225-excr',
							descripcion: 'Excreción',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.2.6',
							nombre: '4226-pk-drug-interact',
							descripcion: 'Interacciones farmacocinéticas con fármacos (no clínicas)',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.2.7',
							nombre: '4227-other-pk-stud',
							descripcion: 'Otros estudios farmacocinéticos',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '4.2.3',
					nombre: '423-tox',
					descripcion: 'Toxicología',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.3.1',
							nombre: '4231-single-dose-tox',
							descripcion: 'Toxicidad a una sola dosis (ordenado por especie, por vía)',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.3.2',
							nombre: '4232-repeat-dose-tox',
							descripcion: 'Toxicidad a dosis repetidas (ordenado por especie, por ruta, por duración; incluidas las evaluaciones de la toxicocinética de soporte)',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.3.3',
							nombre: '4233-genotox',
							descripcion: 'Genotoxicidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.3.1',
									nombre: '42331-in-vitro',
									descripcion: 'In vitro',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.3.2',
									nombre: '42332-in-vivo',
									descripcion: 'In vivo (incluidas las evaluaciones de la toxicocinética de soporte)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.3.4',
							nombre: '4234-carcigen',
							descripcion: 'Carcinogenicidad (incluidas las evaluaciones de la toxicocinética de soporte)',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.4.1',
									nombre: '42341-lt-stud',
									descripcion:
										'Estudios a largo plazo (ordenado por especie; incluidos los estudios de determinación de rango que no es adecuado incluirlos en la toxicidad de dosis repetidas o farmacocinéticos)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.4.2',
									nombre: '42342-smt-stud',
									descripcion:
										'Estudios a corto o mediano plazo (incluidos los estudios de determinación de rango que no es adecuado incluirlos en la toxicidad de dosis repetidas o farmacocinéticos)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.4.3',
									nombre: '42343-other-stud',
									descripcion: 'Otros estudios',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.3.5',
							nombre: '4235-repro-dev-tox',
							descripcion:
								'Toxicidad reproductiva y del desarrollo (incluidos los estudios de determinación de rango y evaluaciones de toxicocinética de soporte). Si se utilizan diseños de estudios modificados, los siguientes subtítulos se deben modificar adecuadamente.',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.5.1',
									nombre: '42351-fert-embryo-dev',
									descripcion: 'Fertilidad y desarrollo embrionario temprano',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.5.2',
									nombre: '42352-embryo-fetal-dev',
									descripcion: 'Desarrollo embrio-fetal',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.5.3',
									nombre: '42353-pre-postnatal-dev',
									descripcion: 'Desarrollo prenatal y postnatal, incluyendo función maternal',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.5.4',
									nombre: '42354-juv',
									descripcion: 'Estudios en los que las crías (animales jóvenes) se dosifican y/o se evalúan adicionalmente',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.3.6',
							nombre: '4236-loc-tol',
							descripcion: 'Tolerancia local',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '4.2.3.7',
							nombre: '4237-other-tox-stud',
							descripcion: 'Otros estudios de toxicidad (si están disponibles)',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.7.1',
									nombre: '42371-antigen',
									descripcion: 'Antigenicidad',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.7.2',
									nombre: '42372-immunotox',
									descripcion: 'Inmunotoxicidad',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.7.3',
									nombre: '42373-mechan-stud',
									descripcion: 'Estudios mecanísticos (si no están incluidos en otra parte)',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.7.4',
									nombre: '42374-dep',
									descripcion: 'Dependencia',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.7.5',
									nombre: '42375-metab',
									descripcion: 'Metabolitos',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.7.6',
									nombre: '42376-imp',
									descripcion: 'Impurezas',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '4.2.3.7.7',
									nombre: '42377-other',
									descripcion: 'Otros',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								}
							]
						}
					]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '4.3',
			nombre: '43-lit-ref',
			descripcion: 'Literatura de Referencia',
			instrucciones: ECTDMedicamentosInstrucciones.LITERATURA_REFERENCIA,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		}
	]
}
