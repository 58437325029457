import { ERROR_CATEGORY, ERROR_LEVEL, ICustomError } from 'cofepris-typesafe/Types/Errors'
import { useSessionStore } from 'src/store/session'

//ruta no autorizada
export default class ERROR_ROUTES_UNAUTHORIZED_ROUTE extends Error implements ICustomError {
	category = 'ROUTES' as ERROR_CATEGORY
	level = 'WARN' as ERROR_LEVEL
	error = 'ERROR_ROUTES_UNAUTHORIZED_ROUTE'
	data = null
	constructor(message: string, data?: any) {
		super(message)
		this.data = data
	}

	public callback(): void {
		console.error('Procesando Error...', this.data)
		console.error('Notificar por alerta al sistema del intento de acceso a ruta detectado y cierre de sesión')
		useSessionStore().machine.send('FORCE_LOGOUT')
	}
}
