<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { containerLabel, toggleSize } from 'src/layouts/layoutsController'
const router = useRoute()

interface BreadcrumbItem {
	label: string
}
// const regex = new RegExp('[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]')

function dictionary(word: string) {
	let response = word

	let words: Record<string, string> = {
		home: 'Inicio',
		login: 'Ingresar',
		buzon: 'Buzón',
		bitacoras: 'Bitácoras',
		modo_operacion: 'Configuración del Modo de Operación del Sistema',
		tramites_en_resolucion: 'Trámites en resolución',
		tramites_en_evaluacion: 'Trámites en evaluación',
		tramites_en_prevencion: 'Trámites en prevención',
		generador_credenciales: 'Generador de credenciales',
		agregarPermiso: 'Agregar Permiso',
		NOTIFICACION: 'Notificaciones',
		informacion: 'Información',
		catalogos: 'Catálogos',
		regex: 'Expresiones Regulares',
		tipoDeDatos: 'Tipos de Datos',
		buildertype: 'Administrador de tipos de datos',
		arbolRecursos: 'Árbol de recursos',
		validacion_documentos: 'Validación de Documentos',
		rnec: 'Registro Nacional de Ensayos Clínicos v2.0',
		sesion: 'Sesión'
	}

	if (words[word]) {
		response = words[word]
	}

	if (response.length > 0) {
		response = response.charAt(0).toUpperCase() + response.slice(1)
	}

	return response
}

const breadcrumbItems = computed<BreadcrumbItem[]>(() => {
	const paths: BreadcrumbItem[] = []
	const fullPathArray = router.fullPath.split('/').filter(Boolean)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	let path = ''
	fullPathArray.forEach((subroute, index) => {
		path += index === fullPathArray.length ? subroute : subroute + '/'
		const label = subroute
		paths.push({
			label: dictionary(label)
		})
	})
	return paths
})
</script>
<template>
	<div class="row">
		<div class="col-md-11">
			<ol class="breadcrumb fs-s gobmx-gray-2 m0 p0 pt5 pb5">
				<li><i class="icon icon-home gobmx-gray-2 bold"></i></li>
				<li v-for="item in breadcrumbItems" :key="item.label">
					<span>{{ item.label }}</span>
				</li>
			</ol>
		</div>
		<div class="col-md-1">
			<div class="text-right">
				<va-icon name="aspect_ratio" class="_icon cp vam" size="medium" @click="toggleSize" />
				<va-badge :text="containerLabel" color="#808485" text-color="#ffffff80" class="cp vam" @click="toggleSize" />
			</div>
		</div>
	</div>
</template>
