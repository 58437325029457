import { ERROR_CATEGORY, ERROR_LEVEL, ICustomError } from 'cofepris-typesafe/Types/Errors'
import { useLogRocketStore } from 'src/store/logrocket'

interface IERROR_API_DATA {
	timestamp: string
	environment: string
	url: string
	method: string
	request: any
	response: any
	status: string
}

//ruta no encontrada
export default class ERROR_API extends Error implements ICustomError {
	category = 'API' as ERROR_CATEGORY
	level = 'ERROR' as ERROR_LEVEL
	error = 'ERROR_API'
	data = {} as IERROR_API_DATA

	constructor(message: string, data: IERROR_API_DATA) {
		super(message)
		this.data = data
	}

	public callback(): void {
		console.warn('Procesando ERROR_API ', this.data)
		console.warn('TODO:Notificar por alerta al sistema del problema detectado')
		useLogRocketStore().getSessionURL()
		useLogRocketStore().track('ERROR_API')
	}
}
