/**
 * @description Módulo para la extracción de las definiciones de Formatos, formularios, secciones y campos desde el contexto de máquina de estados
 * * La estructura de formatos es como sigue:
 * - Un estado de la homoclave tiene X formatos : HomoclaveRegistryFormato[]
 * - Un formato tiene una definición de formato : IFormato
 * - Una definición de formato tiene Y secciones : IDatosSeccion[]
 * - Una sección tiene F formularios
 * - Un formulario tiene C campos
 * - Un campo tiene una definición de campo : ICampoFormulario en la que se encuentran parámetros de configuración del modo de operación y visualización del campo para ser utilizados por el Módulo Formify
 */
import {
	//Registro de Homoclaves
	HomoclaveRegistryFormato,
	IHomoclaveRegistryModalidadFormatos,
	//Contexto de máquina de estados
	IHomoclaveContext
} from 'cofepris-typesafe/Types/Homoclave'
//Formify
import IFormato from 'cofepris-typesafe/src/forms/IFormato'
import ISeccion from 'cofepris-typesafe/src/Steps/ISeccion'
import IFormulario from 'cofepris-typesafe/src/forms/IFormulario'
import ComponentOptions from 'cofepris-typesafe/src/componentOptions/ComponentOptions'
import DataTableOptions from 'cofepris-typesafe/src/componentOptions/DataTableOptions'
import ICampoFormulario from 'cofepris-typesafe/src/forms/ICampoFormulario'
import { CUSTOM_TYPE, ICustomTypeAny, NIVEL_ACCESO } from 'cofepris-typesafe/src/primitives/IPrimitive'
import { ComponentViews } from 'cofepris-typesafe/src/components/ComponentViews'
import { EActor } from 'cofepris-typesafe/Types/Organizacion'
import { NIVEL_ACCESO_VALOR } from 'cofepris-typesafe/Modules/NivelAcceso'

//INTERFACES
export interface IMappingDefinicionHomoclave {
	homoclaveFormatos: HomoclaveRegistryFormato[]
	formatos: { [key: string]: IMappingDefinicionFormato }
	secciones: { [key: string]: IMappingDefinicionSeccion }
	formularios: { [key: string]: IMappingDefinicionFormulario }
	campos: { [key: string]: IMappingDefinicionCampo }
}

export interface IMappingDefinicionFormato {
	homoclaveDefinicionesFormato: IFormato[]
	formato: IFormato
}

export interface IMappingDefinicionSeccion {
	homoclaveFormato: HomoclaveRegistryFormato
	homoclaveFormatoOrden: number
	formato: IFormato
	seccion: ISeccion
	actores: EActor[]
}

export interface IMappingDefinicionFormulario {
	homoclaveDefinicionFormato: IFormato
	formato: IFormato
	seccion: ISeccion
	formulario: IFormulario
}

export interface IMappingDefinicionCampo {
	homoclaveDefinicionFormato: IFormato
	formato: IFormato
	seccion: ISeccion
	formulario: IFormulario
	campo: ICampoFormulario
	idFormato: string
	idSeccion: string
	idFormulario: string
	idCampo: string
	idDato: string
}

export interface IDefinicionFiltro {
	secciones: string[]
	formularios: string[]
	campos: string[]
}

//MATERIAL ICONS OUTLINED
//https://fonts.google.com/icons?selected=Material+Symbols+Outlined
export enum EDefinicionIconos {
	FORMATO = 'snippet_folder',
	SECCION = 'fact_check',
	FORMULARIO = 'list_alt',
	CAMPO = 'toggle_on',
	SECCION_COMPLETA = 'check_circle',
	SECCION_PARCIAL = 'warning',
	FORMULARIO_COMPLETO = 'check_small',
	FORMULARIO_PARCIAL = 'rule',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	FORMULARIO_VACIO = 'warning',
	CUSTOM_TYPE_STRING = 'font_download',
	CUSTOM_TYPE_NUMBER = '123',
	CUSTOM_TYPE_FILE = 'attach_file',
	CUSTOM_TYPE_BOOLEAN = 'radio_button_partial',
	CUSTOM_TYPE_DATE = 'calendar_month',
	CUSTOM_TYPE_ENUM = 'list',
	CUSTOM_TYPE_ARRAY = 'data_array',
	CUSTOM_TYPE_TABLE = 'table_chart'
}

//FORMATOS
/**
 * @description Extrae la definición de formato (combinación de formatos y secciones) de una fase desde el contexto
 */
export const getDefinicionHomoclaveFormatosFromContextoByFase = function (context: IHomoclaveContext, fase: keyof IHomoclaveRegistryModalidadFormatos): HomoclaveRegistryFormato[] {
	try {
		return context.modalidad?.formatos?.[fase] ?? []
	} catch (e) {
		console.error('Error al obtener definicion de formatos de homoclave desde contexto para la fase ' + (fase as any), e)
		return []
	}
}

/**
 * @description Extrae la etiqueta de un formatoHomoclave (combinación de formatos y secciones)
 */
export const getDefinicionEtiquetaFromHomoclaveFormato = function (formato: HomoclaveRegistryFormato): string {
	let response: string = ''
	try {
		if ('titulo' in formato) {
			response = formato.titulo ?? ''
		} else {
			if ('definicionFormato' in formato) {
				response = formato.definicionFormato?._id ?? 'NO_FORMATO'
			}
		}
	} catch (e) {
		console.error('Error al obtener definicion de etiqueta desde formato', formato, e)
	}
	return response
}

//SECCIONES
/**
 * @description Extrae las definiciones de secciones de un formato
 */
export const getDefinicionSeccionesFromFormato = function (formato: IFormato): ISeccion[] {
	try {
		return formato?.secciones ?? []
	} catch (e) {
		console.error('Error al obtener definicion de secciones desde formato', e)
		return []
	}
}

/**
 * @description Extrae la etiqueta de una sección
 */
export const getDefinicionEtiquetaFromSeccion = function (seccion: ISeccion): string {
	let response = ''
	try {
		if ('viewOptions' in seccion) {
			const viewOptions: ComponentOptions = seccion.viewOptions as ComponentOptions
			if ('title' in viewOptions) {
				response = ((viewOptions as any).title as string) || 'NO_TITLE' + ': ' + seccion.identificadorSeccion || 'NO_TITLE : ' + seccion.identificadorSeccion
			}
		}
	} catch (e) {
		console.error('Error al obtener etiqueta desde seccion', seccion, e)
	}
	return response
}

//FORMULARIOS
/**
 * @description Extrae las definiciones de Formularios de una sección
 */
export const getDefinicionFormulariosFromSeccion = function (seccion: ISeccion): IFormulario[] {
	try {
		return (seccion.formularios ?? []) as IFormulario[]
	} catch (e) {
		console.error('Error al obtener definicion de formularios desde seccion', e)
		return []
	}
}

/**
 * @description Extrae la etiqueta de un formulario
 */
export const getDefinicionEtiquetaFromFormulario = function (formulario: IFormulario): string {
	let response = ''
	try {
		if ('viewOptions' in formulario) {
			const viewOptions: ComponentOptions = formulario.viewOptions as ComponentOptions
			if ('title' in viewOptions) {
				response = ((viewOptions as any).title as string) || 'NO_TITLE: ' + formulario.guid
			}
		}
	} catch (e) {
		console.error('Error al obtener etiqueta desde formulario', formulario, e)
	}
	return response
}

//CAMPOS
/**
 * @description Extrae las definiciones de campos de un formulario
 */
export const getDefinicionCamposFromFormulario = function (formulario: IFormulario): ICampoFormulario[] {
	try {
		return (formulario.campos ?? []) as ICampoFormulario[]
	} catch (e) {
		console.error('Error al obtener definicion de campos desde formulario', e)
		return []
	}
}

/**
 * @description Extrae el CUSTOM_TYPE un campo
 */
export const getDefinicionCustomTypeFromCampo = function (campo: ICampoFormulario): CUSTOM_TYPE | string {
	let response: CUSTOM_TYPE | string = 'INDEFINIDO'
	try {
		if (campo?.idCustomType == undefined) {
			response = 'INDEFINIDO'
		}
		if (typeof campo.idCustomType == 'string') {
			response = campo.idCustomType as CUSTOM_TYPE
		} else if (campo.tipoVista == ComponentViews.DataTable) {
			response = CUSTOM_TYPE.TABLE
		} else if (campo.idCustomType?.type) {
			response = campo.idCustomType.type
		} else {
			console.error('Error al obtener definicion de CustomType desde campo', campo)
			response = 'INDEFINIDO'
		}
	} catch (e) {
		console.error('Error al obtener definicion de CustomType desde campo(2)', campo, e)
	}
	return response
}

/**
 * @description Extrae la etiqueta un campo desde la definición del contexto
 */
export const getDefinicionEtiquetaCampoFromContextoByFaseAndIdCampo = function (context: IHomoclaveContext, fase: keyof IHomoclaveRegistryModalidadFormatos, idCampo: string): string {
	let response = ''
	try {
		const definicionMapping: IMappingDefinicionHomoclave = getDefinicionMappingFromContextoByFase(context, fase)
		response = getDefinicionEtiquetaFromCampo(definicionMapping.campos[idCampo]?.campo)
	} catch (e) {
		console.error('Error al obtener la etiqueta del campo ' + idCampo + 'desde contexto para la fase ' + (fase as any), e)
	}
	return response
}

/**
 * @description Extrae la etiqueta un campo desde la definición del campo
 */
export const getDefinicionEtiquetaFromCampo = function (campo: ICampoFormulario): string {
	let response = ''
	try {
		response = campo?.viewOptions?.label ?? ''
		if (response != undefined && campo != undefined) {
			if ('idCustomType' in campo) {
				const idCustomType: ICustomTypeAny = campo.idCustomType as ICustomTypeAny
				if (idCustomType != undefined && 'id' in idCustomType) {
					response = idCustomType._id || 'NO_ID: ' + campo.guid
				}
			}
		}
	} catch (e) {
		console.error('Error al obtener la etiqueta del campo', campo, e)
	}
	return response
}

/**
 * @description Extrae el nivel de acceso un campo desde la definición del campo
 */
export const getDefinicionNivelAccesoFromCampo = function (campo: ICampoFormulario): NIVEL_ACCESO {
	let response = NIVEL_ACCESO.NO_CLASIFICADO
	try {
		if ('viewOptions' in campo) {
			const viewOptions: ComponentOptions = campo.viewOptions as ComponentOptions
			if ('accessLevel' in viewOptions) {
				response = viewOptions.accessLevel!
			}
		}

		if ((getDefinicionCustomTypeFromCampo(campo) as CUSTOM_TYPE) == CUSTOM_TYPE.TABLE) {
			const tableOptions = campo.viewOptions as DataTableOptions
			let maxAccessLevel: NIVEL_ACCESO = response
			tableOptions.tableDefinition?.map(columnDefinition => {
				if (columnDefinition.cell.formField.viewOptions?.accessLevel) {
					const nivelAccesoCelda = columnDefinition.cell.formField.viewOptions.accessLevel as unknown as NIVEL_ACCESO
					if (NIVEL_ACCESO_VALOR[nivelAccesoCelda] > NIVEL_ACCESO_VALOR[maxAccessLevel]) {
						maxAccessLevel = nivelAccesoCelda
					}
				}
			})
			response = maxAccessLevel
		}
	} catch (e) {
		console.error('Error al obtener el nivel de acceso del campo', campo, e)
	}
	return response
}

//MAPPING
/**
 * @description Extrae la definición de formatos de una fase desde el contexto como un mapa de elementos en el que se incluyen los formatos, secciones, formularios y campos a partir de sus id's
 */

export const getDefinicionMappingFromContextoByFase = function (context: IHomoclaveContext, fase: keyof IHomoclaveRegistryModalidadFormatos): IMappingDefinicionHomoclave {
	const response: IMappingDefinicionHomoclave = {
		homoclaveFormatos: [],
		formatos: {}, //indizados por idFormato
		secciones: {}, //indizados por el id de sección, que se integra por el idFormato y el identificador de la sección
		formularios: {},
		campos: {}
	}
	try {
		const homoclaveFormatos: HomoclaveRegistryFormato[] = getDefinicionHomoclaveFormatosFromContextoByFase(context, fase)
		let contadorSecciones = 0
		//FORMATOS
		homoclaveFormatos.forEach(homoclaveFormato => {
			response.homoclaveFormatos.push(homoclaveFormato)
			const homoclaveDefinicionFormato: IFormato = homoclaveFormato.definicionFormato as IFormato
			if (response.formatos[homoclaveDefinicionFormato._id as unknown as string] === undefined) {
				response.formatos[homoclaveDefinicionFormato._id as unknown as string] = {
					formato: {
						_id: homoclaveDefinicionFormato._id,
						secciones: []
					} as IFormato,
					homoclaveDefinicionesFormato: []
				} as IMappingDefinicionFormato
			}
			//SECCIONES
			const secciones: ISeccion[] = getDefinicionSeccionesFromFormato(homoclaveDefinicionFormato)
			response.formatos[homoclaveDefinicionFormato._id as unknown as string].formato.secciones?.push(...secciones)
			response.formatos[homoclaveDefinicionFormato._id as unknown as string].homoclaveDefinicionesFormato.push(homoclaveDefinicionFormato)
			secciones.forEach(seccion => {
				seccion.idDatos = seccion._id as unknown as string
				if (seccion.idDuplicado != undefined) {
					seccion.idDatos = seccion._id + '.[' + seccion.idDuplicado + ']'
				}
				response.secciones[seccion.idDatos] = {
					homoclaveFormato,
					homoclaveFormatoOrden: contadorSecciones,
					formato: response.formatos[homoclaveDefinicionFormato._id as unknown as string].formato,
					seccion,
					actores: homoclaveFormato.actores ?? ([] as EActor[])
				} as IMappingDefinicionSeccion
				contadorSecciones++

				function exploreFormularios(_formularios: IFormulario[]) {
					_formularios?.forEach(formulario => {
						response.formularios[formulario.guid] = {
							homoclaveDefinicionFormato,
							formato: response.formatos[homoclaveDefinicionFormato._id as unknown as string].formato,
							seccion,
							formulario
						} as IMappingDefinicionFormulario
						//CAMPOS
						const campos = getDefinicionCamposFromFormulario(formulario)
						campos.forEach(campo => {
							let idDato = campo.guid
							if (seccion.idDuplicado != undefined) {
								idDato = campo.guid + '.[' + seccion.idDuplicado + ']'
							}
							campo.idDato = idDato
							response.campos[campo.idDato] = {
								homoclaveDefinicionFormato,
								formato: response.formatos[homoclaveDefinicionFormato._id as unknown as string].formato,
								seccion,
								formulario: formulario,
								campo,
								idFormato: homoclaveDefinicionFormato._id,
								//TODO: utilizar el id de sección en lugar del identificador de sección cuando se corriga en persistencia
								idSeccion: seccion.identificadorSeccion,
								idFormulario: formulario.guid,
								idCampo: campo.guid,
								idDato: idDato
							} as IMappingDefinicionCampo
						})
					})
				}
				//FORMULARIOS
				exploreFormularios(getDefinicionFormulariosFromSeccion(seccion))
			})
		})
	} catch (e) {
		console.error('Error al obtener definicion de mapping desde contexto para la fase ' + (fase as any), e)
	}
	return response
}

/**
 * @description Filtra una sección de un formato a partir de un filtro de definición, si los filtros están vacíos, no se aplica filtro y se regresa la sección entera
 * @param {ISeccion} seccion
 * @param {IDefinicionFiltro} filtro
 */
export const filtrarDefinicionSeccion = function (seccion: ISeccion, filtro: IDefinicionFiltro): ISeccion {
	const response: ISeccion = {
		_id: seccion._id,
		viewOptions: seccion.viewOptions,
		formularios: <IFormulario[]>[],
		identificadorSeccion: seccion.identificadorSeccion
	}

	if (filtro.secciones.length == 0 && filtro.formularios.length == 0 && filtro.campos.length == 0) {
		return seccion
	}

	//FORMULARIOS
	const formulariosAgregar: IFormulario[] = []

	if (seccion.formularios != undefined) {
		seccion.formularios.forEach(fm => {
			const formulario = fm as IFormulario
			const camposAgregar: ICampoFormulario[] = []
			if (filtro.formularios.length == 0 || filtro.formularios.includes(formulario.guid)) {
				//CAMPOS
				formulario.campos!.forEach(c => {
					const campo = c as ICampoFormulario
					if (filtro.campos.length == 0 || filtro.campos.includes(campo.guid)) {
						camposAgregar.push(campo)
					}
				})
			}

			if (camposAgregar.length > 0) {
				formulariosAgregar.push({
					_id: formulario._id,
					guid: formulario.guid,
					campos: camposAgregar,
					viewOptions: formulario.viewOptions
				} as IFormulario)
			}
		})
	}

	if (formulariosAgregar.length > 0) {
		response.formularios = formulariosAgregar
	}

	return response
}
