export type ERROR_CATEGORY = 'GENERAL' | 'AUTHORIZATION' | 'ROUTES' | 'API' | E_ERROR_CATEGORIES
export enum E_ERROR_CATEGORIES {
	GENERAL = 'GENERAL',
	AUTHORIZARION = 'AUTHORIZATION',
	ROUTES = 'ROUTES',
	API = 'API'
}

export type ERROR_LEVEL = 'FATAL' | 'ERROR' | 'WARN' | 'INFO' | 'DEBUG' | 'TRACE' | E_ERROR_LEVELS

export enum E_ERROR_LEVELS {
	FATAL = 'FATAL',
	ERROR = 'ERROR',
	WARN = 'WARN',
	INFO = 'INFO',
	DEBUG = 'DEBUG'
}

export const ERROR_LEVELS = Object.freeze({
	FATAL: 0,
	ERROR: 1,
	WARN: 2,
	INFO: 3,
	DEBUG: 4,
	TRACE: 5
})

/**
 * Syslog standar
 * FATAL    One or more key business functionalities are not working and the whole system doesn’t fulfill the business functionalities
 * ERROR    One or more functionalities are not working, preventing some functionalities from working correctly
 * WARN     Unexpected behavior happened inside the application, but it is continuing its work and the key business features are operating as expected.
 * INFO     An event happened, the event is purely informative and can be ignored during normal operations.
 * DEBUG    A log level used for events considered to be useful during software debugging when more granular information is needed.
 * TRACE    A log level describing events showing step by step execution of your code that can be ignored during the standard operation, but may be useful during extended debugging sessions.
 */

export interface ICustomError {
	message: string
	category: ERROR_CATEGORY
	level: ERROR_LEVEL
	error: string
	data?: any
	callback?(): void
}
//Definir errores organizados por categoría

export function isCustomError(object: unknown): object is ICustomError {
	return (
		Object.prototype.hasOwnProperty.call(object, 'category') &&
		Object.prototype.hasOwnProperty.call(object, 'level') &&
		Object.prototype.hasOwnProperty.call(object, 'error') &&
		Object.prototype.hasOwnProperty.call(object, 'data')
	)
}

export function ErrorHandler(e: Error | ICustomError) {
	if (isCustomError(e)) {
		console.warn('Custom error:', e.error)
		if (e.callback) {
			e.callback()
		} else {
			console.warn('category:', e.category)
			console.warn('error:', e.error)
			console.warn('level:', e.level)
			console.warn('data:', e.data)
		}
	} else {
		console.warn('On ErrorHandler:', e)
	}
}
