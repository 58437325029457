/**
 * @name ModificacionesMedicamentos
 * @version 0.0.3
 * @description Archivo principal del Módulo de Modificaciones a Insumos de Saludo de tipo Medicamentos.
 * Este archivo debe exportar las interfaces, tipos y constantes que se definan en el módulo.
 */
import { EMedicamentoTipo } from '../InsumoSalud'
import { EMedicamentoFormulaProporcion } from '../InsumoSalud/Medicamento'

export enum EModificacionesMedicamentoTipo {
	MAYOR = 'MAYOR',
	MODERADA = 'MODERADA',
	MENOR = 'MENOR'
}
export enum EModificacionesMedicamentoEtiquetas {
	A = 'A',
	B = 'B',
	C = 'C',
	D = 'D',
	E = 'E',
	F = 'F',
	G = 'G',
	H = 'H',
	UNICO = 'UNICO'
}
export enum EModificacionesMedicamentoSeccionesOficio {
	FABRICANTE_FARMACO = 'FABRICANTE_FARMACO',
	FABRICANTE_MEDICAMENTO = 'FABRICANTE_MEDICAMENTO',
	FABRICANTE_DILUYENTE = 'FABRICANTE_DILUYENTE',
	LICENCIATARIO = 'LICENCIATARIO',
	ACONDICIONADOR_PRIMARIO = 'ACONDICIONADOR_PRIMARIO',
	ACONDICIONADOR_SECUNDARIO = 'ACONDICIONADOR_SECUNDARIO',
	DISTRIBUIDOR = 'DISTRIBUIDOR',
	ALMACEN = 'ALMACEN',
	UNIDAD_FARMACOVIGILANCIA = 'UNIDAD_FARMACOVIGILANCIA',
	PRESENTACION = 'PRESENTACION',
	ENVASE_PRIMARIO = 'ENVASE_PRIMARIO',
	VIDA_UTIL = 'VIDA_UTIL',
	FORMULA = 'FORMULA',
	NO_REFLEJADAS_OFICIO = 'NO_REFLEJADAS_OFICIO'
}
export interface IModificacionMedicamento {
	padre?: string
	tituloPadre?: string
	numeral: string
	nombre: string
	incisos: IModificacionesMedicamentoInciso[]
	tiposMedicamento: EMedicamentoTipo[]
}

export interface IModificacionesMedicamentoInciso {
	referenciaWHO?: string
	titulo?: string
	letra: EModificacionesMedicamentoEtiquetas
	descripcion: string
	seccionesOficio: EModificacionesMedicamentoSeccionesOficio[]
	tipo: EModificacionesMedicamentoTipo
	etiqueta: IModificacionMedicamentoAnexo
	instructivo: IModificacionMedicamentoAnexo
	informacionParaPrescribir: IModificacionMedicamentoAnexo
}

export interface IModificacionMedicamentoAnexo {
	aplica: boolean
	notas: string[]
}
export interface IFabricante {
	farmaco: string
	medicamento: string
	diluyente: string
}

export interface IDatos {
	dice: string
	debeDecir: string
}
export interface IModificacionSeccionOficio {
	titulo: EModificacionesMedicamentoSeccionesOficio
	datos?: IDatos[]
	formulas?: IFormula[]
}
export interface IAditivo {
	aditivo: string
	cantidad: IDatos
	unidaMedidad: string
	notaEspecifica: IDatos
}
export interface IFormula {
	nombre: string
	proporcion: EMedicamentoFormulaProporcion
	aditivos: IAditivo[]
	hasComent: boolean
	commentario?: string
	hasChangeCountry: boolean
	changeCountry?: string
}
export interface IFormulariosModificaciones {
	fabricanteFarmaco: IModificacionSeccionOficio
	fabricanteMedicamento: IModificacionSeccionOficio
	fabricanteDiluyente: IModificacionSeccionOficio
	licenciatario: IModificacionSeccionOficio
	acondicionadorPrimario: IModificacionSeccionOficio
	acondicionadorSecundario: IModificacionSeccionOficio
	distribuidor: IModificacionSeccionOficio
	almacen: IModificacionSeccionOficio
	unidadFarmacovigilancia: IModificacionSeccionOficio
	presentacion: IModificacionSeccionOficio
	envasePrimario: IModificacionSeccionOficio
	vidaUtil: IModificacionSeccionOficio
	formula: IModificacionSeccionOficio
	noReflejadasOficio: IModificacionSeccionOficio
}
export interface IModificacion {
	padre?: string
	tituloPadre?: string
	numeral: string
	nombre: string
	incisos: IModificacionDatos[]
	tiposMedicamento: EMedicamentoTipo[]
}
export interface IEstructuraModificaciones {
	formatos: IFormulariosModificaciones
}

export interface IModificacionDatos {
	valor: IModificacionesMedicamentoInciso
	isSelect: boolean
}
