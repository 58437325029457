/**
 * @name CTDMedicamentosModulo3
 * @author @tirsomartinezreyes
 * @version 0.0.10
 *
 * @description Define la estructura del Módulo 3 (Quality) del CTD (Common Technical Document) para Registro de Medicamentos.
 *
 * @changelos '0.0.10 - 28/ago/24 - Se corrigen typos en 3.2.p.4.3
 * @changelog 0.0.9 - corrección de typos en 3.2.p.2.3, 3.2.p.3.4 , 3.2.p.4.3 y  3.2.r.8
 */
import {
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

import {
	ECTDMedicamentosInstrucciones //Enumeración que define las instrucciones comúnes para los nodos del CTD
} from './CTDMedicamentosInstrucciones'

/**
 * @name CTDMedicamentosModulo3
 */
export const CTDMedicamentosModulo3: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm3',
	nombre: 'm3',
	descripcion: 'Módulo 3: Calidad',
	hijos: [
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '3.1',
			nombre: '31-toc',
			descripcion: 'Tabla de Contenido del Módulo 3',
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '3.2',
			nombre: '32-body-data',
			descripcion: 'Conjunto de datos',
			hijos: [
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '3.2.a',
					nombre: '32a-app',
					descripcion: 'Apéndices',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.a.1',
							nombre: '32a1-fac-equip',
							descripcion: 'Instalaciones y equipos',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.a.2',
							nombre: '32a2-advent-agent',
							descripcion: 'Evaluación de seguridad de agentes adventicios',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							id: '3.2.a.3',
							nombre: '32a3-novel-excip',
							descripcion: 'Aditivos Nuevos',
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA,
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '3.2.p',
					nombre: '32p-drug-prod',
					descripcion: 'Producto Terminado',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_PRODUCTO_TERMINADO,
							id: 'product-',
							nombre: 'product-',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.p.1',
									nombre: '32p1-desc-comp',
									descripcion: 'Descripción y composición del producto terminado',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.p.2',
									nombre: '32p2-pharma-dev',
									descripcion: 'Desarrollo farmacéutico',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.2.1',
											nombre: '32p21-mx-components-drug-prod',
											descripcion: 'Componentes del producto terminado',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.2.2',
											nombre: '32p22-mx-drug-prod',
											descripcion: 'Producto terminado',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.2.3',
											nombre: '32p23-mx-manufacturing-process-dev',
											descripcion: 'Desarrollo del proceso de fabricación',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.2.4',
											nombre: '32p24-mx-cont-closure-sys',
											descripcion: 'Sistema contenedor-cierre',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.2.5',
											nombre: '32p25-mx-microbiological-attributes',
											descripcion: 'Atributos microbiológicos',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.2.6',
											nombre: '32p26-mx-compatibility',
											descripcion: 'Compatibilidad',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.p.3',
									nombre: '32p3-manuf',
									descripcion: 'Fabricación',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.3.1',
											nombre: '32p31-mx-manufacturers',
											descripcion: 'Fabricante(s)',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.3.2',
											nombre: '32p32-mx-batch-formula',
											descripcion: 'Fórmula del lote',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.3.3',
											nombre: '32p33-mx-desc-manufacturing-process-contr',
											descripcion: 'Descripción del proceso de fabricación y los controles del proceso',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.3.4',
											nombre: '32p34-mx-contr-critical-steps-intermediates',
											descripcion: 'Control de etapas críticas y productos intermedios',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.3.5',
											nombre: '32p35-mx-process-val-evaluation',
											descripcion: 'Validación y/o evaluación del proceso',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.p.4',
									nombre: '32p4-contr-excip',
									descripcion: 'Control de aditivos',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA,
											id: 'excip-',
											nombre: 'excip-',
											hijos: [
												{
													tipo: ECTDTipoNodo.FOLDER,
													id: '3.2.p.4.1',
													nombre: '32p41-mx-spec',
													descripcion: 'Especificaciones',
													hijos: [
														{
															tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
															documentoFormato: [ECTDFormatoDocumento.PDF]
														}
													]
												},
												{
													tipo: ECTDTipoNodo.FOLDER,
													id: '3.2.p.4.2',
													nombre: '32p42-mx-analyt-proc',
													descripcion: 'Métodos analíticos',
													hijos: [
														{
															tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
															documentoFormato: [ECTDFormatoDocumento.PDF]
														}
													]
												},
												{
													tipo: ECTDTipoNodo.FOLDER,
													id: '3.2.p.4.3',
													nombre: '32p43-mx-val-analyt-proc',
													descripcion: 'Validación de métodos analíticos',
													hijos: [
														{
															tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
															documentoFormato: [ECTDFormatoDocumento.PDF]
														}
													]
												},
												{
													tipo: ECTDTipoNodo.FOLDER,
													id: '3.2.p.4.4',
													nombre: '32p44-mx-justif-spec',
													descripcion: 'Justificación de las especificaciones',
													hijos: [
														{
															tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
															documentoFormato: [ECTDFormatoDocumento.PDF]
														}
													]
												},
												{
													tipo: ECTDTipoNodo.FOLDER,
													id: '3.2.p.4.5',
													nombre: '32p45-mx-human-animal-origin-excip',
													descripcion: 'Aditivos de origen humano o animal',
													hijos: [
														{
															tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
															documentoFormato: [ECTDFormatoDocumento.PDF]
														}
													]
												},
												{
													tipo: ECTDTipoNodo.FOLDER,
													id: '3.2.p.4.6',
													nombre: '32p46-mx-novel-excip',
													descripcion: 'Aditivos nuevos',
													hijos: [
														{
															tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
															documentoFormato: [ECTDFormatoDocumento.PDF]
														}
													]
												}
											]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.p.5',
									nombre: '32p5-contr-drug-prod',
									descripcion: 'Control de producto terminado',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.5.1',
											nombre: '32p51-spec',
											descripcion: 'Especificaciones',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.5.2',
											nombre: '32p52-analyt-proc',
											descripcion: 'Métodos analíticos',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.5.3',
											nombre: '32p53-val-analyt-proc',
											descripcion: 'Validación de métodos analíticos',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.5.4',
											nombre: '32p54-batch-analys',
											descripcion: 'Análisis de lotes',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.5.5',
											nombre: '32p55-charac-imp',
											descripcion: 'Caracterización de impurezas',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.5.6',
											nombre: '32p56-justif-spec',
											descripcion: 'Justificación de las especificaciones',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.p.6',
									nombre: '32p6-ref-stand',
									descripcion: 'Estándares o materiales de referencia',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.p.7',
									nombre: '32p7-cont-closure-sys',
									descripcion: 'Sistema contenedor-cierre',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.p.8',
									nombre: '32p8-stab',
									descripcion: 'Estabilidad',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.8.1',
											nombre: '32p81-mx-stab-sum-conclusion',
											descripcion: 'Resumen y conclusiones de estabilidad',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.8.2',
											nombre: '32p82-mx-post-approval-stab-protocol',
											descripcion: 'Protocolo de estabilidad posterior a la aprobación y compromiso de estabilidad.',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.p.8.3',
											nombre: '32p83-mx-stab-data',
											descripcion: 'Datos de estabilidad',
											instrucciones: 'Protocolo e informe de estabilidad conforme a la NOM-073-SSA1-2015 Estabilidad de fármacos y medicamentos, así como de remedios herbolarios)',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										}
									]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '3.2.r',
					nombre: '32r-reg-info',
					descripcion: 'Información Regional',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.r.1',
							nombre: '32r1-mx-documentacion-produccion',
							descripcion: 'Documentación de Producción',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.r.2',
							nombre: '32r2-mx-documentacion-control-calidad',
							descripcion: 'Otros documentos del control de calidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.r.3',
							nombre: '32r3-mx-dispositivos-medicos',
							descripcion: 'Dispositivo(s) médico(s): Descripción, desempeño, seguridad de uso y fabricante.',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.r.4',
							nombre: '32r4-mx-comparabilidad',
							descripcion: 'Comparabilidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.r.5',
							nombre: '32r5-mx-biocomparabilidad',
							descripcion: 'Biocomparabilidad',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.r.6',
							nombre: '32r6-mx-extrapolacion',
							descripcion: 'Extrapolación',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.r.7',
							nombre: '32r7-mx-certificado-identidad-taxonomica-planta',
							descripcion: 'Certificado de identidad taxonómica de la planta',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						},
						{
							tipo: ECTDTipoNodo.FOLDER,
							id: '3.2.r.8',
							nombre: '32r8-mx-evaluacion-riesgo-medio-ambiente',
							descripcion: 'Evaluación del riesgo para el medio ambiente',
							hijos: [
								{
									tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
									documentoFormato: [ECTDFormatoDocumento.PDF]
								}
							]
						}
					]
				},
				{
					tipo: ECTDTipoNodo.FOLDER,
					id: '3.2.s',
					nombre: '32s-drug-sub',
					descripcion: 'Fármaco / Biofármaco / Antígeno',
					hijos: [
						{
							tipo: ECTDTipoNodo.FOLDER_MULTIPLE_SUSTANCIA_SUJETO,
							id: 'substance-manufacturer-',
							nombre: 'substance-manufacturer-',
							hijos: [
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.s.1',
									nombre: '32s1-gen-info',
									descripcion: 'Información general',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},

								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.s.2',
									nombre: '32s2-manuf',
									descripcion: 'Fabricacion',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.2.1',
											nombre: '32s21-mx-manufacturer',
											descripcion: 'Fabricantes',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.2.2',
											nombre: '32s22-mx-desc-manufacturing-process-contr',
											descripcion: 'Descripción del proceso de fabricación y controles del proceso',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.2.3',
											nombre: '32s23-mx-contr-materials',
											descripcion: 'Control de materiales de partida',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.2.4',
											nombre: '32s24-mx-contr-critical-steps-intermediates',
											descripcion: 'Controles de etapas críticas y productos intermedios',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.2.5',
											nombre: '32s25-mx-process-val-evaluation',
											descripcion: 'Validación y/o evaluación del proceso',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.2.6',
											nombre: '32s26-mx-manufacturing-process-dev',
											descripcion: 'Desarrollo del proceso de fabricación',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.s.3',
									nombre: '32s3-charac',
									descripcion: 'Caracterización',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.3.1',
											nombre: '32s31-mx-elucidation-structure-other-characteristics',
											descripcion: 'Elucidación de la estructura y otras características',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.3.2',
											nombre: '32s32-mx-impurities',
											descripcion: 'Impurezas',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.s.4',
									nombre: '32s4-contr-drug-sub',
									descripcion: 'Control del fármaco',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.4.1',
											nombre: '32s41-spec',
											descripcion: 'Especificaciones',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.4.2',
											nombre: '32s42-analyt-proc',
											descripcion: 'Métodos analíticos',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.4.3',
											nombre: '32s43-val-analyt-proc',
											descripcion: 'Validación de los métodos analíticos',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.4.4',
											nombre: '32s44-batch-analys',
											descripcion: 'Análisis de lotes',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.4.5',
											nombre: '32s45-justif-spec',
											descripcion: 'Justificación de las especificaciones',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.s.5',
									nombre: '32s5-ref-stand',
									descripcion: 'Estándares o materiales de referencia',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.s.6',
									nombre: '32s6-cont-closure-sys',
									descripcion: 'Sistema Contenedor-Cierre',
									hijos: [
										{
											tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
											documentoFormato: [ECTDFormatoDocumento.PDF]
										}
									]
								},
								{
									tipo: ECTDTipoNodo.FOLDER,
									id: '3.2.s.7',
									nombre: '32s7-stab',
									descripcion: 'Estabilidad',
									hijos: [
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.7.1',
											nombre: '32s71-mx-stab-sum-conclusions',
											descripcion: 'Resumen de estabilidad y conclusiones',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.7.2',
											nombre: '32s72-mx-post-approval-stab-protocol',
											descripcion: 'Protocolo de estabilidad posterior a la aprobación y compromiso de estabilidad',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										},
										{
											tipo: ECTDTipoNodo.FOLDER,
											id: '3.2.s.7.3',
											nombre: '32s73-mx-stab-data',
											descripcion: 'Datos de estabilidad',
											hijos: [
												{
													tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
													documentoFormato: [ECTDFormatoDocumento.PDF]
												}
											]
										}
									]
								}
							]
						}
					]
				}
			]
		},
		{
			tipo: ECTDTipoNodo.FOLDER,
			id: '3.3',
			nombre: '33-lit-ref',
			descripcion: 'Literatura de referencia',
			instrucciones: ECTDMedicamentosInstrucciones.LITERATURA_REFERENCIA,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		}
	]
}
