/**
 * @name CTDDispositivosModulo3
 * @author @tirsomartinezreyes
 * @version 0.0.5
 *
 * @description Define la estructura del Módulo 3 (Evidencia No Clínica)
 * del CTD (Common Technical Document) para Registro de Dispositivos.
 *
 * @changelog 0.0.5 - 09/sep/24 - Se corrige typo en 3.5 y 3.7.4
 * @changelog 0.0.4 - 28/agosto/24 - Se corrige typo en 3.2.1.3
 * @changelog 0.0.3 - Se corrige typo en 3.1
 * @changelog 0.0.2 - Se agregan folders para carga de resúmenes en múltiples nodos del CTD
 */

import {
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

/**
 * @name CTDDispositivosModulo3
 * @description Define la estructura del Módulo 3 del CTD (Common Technical Document) para Registro de Dispositivos.
 */
export const CTDDispositivosModulo3: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm3',
	nombre: 'm3',
	descripcion: 'Módulo 3: EVIDENCIA NO CLÍNICA',
	hijos: [
		{
			id: '3.1',
			nombre: '31-mat-primas-y-formulas',
			descripcion: 'Materias primas y fórmulas',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '3.1.1',
					nombre: '311-lista-materiales',
					descripcion: 'Lista de materiales de elaboración utilizados en el dispositivo médico indicando el nombre y composición de los materiales',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.2',
					nombre: '312-form-cualicuanti',
					descripcion: 'Declaración de la fórmula cuali- cuantitativa por unidad de medida, dosis o porcentual para productos formulados emitida por el fabricante.',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.3',
					nombre: '313-cert-mat-primas',
					descripcion: 'Certificados de análisis de materias primas',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.4',
					nombre: '314-info-rela-farmaco',
					descripcion: 'Información relacionada y control del fármaco',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.5',
					nombre: '315-descr-partes-func',
					descripcion: 'Descripción o diagrama de las partes funcionales del producto',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.6',
					nombre: '316-esquema-dispositivo',
					descripcion: 'Dibujo o imagen esquemática que represente el dispositivo médico',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.7',
					nombre: '317-esquema-dibujos-ing',
					descripcion: 'Esquemas de diseño o dibujos de ingeniería',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.8',
					nombre: '318-diagrama-fabri',
					descripcion: 'Diagrama o descripción general del proceso de fabricación',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.9',
					nombre: '319-otros-doc-mat',
					descripcion: 'Otros documentos relacionados a la calidad de los materiales',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.1.10',
					nombre: '3110-resumen-mat',
					descripcion: 'Resumen de materias primas y fórmulas',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '3.2',
			nombre: '32-biocompatibilidad',
			descripcion: 'Pruebas de Biocompatibilidad',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '3.2.1',
					nombre: '321-rep-eval-biologica',
					descripcion: 'Reporte de evaluación de seguridad biológica',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.2',
					nombre: '322-rep-geno-carci-toxic',
					descripcion: 'Reportes de pruebas de genotoxicidad, carcinogenicidad y toxicidad reproductiva',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.3',
					nombre: '323-rep-hemocomp',
					descripcion: 'Reportes de pruebas de hemocompatibilidad',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.4',
					nombre: '324-rep-cito-irrit-hiper',
					descripcion: 'Reportes de pruebas de citotoxicidad, irritación e hipersensibilidad retardada',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.5',
					nombre: '325-rep-efectos-implant',
					descripcion: 'Reportes de pruebas para evaluar efectos locales después de la implantación',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.6',
					nombre: '326-rep-toxic-sistemica',
					descripcion: 'Reportes de pruebas de toxicidad sistémica',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.7',
					nombre: '327-rep-prod-degrad-lix',
					descripcion: 'Reportes de estudio de productos de degradación y lixiviables',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.8',
					nombre: '328-rep-pirogenicidad',
					descripcion: 'Reporte de pruebas de pirogenicidad',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.9',
					nombre: '329-refer-mate-conocidos',
					descripcion: 'Referencias bibliográficas del material con el certificado de análisis que avale la materia prima. (Para el caso de materiales reconocidos)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.10',
					nombre: '3210-rep-veri-vali-diseno',
					descripcion: 'Reporte de verificación y validación del diseño',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.11',
					nombre: '3211-espec-dispositivo',
					descripcion: 'Especificaciones fisicas, quimicas, mecánicas y/o funcionales del dispositivo médico',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.12',
					nombre: '3212-otros-doc',
					descripcion: 'Otros documentos según aplique',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.2.13',
					nombre: '3213-resumen-biocomp',
					descripcion: 'Resumen de pruebas de biocompatibilidad',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '3.3',
			nombre: '33-metodo-analisis',
			descripcion: 'Métodos de análisis',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '3.3.1',
					nombre: '331-rep-mecanicas',
					descripcion: 'Reportes completos de pruebas mecánicas conforme a farmacopea o estándar internacional o especificaciones del fabricante',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.3.2',
					nombre: '332-rep-desemp-funcion',
					descripcion: 'Reportes de pruebas de desempeño/funcionalidad',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.3.3',
					nombre: '333-rep-fisicoquimicas',
					descripcion: 'Reportes de pruebas fisicoquímicas conforme estándar o farmacopea',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.3.4',
					nombre: '334-doc-tejido-hum-anim',
					descripcion: 'Documentación que demuestre trazabilidad, seguridad y certificación del origen del tejido humano o animal',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.3.5',
					nombre: '335-rep-acces-envaseprim',
					descripcion: 'Reportes de pruebas de funcionalidad, seguridad y eficacia para otros dispositivos incluidos como accesorios o envases primarios',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.3.6',
					nombre: '336-cartas-tecnicas',
					descripcion: 'Cartas o justificaciones técnicas',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.3.7',
					nombre: '337-otros-doc',
					descripcion: 'Otros documentos según aplique',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.3.8',
					nombre: '338-resumen-met-analisis',
					descripcion: 'Resumen de métodos de análisis',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '3.4',
			nombre: '34-seg-electrica-radiacion',
			descripcion: 'Seguridad eléctrica y de radiación',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '3.4.1',
					nombre: '341-rep-seg-electrica-etc',
					descripcion: 'Reportes de seguridad eléctrica, compatibilidad electromagnética, etc. (IEC)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.4.2',
					nombre: '342-rep-seg-desemp',
					descripcion: 'Reportes de pruebas de seguridad y desempeño específicas para el tipo de dispositivo',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.4.3',
					nombre: '343-rep-rayosx-rad-ionica',
					descripcion: 'Reportes de pruebas aplicables a equipos de rayos X o que emitan algún tipo de radiación ionizante',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.4.4',
					nombre: '344-rep-valid-software',
					descripcion: 'Reportes de pruebas de validación de software, usabilidad, etc.',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.4.5',
					nombre: '345-otros-doc',
					descripcion: 'Otros documentos según aplique',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.4.6',
					nombre: '346-resumen-seg-elect-rad',
					descripcion: 'Resumen de seguridad eléctrica y de radiación',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '3.5',
			nombre: '35-estabilidad',
			descripcion: 'Estudios de estabilidad',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '3.5.1',
					nombre: '351-desc-mat-empaque',
					descripcion: 'Descripción, materiales de elaboración del sistema de empaque',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.5.2',
					nombre: '352-rep-estabil-envej-aceler',
					descripcion: 'Reporte completo de estudio de estabilidad o envejecimiento acelerado',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.5.3',
					nombre: '353-rep-estabil-envej-real',
					descripcion: 'Reporte completo de estudio de estabilidad o envejecimiento en tiempo real',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.5.4',
					nombre: '354-otros-doc',
					descripcion: 'Otros documentos según aplique',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.5.5',
					nombre: '355-resumen-estabili',
					descripcion: 'Resumen de estudios de estabilidad',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '3.6',
			nombre: '36-esterilizacion',
			descripcion: 'Esterilización',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '3.6.1',
					nombre: '361-desc-proc-esterili',
					descripcion: 'Descripción del proceso de esterilización aplicado al dispositivo',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.6.2',
					nombre: '362-rep-val-proc-ester',
					descripcion: 'Reporte de validación del proceso de esterilización',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.6.3',
					nombre: '363-rep-val-endotox-oxet',
					descripcion: 'Certificados esterilidad, endotoxinas, residuos de óxido de etileno',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.6.4',
					nombre: '364-otros-doc',
					descripcion: 'Otros documentos según aplique',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.6.5',
					nombre: '365-resumen-esterili',
					descripcion: 'Resumen de esterilización',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '3.7',
			nombre: '37-desem-analitico',
			descripcion: 'Desempeño analítico',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '3.7.1',
					nombre: '371-rep-desem-anali-clin',
					descripcion: 'Reportes de pruebas para verificar desempeño analítico y clínico',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.7.2',
					nombre: '372-rep-ausen-vih-hb-aginf',
					descripcion: 'Reportes de pruebas de ausencia de anticuerpo contra VIH, hepatitis B u otro agente infectocontagioso',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.7.3',
					nombre: '373-otros-doc',
					descripcion: 'Otros documentos según aplique',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.7.4',
					nombre: '374-resumen-desem-analit',
					descripcion: 'Resumen de desempeño analítico',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '3.8',
			nombre: '38-scdm',
			descripcion: 'Software como dispositivo médico',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '3.8.1',
					nombre: '381-gestion-riesgos',
					descripcion: 'Gestión de riesgos',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.8.2',
					nombre: '382-descr-ciclo-vida',
					descripcion: 'Descripción del Proceso del Ciclo de Vida del Software',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.8.3',
					nombre: '383-verifi-valid',
					descripcion: 'Verificación y validación del Software',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.8.4',
					nombre: '384-ciberseguridad',
					descripcion: 'Ciberseguridad',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.8.5',
					nombre: '385-otra-evid-noclinica',
					descripcion: 'Otra evidencia no clínica',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '3.8.6',
					nombre: '386-resumen-scdm',
					descripcion: 'Resumen de Software como Dispositivo Médico',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},

		{
			id: '3.9',
			nombre: '39-gestion-riesgos',
			descripcion: 'Gestión de Riesgos',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		}
	]
}
