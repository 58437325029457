<script setup lang="ts">
import {
	PrivateKey,
	GlobalMethods,
	RSASignContext,
	VaCard,
	VaCardContent,
	VaFileUpload,
	VaInput,
	cerIconSource,
	computed,
	getRSAMachine,
	haciendaImage,
	keyIconSource,
	loadX509CertificateFromBase64String,
	logoSat,
	objectValidate,
	onMounted,
	ref,
	shieldIconSource,
	useMachine,
	watch
} from './bom'

const props = defineProps({
	mode: {
		type: String,
		//required: true,
		default: 'LOGIN'
	},
	debug: {
		type: Boolean,
		//required: true,
		default: false
	},
	message: {
		type: String,
		default: null
	},
	certificadoB64: {
		type: String,
		default: null
	}
})

const machine = useMachine(getRSAMachine)
const isPasswordVisible = ref(false)
const pkPass = ref('')
const emit = defineEmits(['validSignature', 'cancel'])

defineExpose({
	machine
})

onMounted(() => {
	props.debug ? console.log('onMounted: RSA') : null
	machine.send('START', { debug: props.debug, mode: props.mode, message: props.message, certificadoB64: props.certificadoB64 })
})

const nextEvents = computed(() => {
	return machine.state.value.nextEvents
})

const isState = function (mode: string): boolean {
	return machine.state.value.matches(mode)
}

const getContextValue = function (path: string): any {
	return objectValidate(machine.state.value.context, path, null)
}

const numeroCertificadoCorto = function (certificado: string): string {
	let response = ''
	for (let i = 0; i < certificado.length; i++) {
		if (i % 2 == 1) {
			response += certificado[i]
		}
	}
	return response
}

const getSubjectFieldFromCertificate = (subject: any, type: string, valueToFind: any): string => {
	let value: string = ''
	try {
		if (subject && subject.attributes && Array.isArray(subject.attributes)) {
			value = subject.attributes.find((field: any) => field[type] === valueToFind).value
		}
	} catch (e) {
		return value
	}

	return value
}

const rfc = computed(() => {
	let response = ''
	if (getContextValue('certificadoB64')) {
		let x509Certificate = loadX509CertificateFromBase64String(getContextValue('certificadoB64'))
		response = getSubjectFieldFromCertificate(x509Certificate.certificate.subject, 'type', '2.5.4.45')
	}
	return response
})

const nombre = computed(() => {
	let response = ''
	if (getContextValue('certificadoB64')) {
		let x509Certificate = loadX509CertificateFromBase64String(getContextValue('certificadoB64'))
		response = getSubjectFieldFromCertificate(x509Certificate.certificate.subject, 'shortName', 'CN')
	}
	return response
})

const isContextValue = function (path: string, value: any): any {
	let tmp = objectValidate(machine.state.value.context, path, null)
	return tmp == value
}

const onCertificateLoaded = function (files: File[]) {
	props.debug ? console.log('on onCertificateLoaded', files) : null
	try {
		if (files) {
			if (files[0]) {
				const file = files[0]
				const reader = new FileReader()
				reader.readAsDataURL(file)

				reader.onload = function () {
					const result = reader.result as string
					const certificadoB64 = result.split(',')[1]
					const certificadoX509 = loadX509CertificateFromBase64String(certificadoB64)
					let end = objectValidate(certificadoX509, 'certificate.validity.notAfter', '')

					if (!certificadoX509.valid) {
						machine.send('CERTIFICATE_ERROR', { certificadoErrorMessage: 'El certificado ha vencido: ' + end })
						return
					}

					if (certificadoX509.certificateType != 'EFIRMA') {
						machine.send('CERTIFICATE_ERROR', { certificadoErrorMessage: 'El certificado no es de e.firma' })
						return
					}

					machine.send('CERTIFICATE_OK', { certificadoB64 })
				}

				reader.onerror = function (error) {
					console.log('reader error: ', error)
					machine.send('CERTIFICATE_ERROR', { certificadoErrorMessage: 'Ocurrió un error en la lectura del certificado' })
				}
			}
		}
	} catch (error) {
		console.log('Error on onCertificateLoaded', error)
		machine.send('CERTIFICATE_ERROR', { certificadoErrorMessage: 'Ocurrió un error con la caraga del certificado' })
	}
}

const onPkLoaded = function (files: File[]) {
	props.debug ? console.log('on onPkLoaded', files) : null
	try {
		if (files) {
			if (files[0]) {
				const file = files[0]
				const reader = new FileReader()
				reader.readAsDataURL(file)

				reader.onload = function () {
					const result = reader.result as string
					const pkB64 = result.split(',')[1]
					//TODO: antes de enviar el evento SET_PKB64, se debe validar que el certificado y la llave privada coincidan, en caso contrario enviar a signature_error
					machine.send('SET_PKB64', { pkB64 })
				}

				reader.onerror = function (error) {
					console.log('reader error: ', error)
					machine.send('SIGNATURE_ERROR', { signaturePreviewError: 'Ocurrió un error con la carga de la llave privada' })
				}
			}
		}
	} catch (error) {
		props.debug ? console.log('Error on onPkLoaded', error) : null
		machine.send('SIGNATURE_ERROR', { signaturePreviewError: 'Ocurrió un error con la carga de la llave privada' })
	}
}

const emitCancel = function () {
	props.debug ? console.log('on emitCancel') : null
	emit('cancel')
}

const emitValidSignature = function (context: RSASignContext): void {
	props.debug ? console.log('on emitValidSignature', context) : null
	emit('validSignature', context)
}

const signMessage = function (pw: string) {
	props.debug ? console.log('in signMessage') : null
	pkPass.value = ''
	machine.send('SET_PKPASS', { pkPass: pw })
	try {
		let pk = new PrivateKey(atob(getContextValue('pkB64')))
		let certificadoX509 = loadX509CertificateFromBase64String(getContextValue('certificadoB64'))
		let signature = pk.rsaSign(getContextValue('message'), getContextValue('pkPass'), 'utf8')

		let signatureB64 = GlobalMethods.binaryToBase64(signature)
		let signatureB64Decoded = GlobalMethods.base64ToBinary(signatureB64)
		let validSignature = certificadoX509.rsaVerifySignature(getContextValue('message'), signatureB64Decoded, 'sha256', 'utf8')

		if (validSignature) {
			machine.send('SIGNATURE_SUCCESS', { signatureB64, validSignature })
		} else {
			machine.send('SIGNATURE_ERROR', { signaturePreviewError: 'La firma del mensaje no es válida' })
		}
	} catch (error) {
		props.debug ? console.log('Error on signMessage', error) : null
		machine.send('SIGNATURE_ERROR', { signaturePreviewError: 'No es posible firmar el mensaje. Verifique el archivo.key y/o la contraseña' })
	}
}

//watch a variable
watch(
	() => machine.state.value,
	val => {
		props.debug ? console.log('watch machine.state.value', val) : null
		if (isState('signature_done')) {
			emitValidSignature(machine.state.value.context)
		}
	}
)
</script>
<template>
	<va-card class="wrapper bg-white">
		<!--INICIA DEBUG-->
		<details v-if="machine.state.value.context.debug" class="debug">
			<summary class="p5">
				Máquina: <span class="bold">{{ machine.service.id }}</span> | Estado: <span class="bold">{{ machine.state.value.value }}</span>
			</summary>
			<div class="p5 m5 bss bw1 bclg">
				<button v-for="(event, index) in nextEvents" :key="index" @click="machine.send(event)" class="btn btn-xs fs-xs">
					{{ event }}
				</button>
			</div>
			<pre class="code"> Contexto: {{ JSON.stringify(machine.state.value.context, undefined, 2) }}</pre>
		</details>

		<!--INICIA COMPONENTE-->
		<div class="content mh100">
			<!--INICIA HEADER-->
			<div class="row bg-light-gray black p5">
				<div class="col-md-2 text-left bold ma">
					<img :src="haciendaImage" alt="logo sat" height="20" />
				</div>
				<div class="col-md-8 text-center bold ma">
					<span v-if="isContextValue('mode', 'LOGIN')">Acceso con Firma Electrónica del SAT</span>
					<span v-if="isContextValue('mode', 'SIGN')">Firma Electrónica del SAT</span>
				</div>
				<div class="col-md-2 text-right bold ma">
					<img :src="logoSat" alt="logo sat" height="20" />
				</div>
			</div>

			<!--error_mode-->
			<div v-if="isState('error_mode')" class="row">
				<div class="col-md-12 text-center m20 alert alert-danger bold ff-mono">
					Modo de configuración no válido: {{ machine.state.value.context.mode }}
					<br />
					<span class="ff-mono" v-if="isContextValue('mode', 'SIGN')"> Para configurar el modo de firma debe proporcionarse message y certificadoB64 </span>
				</div>
			</div>

			<!--login_waiting-->
			<div v-if="isState('login_waiting')" class="row w100p pt20 pb20">
				<div class="col-md-3 text-center w25p">
					<img :src="cerIconSource" alt="logo hacienda" height="40" class="v-center" />
					&nbsp;
					<img :src="keyIconSource" alt="logo hacienda" height="40" class="v-center" />
				</div>
				<div class="col-md-6 text-center w50p">
					<button cyid="btnLoginEFirma" class="btn btn-primary active btn-sm bold" @click="machine.send('ACTIVATE_LOGIN')">Acceso con E.firma del SAT</button>
				</div>
				<div class="col-md-3 text-center w25p"></div>
			</div>

			<!--certificate_waiting-->
			<div class="row mt5 mb5" v-if="isState('certificate_waiting')">
				<div class="col-md-3 text-center w25p">
					<img :src="cerIconSource" alt="logo hacienda" height="50" class="v-center" />
				</div>
				<div class="col-md-6 text-center w50p">
					<div class="vcenter">
						<va-file-upload
							cyid="btnCargaCertificadoEfirma"
							class="w100p"
							style="width: 60%; height: 100%"
							color="#9d2449"
							file-types=".cer"
							upload-button-text="Certificado de E.firma"
							hide-file-list
							@fileAdded="onCertificateLoaded"
						/>
					</div>
				</div>
				<div class="col-md-3 text-center ma w25p">
					<button
						class="btn btn-danger btn-xs"
						type="button"
						@click="
							$event => {
								machine.send('CANCEL_LOGIN')
								emitCancel()
							}
						"
					>
						Cancelar
					</button>
				</div>
			</div>

			<!--certificate_error-->
			<va-card-content class="p0" v-if="isState('certificate_error')">
				<div class="alert alert-danger m20 bold">
					{{ machine.state.value.context.certificadoErrorMessage || 'Hay un error con el certificado' }}<br /><br /><br />
					<button class="btn btn-danger btn-xs" @click="machine.send('CERTIFICATE_RETRY')">Reintentar</button>
				</div>
			</va-card-content>

			<!--message_waiting-->
			<div class="row" v-if="isState('message_waiting')">
				<div class="col-md-12 text-center bold mt25">
					<va-inner-loading loading></va-inner-loading>
					<div class="m20">Validando certificado...</div>
				</div>
			</div>

			<!--message_login_error-->
			<div v-if="isState('message_login_error')">
				<div class="alert alert-danger bold m20">
					{{ getContextValue('getMessageError') || 'Ocurrió un error al obtener el mensaje' }}<br /><br /><br />
					<div class="md12" style="padding: 15px; text-align: center">
						<button class="btn btn-danger btn-sm" style="margin: 5px !important" @click="machine.send('MESSAGE_RETRY')">Reintentar</button>
						<button class="btn btn-danger btn-sm" style="margin: 5px !important" @click="machine.send('MESSAGE_CANCEL')">Usar otro certificado</button>
					</div>
				</div>
			</div>

			<!--signature_preview-->
			<div v-if="isState('signature_preview')">
				<div class="row bss bw1 bclg p5 dark-gray">
					<div class="col-md-4 fs-xs bold wb-ba ma text-center">Certificado: {{ numeroCertificadoCorto(getContextValue('certificadoX509.serialNumber')) }}</div>
					<div class="col-md-4 fs-xs bold wb-ba ma text-center">
						{{ rfc }}
					</div>
					<div class="col-md-4 fs-xs bold wb-ba ma text-center">
						{{ nombre }}
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="bss bw1 bclg p20 dark-gray mt15 wb-ba">
							<div class="text-center ff-mono mb10 bold">Cadena Original</div>
							<va-scroll-container style="min-height: 100px; max-height: 200px" color="primary" vertical size="medium">
								<div class="ff-mono fs-s text-justify wb-ba">{{ getContextValue('message') }}</div>
							</va-scroll-container>
						</div>

						<div class="bg-light-gray ff-mono fs-s p10 dark-gray mb20 col-align-center text-center row">
							<div class="col-md-9">
								<div class="ml10">
									<img :src="shieldIconSource" width="15" class="vcenter mr5" alt="Código de integridad SHA-256" />
									<span class="fs-s bold tt-uppercase">SHA-256: </span>
									<div class="ff-mono wb-ba mt5">
										{{ getContextValue('messageSHA256') }}
									</div>
								</div>
							</div>
							<div class="col-md-3 text-right ma">
								<button
									v-if="isContextValue('mode', 'LOGIN')"
									class="btn btn-danger btn-sm"
									type="button"
									@click="
										$event => {
											machine.send('LOGIN_CANCEL')
											emitCancel()
										}
									"
								>
									Cancelar solicitud
								</button>
								<button
									v-if="isContextValue('mode', 'SIGN')"
									class="btn btn-danger btn-sm"
									type="button"
									@click="
										$event => {
											machine.send('SIGNATURE_CANCEL')
											emitCancel()
										}
									"
								>
									Cancelar Firma
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb10">
					<div class="col-md-2 text-center">
						<img :src="keyIconSource" alt="logo .key" height="50" class="" />
					</div>
					<div class="col-md-10 p10 text-center">
						<va-file-upload
							v-if="!machine.state.value.context.pkB64"
							@fileAdded="onPkLoaded"
							cyid="btnCargaCertificadoLlavePrivadaEfirma"
							class=""
							color="#9d2449"
							file-types=".key"
							upload-button-text="Cargar llave privada .key"
							hide-file-list
						/>

						<div class="row" v-if="machine.state.value.context.pkB64">
							<div class="col-md-6">
								<form action="#" onsubmit="return false">
									<va-input
										cyid="txtPasswordLlavePrivadaEfirma"
										v-model="pkPass"
										:type="isPasswordVisible ? 'text' : 'password'"
										:label="'Ingrese la contraseña de su llave privada'"
										@keydown.enter="signMessage(pkPass)"
										v-show="machine.state.value.context.pkB64"
										placeholder="Contraseña"
										color="#9d2449"
										input-class="t5 mr5"
										class="w100p"
										autocomplete="off"
									>
										<template #appendInner>
											<va-icon :name="isPasswordVisible ? 'visibility_off' : 'visibility'" size="small" color="primary" @click="isPasswordVisible = !isPasswordVisible" />
										</template>
									</va-input>
								</form>
							</div>
							<div class="col-md-6 text-left">
								<button cyid="btnFirmar" class="btn btn-primary btn-sm active mb5 w100p" type="button" @click="signMessage(pkPass)">
									{{ machine.state.value.context.mode == 'LOGIN' ? 'Firmar e iniciar sesión' : 'Firmar' }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--signature_preview_error-->
			<va-card-content class="m20" v-if="isState('signature_preview_error')">
				<div class="alert alert-danger bold">
					{{ machine.state.value.context.signaturePreviewError || 'Hubo un error al cargar la llave privada' }}<br /><br /><br />
					<button class="btn btn-danger" @click="machine.send('SIGNATURE_RETRY')">Reintentar</button>
				</div>
			</va-card-content>

			<!--signature_cancel-->
			<va-card-content class="" v-if="isState('signature_cancel')">
				<div class="alert alert-danger bold p50 fs-xl">
					{{ 'Firmado cancelado' }}
				</div>
			</va-card-content>

			<!--signature_sending-->
			<div class="row" v-if="isState('signature_sending')">
				<div class="col-md-12 text-center bold mt25">
					<va-inner-loading loading></va-inner-loading>
					<div class="m20">Enviando mensaje firmado...</div>
				</div>
			</div>

			<!--signature_sending_error-->
			<div class="row alert alert-danger" v-if="isState('signature_sending_error')">
				<div class="col-md-12 text-center bold">{{ machine.state.value.context.signatureSendingError || 'Hubo un error al procesar el mensaje firmado' }}<br /><br /><br /></div>
				<div class="col-md-6 text-right bold">
					<button class="btn btn-primary active btn-sm" type="button" @click="machine.send('SIGNATURE_SENDING_RETRY')">Reintentar</button>
				</div>
				<div class="col-md-6 text-left bold">
					<button
						class="btn btn-danger btn-sm"
						type="button"
						@click="
							$event => {
								machine.send('LOGIN_CANCEL')
								emitCancel
							}
						"
					>
						Cancelar
					</button>
				</div>
			</div>

			<!--signature_sent_invalid-->
			<va-card-content class="m20" v-if="isState('signature_sent_invalid')">
				<div class="alert alert-danger bold">
					{{ 'Se ha rechazado la firma de la solicitud de inicio de sesión' }}<br /><br /><br />
					<button class="btn btn-danger" @click="machine.send('START_RETRY')">CANCELAR</button>
				</div>
			</va-card-content>

			<!--signature_done-->
			<va-card-content class="" v-if="isState('signature_done')">
				<div class="alert alert-success bold p50 fs-xl">
					{{ 'Firmado exitoso' }}
				</div>
			</va-card-content>
		</div>
		<!-- TERMINA COMPONENTE-->
	</va-card>
</template>
