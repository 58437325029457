import { computed } from 'vue'
import { useRoute } from 'vue-router'

export const isRutaSitio = computed(() => {
	const route = useRoute()
	return route.fullPath.indexOf('/sitio') == 0
})

export const isRutaPortal = computed(() => {
	const route = useRoute()
	return route.fullPath.indexOf('/portal') == 0
})

export const isRutaSistema = computed(() => {
	const route = useRoute()
	return route.fullPath.indexOf('/sistema') == 0
})

export const isRutaAdministrador = computed(() => {
	const route = useRoute()
	return route.fullPath.indexOf('/administrador') == 0
})

export const isRutaSuperAdministrador = computed(() => {
	const route = useRoute()
	return route.fullPath.indexOf('/superadministrador') == 0
})

export const isRutaPerfil = computed(() => {
	const route = useRoute()
	return route.fullPath.indexOf('/perfil') == 0
})
