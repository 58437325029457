/**
 * @name strings.ts
 * @author @tirsomartinezreyes
 * @version 1.0.1
 * @description Este archivo proporciona funciones para el manejo de cadenas de texto, utilizables por Vue o TypeScript
 *
 * Notas de la versión:
 *  1.0.1 Se agregó funciona de normalización de cadenas para búsquedas
 */

/**
 * @name addSpacesToCSV
 * @description Agrega espacios a una cadena de texto separada por comas para evitar que queden pegadas
 */
export function addSpacesToCSV(csv: string): string {
	let response = ''
	const tokens = csv.split(',')
	response = tokens.join(', ')
	return response
}

/**
 * @name addCarReturnToCSV
 * @description Agrega saltos de línea a una cadena de texto separada por comas para que queden en líneas separadas
 */
export function addCarReturnToCSV(csv: string): string {
	let response = ''
	const tokens = csv.split(',')
	response = tokens.join(',\r\n')
	return response
}

/**
 * @name objectsArrayToString
 * @description Convierte un arreglo de objetos a una cadena de texto con sus valores en formato JSON, separados por comas
 */
export function objectsArrayToString(objects: any[]): string {
	let response = ''
	const tokens = objects.map(object => JSON.stringify(object))
	response = tokens.join(', ')
	return response
}

/**
 * @name arrayElementsCount
 * @description Cuenta los elementos de un arreglo y devuelve el número de elementos, si no e sun arreglo, devuelve 0
 */
export function arrayElementsCount(array: any[]): number {
	let response = 0
	if (array instanceof Array) {
		response = array.length
	}

	return response
}

/**
 * @name arrayIndexToOrdinal
 * @description Convierte un índice de un arreglo en un ordinal en españolen femenino o masculino
 */
export function arrayIndexToOrdinal(index: number, modo: 'masculino' | 'femenino' = 'masculino'): string {
	let response = ''
	const ordinalNumbersMasculino = ['primer', 'segundo', 'tercer', 'cuarto', 'quinto', 'sexto', 'séptimo', 'octavo', 'noveno', 'décimo']
	const ordinalNumbersFemenino = ['primera', 'segunda', 'tercera', 'cuarta', 'quinta', 'sexta', 'séptima', 'octava', 'novena', 'décima']
	const ordinalNumbers = modo === 'femenino' ? ordinalNumbersFemenino : ordinalNumbersMasculino
	if (index >= 0 && index < ordinalNumbers.length) {
		response = ordinalNumbers[index]
	}
	return response
}

/**
 * @name upperCaseFirst
 * @description Convierte la primera letra de una cadena de texto a mayúsculas
 */
export function upperCaseFirst(string: string): string {
	let response = ''
	if (string.length > 0) {
		response = string.charAt(0).toUpperCase() + string.slice(1)
	}
	return response
}

/**
 * @name normalizarBusqueda
 * @description Normaliza una cadena de texto para realizar búsquedas sin importar mayúsculas, minúsculas o acentos
 */
export function normalizarBusqueda(cadena: string): string {
	const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ'
	const accentsOut = 'AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn'
	cadena = cadena
		.split('')
		.map(letter => {
			const accentIndex = accents.indexOf(letter)
			return accentIndex !== -1 ? accentsOut[accentIndex] : letter
		})
		.join('')

	return cadena.normalize('NFD').toLowerCase()
}
