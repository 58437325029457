import { RSASignContext } from './types'
import { AnyEventObject } from 'xstate'
import { callAuthenticationAPI } from 'src/controllers/auth'
import { AuthenticationAPIRequest, AuthenticationAPIResponse, AuthenticationAPIRequestBody } from 'cofepris-typesafe/Types/Auth'
import { stringSHA256 } from 'webauthn'

const doGetMessage = async function (context: RSASignContext, event: AnyEventObject): Promise<object | null> {
	context.debug ? console.log('on doGetMessage') : null
	const body: AuthenticationAPIRequestBody['RSA_GET_LOGIN_MESSAGE'] = {
		b64x509Certificate: context.certificadoB64 as string
	}

	const request: AuthenticationAPIRequest = {
		accion: 'RSA_GET_LOGIN_MESSAGE',
		body: JSON.stringify(body)
	}

	const response: AuthenticationAPIResponse = await callAuthenticationAPI(request)

	if (response.status == 'OK') {
		const sha256 = stringSHA256(response.data.messageForSign?.message as string)
		if (sha256 !== (response.data.messageForSign?.sha256 as string)) {
			console.error('Los hashes del servicio y calculados no coinciden', [sha256, response.data.messageForSign?.sha256 as string])
		}
		return { message: response.data.messageForSign?.message as string }
	} else {
		return Promise.reject({ getMessageError: response.message })
	}
}

const doRequestLogin = async function (context: RSASignContext, event: AnyEventObject): Promise<object | null> {
	context.debug ? console.log('on doRequestLogin') : null
	return new Promise(async (resolve, reject) => {
		const body: AuthenticationAPIRequestBody['RSA_GET_IDENTITY_TOKEN'] = {
			sha256Message: context.messageSHA256 as string,
			signature: context.signatureB64 as string
		}

		const request: AuthenticationAPIRequest = {
			accion: 'RSA_GET_IDENTITY_TOKEN',
			body: JSON.stringify(body)
		}
		//console.log(JSON.stringify(body))
		const response: AuthenticationAPIResponse = await callAuthenticationAPI(request)
		//console.log({ response })

		if (response.status == 'OK') {
			resolve({ jwt: response.data.jwt, validSignature: response.data.isValid })
		} else {
			reject({ signatureSendingError: response.message })
		}
	})
}

export default {
	doGetMessage,
	doRequestLogin
}
