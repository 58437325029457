import { ref } from 'vue'
import { useConfigStore } from 'src/store/config'
import { useUserStore } from 'src/store/auth'
import ERROR_API from 'src/errors/ERROR_API'
import { ErrorHandler } from 'cofepris-typesafe/Types/Errors'
import { EModoOperacion, IModoOperacion } from 'cofepris-typesafe/Modules/ModoOperacion'
import { IConfigValues, IGlobalConfig } from 'cofepris-typesafe/Types/ConfigsSystem'
import { OrganizacionConfigAPIResponse } from 'cofepris-typesafe/Types/Organizacion'

const debug = false
export const cargando = ref<boolean>(false)
export const lastAPIRequest = ref<IConfigValues | undefined>(undefined)
export const lastAPIResponse = ref<OrganizacionConfigAPIResponse | undefined>(undefined)

export const modoOperacion = ref<IModoOperacion>({
	modoOperacion: EModoOperacion.INDEFINIDO,
	mensaje: undefined,
	texto: undefined
})

export const getConfiguracionModoOperacion = function () {
	callAPI('GET', 'GET', undefined, useUserStore().getAccesJWT())
		.then((response: OrganizacionConfigAPIResponse) => {
			const globalConfig: IGlobalConfig = response.data as IGlobalConfig
			if (response.status === 'OK') {
				if (globalConfig.modoOperacion) {
					modoOperacion.value = globalConfig.modoOperacion
				}
			} else {
				modoOperacion.value = {
					modoOperacion: EModoOperacion.ERROR,
					mensaje: response.message ?? 'No es posible obtener la configuración de modo de operación del sistema',
					texto: 'Notifique al administrador del problema'
				}
			}
		})
		.catch(error => {
			console.error('on catch', error)
			modoOperacion.value = {
				modoOperacion: EModoOperacion.ERROR,
				mensaje: error ?? 'No es posible obtener la configuración de modo de operación del sistema',
				texto: 'Notifique al administrador del problema'
			}
		})
}

export const setConfiguracionModoOperacion = function (nuevoModoOperacion: IModoOperacion): Promise<OrganizacionConfigAPIResponse> {
	const request: IConfigValues = {
		modoOperacion: nuevoModoOperacion
	}
	return callAPI('SET', 'POST', request, useUserStore().getAccesJWT())
}

export const callAPI = function (service: 'GET' | 'SET', method: 'GET' | 'POST', request: IConfigValues | undefined, jwt: string): Promise<OrganizacionConfigAPIResponse> {
	debug && console.log('on callAPI', service, request)
	return new Promise(resolve => {
		cargando.value = true
		fetch(useConfigStore().getEnvironmentConfig().API_SERVICES.CONFIG[service], {
			method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: jwt
			},
			body: JSON.stringify(request)
		})
			.then(async (response: Response) => {
				const json: OrganizacionConfigAPIResponse = (await response.json()) || {}
				lastAPIRequest.value = request
				lastAPIResponse.value = json
				switch (response.status) {
					case 200:
						resolve({
							status: 'OK',
							message: json.message,
							data: json.data
						} as OrganizacionConfigAPIResponse)
						break
					case 400:
						resolve({
							status: 'ERROR',
							message: json.message,
							data: json.data
						} as OrganizacionConfigAPIResponse)
						break
					default:
						resolve({
							status: 'NETWORK_ERROR',
							message: json.message || 'Error ' + response.status + ': Ocurrió un error de red en la solicitud',
							data: {}
						} as OrganizacionConfigAPIResponse)

						throw new ERROR_API('Error en solicitud a API', {
							timestamp: Date.now().toString(),
							environment: useConfigStore().getEnvironment(),
							url: useConfigStore().getEnvironmentConfig().API_SERVICES.CONFIG[service],
							method,
							request,
							response: json,
							status: response.status.toString()
						})
				}
			})
			.catch(error => {
				debug && console.log('on catch', error)
				lastAPIRequest.value = request
				resolve({
					status: 'NETWORK_ERROR',
					message: 'Ocurrió un error de red en la solicitud: (' + error.message + ') @ ' + useConfigStore().getEnvironmentConfig().API_SERVICES.CONFIG[service],
					data: {}
				} as OrganizacionConfigAPIResponse)

				ErrorHandler(error)
			})
			.finally(() => {
				debug && console.log('on finally')
				cargando.value = false
			})
	})
}
