/**
 * @name CTDDispositivosModulo5
 * @author @tirsomartinezreyes
 * @version 0.0.1
 *
 * @description Define la estructura del Módulo 5 (Etiquetado y Mmaterial Promocional)
 * del CTD (Common Technical Document) para Registro de Dispositivos.
 */

import {
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

/**
 * @name CTDDispositivosModulo5
 * @description Define la estructura del Módulo 5 del CTD (Common Technical Document) para Registro de Dispositivos.
 */
export const CTDDispositivosModulo5: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm5',
	nombre: 'm5',
	descripcion: 'Módulo 5: ETIQUETADO Y MATERIAL PROMOCIONAL',
	hijos: [
		{
			id: '5.1',
			nombre: '51-etique-dispo-empaque',
			descripcion: 'Etiquetado del Dispositivo y/o del empaque',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '5.2',
			nombre: '52-manual-uso',
			descripcion: 'Manual de uso del Dispositivo',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '5.3',
			nombre: '53-instructivo-uso',
			descripcion: 'Instructivo de uso del Dispositivo',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '5.4',
			nombre: '54-otro-etiq-mate-promo',
			descripcion: 'Otro etiquetado y material promocional',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '5.5',
			nombre: '55-catalogo-comercial',
			descripcion: 'Catálogo comercial',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '5.6',
			nombre: '56-tecnica-quirurgica',
			descripcion: 'Técnica quirúrgica',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		}
	]
}
