/**
 * @name CTDDispositivosModulo4
 * @author @tirsomartinezreyes
 * @version 0.0.1
 *
 * @description Define la estructura del Módulo 4 (Evidencia Clínica)
 * del CTD (Common Technical Document) para Registro de Dispositivos.
 */

import {
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

/**
 * @name CTDDispositivosModulo4
 * @description Define la estructura del Módulo 4 del CTD (Common Technical Document) para Registro de Dispositivos.
 */
export const CTDDispositivosModulo4: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm4',
	nombre: 'm4',
	descripcion: 'Módulo 4: EVIDENCIA CLÍNICA',
	hijos: [
		{
			id: '4.1',
			nombre: '41-pub-revistas-index',
			descripcion: 'Publicaciones en revistas indexadas de los estudios clínicos',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '4.2',
			nombre: '42-eval-clinica',
			descripcion: 'Evaluación clínica',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '4.3',
			nombre: '43-valid-clin-software',
			descripcion: 'Validación clínica del Software como dispositivo médico',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '4.4',
			nombre: '44-otra-evid-clinica',
			descripcion: 'Otras Evidencias Clínicas',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		}
	]
}
