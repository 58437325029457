/**
 * El objetivo del store es ser el unto de consulta de los parámetros de configuración de la alicación para el entorno configurado
 * - La configuración general y de todos los entornos se encuentra compartida en shared/Constants/Config.ts
 * - El nodo ENVIRONMENTS del objeto config contiene los parámetros particulares de configuración para cada entorno
 *
 * En el store se permite identificar cual es el entorno al que debe conectarse la aplicación, para lo cual se utiliza la siguiente lógica:
 * - Se obtiene el valor de la variable de entorno VITE_ENVIRONMENT, si esta variable no existe o no es un valor válido, se utiliza el valor por default DEV
 * - Si existe sessionStorage y la variable ENVIRONMENT existe en el sessionStorage, se utiliza el valor de la variable ENVIRONMENT del sessionStorage
 *
 * VITE_ENVIRONMENT es seteado en el momento de la compilación desde la rama específica de desarrollo, por lo que su valor queda hardcodeado en el bundle
 */
import { defineStore } from 'pinia'
import { config, configType, EnvironmentType } from 'cofepris-typesafe/Constants/Config'

export const useConfigStore = defineStore('config', () => {
	const storeConfig: configType = JSON.parse(JSON.stringify(config))

	/**
	 * El objetivo de esta función es obtener la configuración de la aplicación, con el valor de ENVIRONMENT correctamente seteado
	 */
	const getConfig = function (): configType {
		return storeConfig
	}

	/**
	 * El objetivo de esta función es obtener el identificador del entorno al que debe conectarse la aplicación
	 */
	const getEnvironment = function (): EnvironmentType {
		let response = EnvironmentType.DEV
		const importedEnvironment: EnvironmentType = ((import.meta as any).env.VITE_ENVIRONMENT || '').toUpperCase()

		if (importedEnvironment) {
			if (Object.values(EnvironmentType).includes(importedEnvironment)) {
				response = importedEnvironment
			}
		}

		if (sessionStorage?.getItem('ENVIRONMENT')) {
			const sessionStorageEnvironment: EnvironmentType = (sessionStorage.getItem('ENVIRONMENT') ?? '').toUpperCase() as EnvironmentType
			if (Object.values(EnvironmentType).includes(sessionStorageEnvironment)) {
				response = sessionStorageEnvironment
			}
		}
		return response
	}

	/**
	 * El objetivo de esta función es setear el identificador del entorno al que debe conectarse la aplicación en session Storage
	 */
	const setLocalEnvironment = function (environment: EnvironmentType): void {
		if (Object.values(EnvironmentType).includes(environment)) {
			sessionStorage.setItem('ENVIRONMENT', environment)
		}
	}

	/**
	 * El objetivo de esta función es obtener la configuración del entorno al que debe conectarse la aplicación
	 */
	const getEnvironmentConfig = function (): configType['ENVIRONMENTS'][keyof configType['ENVIRONMENTS']] {
		const config = getConfig()
		return config.ENVIRONMENTS[getEnvironment() as keyof configType['ENVIRONMENTS']]
	}

	/**
	 * El objetivo de esta función es obtener el identificador del grupo de organización para el entorno al que debe conectarse la aplicación
	 */
	const getOrganizationGroup = function (): string {
		return getEnvironmentConfig().AUTHENTICATION.ORGANIZATION_GROUP
	}

	/**
	 * El objetivo de esta función es obtener el identificador del grupo público para el entorno al que debe conectarse la aplicación
	 */
	const getPublicGroup = function (): string {
		return getEnvironmentConfig().AUTHENTICATION.PUBLIC_GROUP
	}

	return {
		getConfig,
		getEnvironment,
		getEnvironmentConfig,
		setLocalEnvironment,
		getOrganizationGroup,
		getPublicGroup
	}
})
