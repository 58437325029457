<script lang="ts" setup>
import { computed, ref } from 'vue'
import { JsonTreeView } from 'json-tree-view-vue3'
import JsonUtils from 'cofepris-typesafe/src/utils/JsonUtils'

const props = withDefaults(
	defineProps<{
		header?: string
		title?: string
		content: object
		cols?: number
		open?: boolean
		class?: string
		trace?: boolean
		debug?: boolean
	}>(),
	{
		header: undefined,
		title: undefined,
		content: () => ({}),
		cols: 1,
		open: false,
		class: ''
	}
)

enum EViewMode {
	TREE = 'TREE',
	JSON = 'JSON'
}

const view = ref<EViewMode>(EViewMode.TREE)
const fontSize = ref<string>('fs-s')
const colorScheme = ref<string>('dark')
const open = ref<boolean>(props.open)

const copy = async function (v: any) {
	try {
		const textToCopy = getStringValue('', v)
		await navigator.clipboard.writeText(textToCopy)
	} catch (error) {
		console.error('Failed to copy text: ', error)
	}
}

const toggleView = function () {
	if (view.value == EViewMode.TREE) {
		view.value = EViewMode.JSON
	} else {
		view.value = EViewMode.TREE
	}
}

const toggleOpen = function () {
	open.value = !open.value
}

const toggleFontSize = function () {
	const fontSizes = ['fs-xxs', 'fs-xs', 'fs-s', 'fs-m']
	const index = fontSizes.indexOf(fontSize.value)
	if (index < fontSizes.length - 1) {
		fontSize.value = fontSizes[index + 1]
	} else {
		fontSize.value = fontSizes[0]
	}
}

const toggleColorScheme = function () {
	const colorSchemes = ['dark', 'light']
	const index = colorSchemes.indexOf(colorScheme.value)
	if (index < colorSchemes.length - 1) {
		colorScheme.value = colorSchemes[index + 1]
	} else {
		colorScheme.value = colorSchemes[0]
	}
}

function getStringValue(k: string, v: any) {
	let response = v
	let error: boolean = false
	let error2: boolean = false
	if (typeof v == 'object') {
		try {
			response = JSON.stringify(v, null, 2)
		} catch (e) {
			response = "Error al convertir '" + k + "' a string"
			error = true
		}
	}

	if (error) {
		try {
			response = JsonUtils.stringify(v, 2)
		} catch (e) {
			response = "Error(2) al convertir '" + k + "' a string"
			error2 = true
		}
	}

	if (error && error2) {
		console.warn('DebugPanel (' + k + ')', v)
	}

	return response
}

function stringifyObject(k: string, v: any): string {
	let response = JSON.stringify({ error: 'No se proporcionó un objeto' }, null, 2)
	let error: boolean = false
	let error2: boolean = false
	if (typeof v == 'object') {
		try {
			response = JSON.stringify(v, null, 2)
		} catch (e) {
			response = JSON.stringify({ error: 'Error al convertir a string ' + k }, null, 2)
			error = true
		}
		if (error) {
			try {
				response = JsonUtils.stringify(v, 2)
			} catch (e) {
				//props.trace &&
				console.log(e)
				response = JSON.stringify({ error: 'Error(2) al convertir a string ' + k }, null, 2)
				error2 = true
			}
		}
	}
	if (error && error2) {
		console.info('DebugPanel (' + k + ') ', v)
	}
	return response
}

const columnClass = computed(() => {
	let response = 'col-md-12'

	if (props.cols == 2) {
		response = 'col-md-6'
	}

	if (props.cols == 3) {
		response = 'col-md-4'
	}

	if (props.cols == 4) {
		response = 'col-md-3'
	}

	if (props.cols == 6) {
		response = 'col-md-2'
	}

	return response
})

const cssClasses = computed(() => {
	let response = ''
	response += fontSize.value + ' '
	if (colorScheme.value == 'light') {
		response += 'bg-white black '
	} else {
		response += 'bg-black white '
	}
	return response
})
</script>

<template>
	<div :class="props.class" class="debug mxw1200 text-left">
		<div class="text-left" @click="toggleOpen">
			<va-icon :name="open ? 'arrow_drop_down' : 'arrow_right'" class="gray" />
			<span v-if="!props.header && !props.title">Debug</span>
			<span v-if="props.title">{{ props.title }}</span>
			<span v-if="props.header">{{ props.header }}</span>
		</div>
		<div v-if="open" class="width100p debug">
			<va-icon :name="view == EViewMode.TREE ? 'data_object' : 'account_tree'" class="black ml30" @click="toggleView" />
			<va-icon name="format_size" class="black ml30" @click="toggleFontSize" />
			<va-icon name="contrast" class="black ml30" @click="toggleColorScheme" />
		</div>
		<div v-if="open" class="row">
			<div v-for="(v, k) in props.content" :key="k" :class="columnClass">
				<pre v-if="view == EViewMode.TREE" class="code" :class="cssClasses">
                    <JsonTreeView :data="stringifyObject(k, v)" :max-depth="0" :root-key="String(k)" :color-scheme="colorScheme" />
					<va-icon name="content_copy" class="gray ml30" @click="copy(v)" />
                </pre>
				<pre v-if="view == EViewMode.JSON" class="code mxw1000 mxh500" :class="cssClasses">{{ getStringValue(k, v) }}</pre>
				<div v-if="view == EViewMode.JSON" class="mb20">
					<va-badge :text="k" color="success" />
					<va-icon name="content_copy" class="gray ml30" @click="copy(v)" />
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.code {
	text-transform: none;
	font-family: monospace;
	margin: 15px;
	padding: 15px;
	outline: 1px solid black;
	overflow: scroll;
	text-align: justify;
}
</style>
