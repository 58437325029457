<script setup lang="ts">
/**
 * @name SWManager
 * @description Componente para la edición de Activos de Información de Insumos para la salud
 *
 * @autor @tirsomartinezreyes @Jorel254
 * @version 0.0.2
 * @changelog - 0.0.3 - 29/ago/24/ Correción de variables que no se utilizan y formato @Jorel254
 */
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { useModal } from 'vuestic-ui'
const { confirm } = useModal()
import { ref } from 'vue'
const intervalMS = 60 * 60 * 1000
const pleaseWait = ref(false)
let interval: null | ReturnType<typeof setInterval> = null
const cancelInterval = () => {
	console.log('SWManager: clearInterval')
	if (interval) {
		clearInterval(interval)
		interval = null
	}
}
let lastTimeConfirmationShow = 0
const { updateServiceWorker } = useRegisterSW({
	immediate: true,
	onNeedRefresh() {
		console.log('SWManager: onNeedRefresh')
		cancelInterval()
		if (Date.now() - lastTimeConfirmationShow < 1000 * 60) {
			return
		}
		lastTimeConfirmationShow = Date.now()
		confirm({
			title: 'Actualización disponible',
			okText: 'Actualizar y reiniciar',
			cancelText: 'No reiniciar',
			message: 'Hay una nueva versión disponible, ¿desea actualizar la aplicación?'
		}).then(r => {
			pleaseWait.value = false

			if (r) {
				console.log('SWManager: onNeedRefresh: confirm: then')
				updateServiceWorker()
				location.reload()
			}
		})
	},

	onOfflineReady() {
		cancelInterval()
		console.log('SWManager: onOfflineReady')
		pleaseWait.value = false
	},

	onRegisterError(error: Error) {
		console.log('SWManager: onRegisterError', error)
	},
	onRegisteredSW(scriptName: string, r: ServiceWorkerRegistration | undefined): void {
		console.log('SWManager: onRegisteredSW', scriptName, r)
		if (r) {
			const serviceWorker = r?.installing || r?.active || r?.waiting
			console.log('%c  State  ' + serviceWorker?.state + '  ', 'background: green; color: #FFF')
			r.onupdatefound = () => {
				console.log('SWManager: onupdatefound')
				cancelInterval()
				// pleaseWait.value = true
			}
			if (serviceWorker?.state === 'installing') {
				pleaseWait.value = true
			} else if (serviceWorker?.state === 'activated') {
				updateServiceWorker()
				pleaseWait.value = false
			}
			interval = setInterval(() => {
				console.log('SWManager: onRegistered: setInterval')
				r && r.update()
			}, intervalMS)
		}
	}
})
</script>

<template>
	<CargandoModal :mostrar="pleaseWait" bold titulo="Configurando aplicación..." />
</template>
