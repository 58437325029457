export interface IErrorModalService {
	set: (args: Partial<IErrorModalArgs>) => IErrorModalService
}

export abstract class IErrorModalArgs {
	mostrar?: boolean = false
	bold?: boolean = false
	datos?: any = undefined
	titulo?: string = 'Mensaje informativo'
	mensaje?: string = 'Ocurrio un error desconocido, por favor intente de nuevo.'
	aceptarTexto?: string = 'Entiendo'
	cancelarTexto?: string = 'Cancelar'
	regresarTexto?: string = 'Regresar'
	reintentarTexto?: string = 'Reintentar'
	cerrarTexto?: string = 'Cerrar'
	onAceptar?: Promise<unknown> | (() => void | any) | undefined = undefined
	onReintentar?: Promise<unknown> | (() => void | any) | undefined = undefined
	onCancelar?: Promise<unknown> | (() => void | any) | undefined = undefined
	onRegresar?: Promise<unknown> | (() => void | any) | undefined = undefined
	onCerrar?: Promise<unknown> | (() => void | any) | undefined = undefined
}
