/**
 * @name CCTDMedicamentos
 * @author @tirsomartinezreyes
 * @version 0.0.14
 *
 * @changelog 0.0.14 - 02/sep/24 - Se modificó Módulo 1 en 1.13.3
 * @changelog 0.0.13 - 28/ago/24 - Se modificaron módulos 1, 3 y 4
 *
 * Documento de análisis de la estructura del CTD para COFEPRIS
 * @references https://docs.google.com/spreadsheets/d/1iRlvEqT78WXnAxntQ2RwKvlRBIWM6VcVAJc4Po50--0/edit?usp=sharing
 *
 * Especificación general del CTD
 * @references https://www.ich.org/page/ctd
 *
 * Especificación técnica del eCTD
 * @references https://admin.ich.org/sites/default/files/inline-files/eCTD_Specification_v3_2_2_0.pdf
 *
 * Documento de referencia al eCTD de la FDA
 * @reference https://www.fda.gov/media/93818/download
 *
 * Documento de referencia al eCTD de la EMA
 * @reference https://esubmission.ema.europa.eu/ectd/
 *
 * Documento de referencia al NeeS de la EMA
 * @reference https://esubmission.ema.europa.eu/tiges/docs/NeeS%20eGuidance%20Document%20v4%200_final%20for%20publication%20Nov%202013.pdf
 *
 * Documento de referencia al sobre la validación del NeeS por EMA
 * @reference https://esubmission.ema.europa.eu/tiges/docs/NeeS%20Validation%20Criteria%20v4%203_Feb2018.xls
 * @reference https://esubmission.ema.europa.eu/doc/EU%20NeeS%20validation%20Criteria%20v1.0.pdf
 */

import {
	ECTDTipoCTD, //Enumeración que define los tipos de CTD: MEDICAMENTOS y DISPOSITIVOS
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTD, //Definición de la estructura del CTD apegado a la interface definida
	CICTDVersion //Versión de la interface ICTD
} from '../ICTD'

import { CTDMedicamentosModulo1 } from './CTDMedicamentosModulo1' //0.0.9
import { CTDMedicamentosModulo2 } from './CTDMedicamentosModulo2' //0.0.4
import { CTDMedicamentosModulo3 } from './CTDMedicamentosModulo3' //0.0.10
import { CTDMedicamentosModulo4 } from './CTDMedicamentosModulo4' //0.0.6
import { CTDMedicamentosModulo5 } from './CTDMedicamentosModulo5' //0.0.5

export const CCTDMedicamentosVersion = '0.0.14'
/**
 * @name CCTDMedicamentos
 * @description Define la estructura del CTD (Common Technical Document) para Medicamentos.
 */
const CCTDMedicamentos: ICTD = {
	versionICTD: CICTDVersion,
	versionCTD: CCTDMedicamentosVersion,
	tipoCTD: ECTDTipoCTD.MEDICAMENTOS,
	raiz: {
		tipo: ECTDTipoNodo.RAIZ,
		id: 'ctd',
		nombre: 'CTD',
		descripcion: 'Common Technical Document para Registro de Medicamentos',
		hijos: [CTDMedicamentosModulo1, CTDMedicamentosModulo2, CTDMedicamentosModulo3, CTDMedicamentosModulo4, CTDMedicamentosModulo5]
	}
}

/**
 *
 * @name obtenerCopiaCTDMedicamentos
 * @description Obtiene una copia del CTD Medicamentos como un objeto nuevo, sin referencias al CCTDMedicamentos original.
 */
export const obtenerCopiaCTDMedicamentos = (): ICTD => JSON.parse(JSON.stringify(CCTDMedicamentos)) as ICTD
