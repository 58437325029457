import Container, { ContainerInstance } from 'typedi'
import { EFirmaDelimitador } from 'cofepris-typesafe/Modules/Firma'
export default class TypeContainer {
	public static container: ContainerInstance

	public static has(name: string): boolean {
		//console.log({ has: name })
		if (this.container == undefined) {
			this.init()
		}
		return this.container.has(name)
	}

	public static get<T>(name: string): T {
		//console.log({ get: name })
		return this.container.get<T>(name)
	}

	public static getOrNull<T>(name: string): T | undefined {
		//console.log({ getOrNull: name })
		try {
			return this.get<T>(name)
		} catch {
			return undefined
		}
	}

	public static set<T>(name: string, value: T): ContainerInstance {
		return this.container.set<T>(name, value)
	}

	public static init() {
		// eslint-disable-next-line @typescript-eslint/ban-types
		TypeContainer.container = Container.set<{}>(EFirmaDelimitador.DELIMITADOR_VALOR_VACIO, {}) as ContainerInstance
		return TypeContainer.container
	}
}
