<script lang="ts" setup>
import { onMounted } from 'vue'
import { EModoOperacion } from 'cofepris-typesafe/Modules/ModoOperacion'
import { modoOperacion, getConfiguracionModoOperacion } from './controller'
import { isRutaSitio } from 'src/router/computed'

const modosMostrarAlerta = [EModoOperacion.MANTENIMIENTO, EModoOperacion.AVISO, EModoOperacion.ERROR]

onMounted(() => {
	getConfiguracionModoOperacion()
})
</script>

<template>
	<div v-if="modosMostrarAlerta.includes(modoOperacion.modoOperacion)" class="alert alert-danger mt-10">
		<div class="fs-lg bold pt30">{{ modoOperacion.mensaje }}</div>
		<div v-if="modoOperacion.texto" class="ml50" :class="isRutaSitio ? 'mr50' : 'mr30'"><br />{{ modoOperacion.texto }}</div>
	</div>
</template>
