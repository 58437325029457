/**
 * @package CModificacionesDispositivos
 * @author @BrenAzua
 * @version 0.0.2
 *
 * @description Registro de modificaciones de dispositivos
 */
import { IModificacionDispositivo, EModificacionDispositivosSeccionOficio, EModificacionDispositivosTipo, EModificacionDispositivosHomoclaveModalidad } from './IModificacionesDispositivos'
export const CModificacionesDispositivos: IModificacionDispositivo[] = [
	{
		numeral: '6.1.1',
		titulo: 'Cambio de domicilio del distribuidor nacional o titular del registro sanitario',
		descripcion: 'Cambio o actualización del domicilio',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.TITULAR_REGISTRO, EModificacionDispositivosSeccionOficio.DISTRIBUIDOR],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.2',
		titulo: 'Cambio de razón social del: titular del registro sanitario, distribuidor o fabricante real/legal (Excepto Cesión de Derechos).',
		descripcion: 'Cambio o actualización de la razón social',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.TITULAR_REGISTRO, EModificacionDispositivosSeccionOficio.DISTRIBUIDOR, EModificacionDispositivosSeccionOficio.FABRICANTE],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.3',
		titulo: 'Cambio o Inclusión del importador (es) y distribuidor (es)',
		descripcion: 'Cambio o Inclusión',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.DISTRIBUIDOR, EModificacionDispositivosSeccionOficio.IMPORTADOR],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.4',
		titulo: 'Cambio o inclusión de fabricante responsable de la calidad del producto (fabricante legal),',
		descripcion: 'Cambio o inclusión del (los) fabricante(es)',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.FABRICANTE],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.5',
		titulo: 'Por cambio de nombre comercial o número de catálogo del producto',
		descripcion: 'Cambio de denominación distintiva o número de catálogo donde se encuentre el Dispositivo Médico',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.DENOMINACION_DISTINTIVA, EModificacionDispositivosSeccionOficio.PRESENTACIONES],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.6',
		titulo: 'Por cambio de material del envase secundario',
		descripcion: 'Cambio de material del envase secundario',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.ENVASE_SECUNDARIO],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.7',
		titulo: 'Por eliminación de presentaciones o cambio de códigos o números de referencia del producto',
		descripcion: 'Eliminación de presentación(es) o cambio de códigos o números de referencia',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.PRESENTACIONES],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.8',
		titulo: 'Por eliminación de fabricante real',
		descripcion: 'Eliminación del (los) fabricante (es)',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.FABRICANTE],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.9',
		titulo: 'Por eliminación de importador o distribuidor (es) nacional o extranjero',
		descripcion: 'Eliminación',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.DISTRIBUIDOR, EModificacionDispositivosSeccionOficio.IMPORTADOR],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.10',
		titulo: 'Por sustitución, inclusión, modificación al domicilio o cambio de razón social; del distribuidor en el extranjero',
		descripcion: 'Sustitución, inclusión, modificación al domicilio o cambio de razón social del (los) distribuidor(es) extranjeros',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.DISTRIBUIDOR],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	},
	{
		numeral: '6.1.12',
		titulo: 'Por expresión en el domicilio del sitio del fabricante real, siempre y cuando no exista cambios en el sitio de fabricación y conserve el mismo lugar físicamente',
		descripcion: 'Expresión en el domicilio del sitio del (los) fabricante(s) real(es)',
		seccionesOficio: [EModificacionDispositivosSeccionOficio.FABRICANTE],
		homoclaveModalidad: [EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_A'], EModificacionDispositivosHomoclaveModalidad['COFEPRIS_2022_022_003_A_B']],
		tipo: EModificacionDispositivosTipo.ADMINISTRATIVAS
	}
]
